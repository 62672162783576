import React, { useState, useCallback, useEffect } from 'react';
import { 
  FaFacebookSquare, 
  FaYoutube, 
  FaInstagram, 
  FaWhatsapp, 
  FaTwitter, 
  FaLinkedin, 
  FaTiktok, 
  FaPinterest,
  FaQuestionCircle // Ícone padrão para casos não mapeados
} from 'react-icons/fa';
import { IconType } from 'react-icons';

interface SocialMediaProps {
  id_banda: number;
  token: string;
}

interface SocialMediaLink {
  url: string;
  nome: string;
  icone: string;
}

interface IconeRedeSocialProps {
  icone: string;
}

const iconMap: { [key: string]: IconType } = {
  'facebook-square': FaFacebookSquare,
  'youtube': FaYoutube,
  'instagram': FaInstagram,
  'whatsapp': FaWhatsapp,
  'twitter': FaTwitter,
  'linkedin': FaLinkedin,
  'tiktok': FaTiktok,
  'pinterest': FaPinterest
};

const SocialMedia: React.FC<SocialMediaProps> = ({ id_banda, token }) => {
  const [socialMediaLinks, setSocialMediaLinks] = useState<SocialMediaLink[]>([]);

  const IconeRedeSocial: React.FC<IconeRedeSocialProps> = ({ icone }) => {
    const IconComponent = iconMap[icone] || FaQuestionCircle; // Use FaQuestionCircle como fallback
    return <IconComponent size={30} color="#FFF" />;
  };

  const fetchData = useCallback(async () => {
    if (!token || !id_banda) {
      console.log('Token ou id_banda não disponíveis - execução interrompida');
      return;
    }

    console.log('ID banda sendo enviado para redesocial', id_banda);

    try {
      const url = `https://tribhus.shop:5000/redesocial/band/${id_banda}/social-medias`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      const data = await response.json();

      if (data.error) {
        console.error("Error from server:", data.error);
        return;
      }

      setSocialMediaLinks(data);
    } catch (error) {
      console.error("Error fetching social media links:", error);
    }
  }, [id_banda, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSocialMediaClick = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div style={styles.container}>
      {socialMediaLinks.length > 0 }
      <div style={styles.scrollView}>
        {socialMediaLinks.map((link, index) => (
          <button
            key={index}
            style={styles.card}
            onClick={() => handleSocialMediaClick(link.url)}
          >
            <IconeRedeSocial icone={link.icone} />
          </button>
        ))}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    flex: 1,
    padding: 2,
  },
  scrollView: {
    display: 'flex',
    overflowX: 'auto',
    scrollSnapType: 'x mandatory',
    WebkitOverflowScrolling: 'touch',
  },
  card: {
    margin: 5,
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#2D2D2D',
    borderRadius: 30,
    border: 'none',
    cursor: 'pointer',
    scrollSnapAlign: 'start',
  },
};

export default SocialMedia;