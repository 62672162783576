import React from 'react';

const TermosBandaDocument = () => (
  <>
    <h2>Termos de Uso - Tribhus para Artistas</h2>
    <p>Última atualização: 15/09/2024</p>
    <p>
      Leia atentamente estes Termos de Uso, nossa Política de Privacidade e quaisquer outros termos mencionados neste documento.
    </p>

    <h3>1. Introdução</h3>
    <p>
      Obrigado por escolher o serviço Tribhus para Artistas, uma plataforma de rede social e streaming dedicada ao rock. Ao se cadastrar, publicar conteúdo ou usar nossos serviços, sites e aplicativos como artista ou banda, você está firmando um contrato vinculativo com a Tribhus.
    </p>

    <h3>2. Serviços e Funcionalidades</h3>
    <h4>2.1 Funcionalidades Básicas</h4>
    <p>
      Os serviços e funcionalidades básicas oferecidos em nossos sites e aplicativos, incluindo o cadastro, acesso ao feed, publicação de conteúdos e navegação geral na plataforma, são gratuitos para os usuários.
    </p>
    <h4>2.2 Funcionalidades Premium</h4>
    <p>
      Alguns recursos premium ou avançados, definidos pela Tribhus, podem exigir pagamento. Qualquer cobrança será claramente informada antes da contratação ou uso desses serviços, e o usuário terá a oportunidade de revisar e aceitar os termos específicos de pagamento antes de completar a transação.
    </p>
    <h4>2.3 Alterações nas Funcionalidades</h4>
    <p>
      A Tribhus se reserva o direito de modificar, adicionar ou remover funcionalidades, tanto gratuitas quanto premium, a qualquer momento. Caso uma funcionalidade gratuita seja convertida em premium, você será notificado com antecedência, e a continuidade do uso dessa funcionalidade estará sujeita à aceitação dos novos termos.
    </p>

    <h3>3. Elegibilidade</h3>
    <p>
      Para usar o Serviço da Tribhus para Artistas, você deve:
    </p>
    <ul>
      <li>Ter pelo menos 18 anos;</li>
      <li>Ter capacidade legal para firmar um contrato vinculativo conosco;</li>
      <li>Não ter sido previamente banido do serviço por violar estes termos;</li>
      <li>Ser residente em um país onde o serviço é oferecido.</li>
    </ul>
    <p>
      Se você está usando o serviço em nome de uma banda ou entidade musical, você garante que tem autoridade para vincular essa entidade a estes termos.
    </p>

    <h3>4. Processo de Avaliação</h3>
    <p>
      Ao se cadastrar, você concorda em passar por um processo de avaliação realizado pela equipe de conteúdo da Tribhus para certificar que seu trabalho se enquadra em alguma vertente do rock. A Tribhus se reserva o direito de aprovar ou rejeitar qualquer música, álbum ou conteúdo, com base em critérios de curadoria interna, que são definidos única e exclusivamente pelos administradores da Tribhus.
    </p>
    <p>
      A decisão sobre a aprovação ou rejeição de conteúdo é final e irrevogável, e não está sujeita a recurso ou revisão. Esta avaliação pode resultar na aprovação parcial, completa ou na rejeição do conteúdo enviado.
    </p>
    <p>
      Você reconhece que a curadoria musical é subjetiva e que os critérios de avaliação podem incluir, mas não se limitam a, estilo musical, qualidade da produção, originalidade, e adequação ao público-alvo da Tribhus. Em caso de rejeição, a Tribhus não é obrigada a fornecer justificativa detalhada para sua decisão.
    </p>
    <p>
      Além disso, você concorda que a Tribhus não será responsável por qualquer dano, perda de oportunidades ou qualquer outro prejuízo resultante da rejeição de seu conteúdo, e que o envio de conteúdo não garante sua publicação na plataforma.
    </p>

    <h3>5. Alterações aos Termos</h3>
    <p>
      Reservamo-nos o direito de modificar estes Termos a qualquer momento. As alterações entrarão em vigor imediatamente após a publicação, a menos que especificado de outra forma. O uso continuado do serviço após as alterações constitui sua aceitação dos novos termos.
    </p>

    <h3>6. Seu Conteúdo e Direitos</h3>
    <h4>6.1 Conteúdo do Artista</h4>
    <p>
      Como artista ou banda na plataforma Tribhus, você tem permissão para publicar:
    </p>
    <ul>
      <li>No seu dashboard de artista: gravações sonoras, obras audiovisuais que incorporem obras musicais ou gravações sonoras, e outros conteúdos relacionados à sua música (como letras, partituras, etc.).</li>
      <li>No feed geral da plataforma: fotos, textos e informações, além de links para vídeos hospedados em plataformas de compartilhamento de vídeos de terceiros.</li>
    </ul>

    <h4>6.2 Política de Incorporação de Vídeos de Plataformas de Terceiros</h4>
    <p>
      Como artista na plataforma Tribhus, você tem a capacidade de incorporar vídeos de plataformas de compartilhamento de terceiros diretamente no feed da Tribhus. Ao fazer isso:
    </p>
    <ol>
      <li>
        <strong>Uso da Funcionalidade:</strong> Você concorda em usar a funcionalidade de incorporação de vídeos de acordo com estes termos e com os termos de serviço da plataforma de origem do vídeo. A Tribhus fornece esta funcionalidade "como está" e não garante sua disponibilidade contínua.
      </li>
      <li>
        <strong>Responsabilidade pelo Conteúdo:</strong> Embora a Tribhus facilite a incorporação de vídeos de plataformas de terceiros, você é responsável por garantir que tem o direito de usar e compartilhar o conteúdo que incorpora.
      </li>
      <li>
        <strong>Remoção de Conteúdo:</strong> A Tribhus se reserva o direito de remover qualquer vídeo incorporado que viole estes termos, receba reclamações válidas de direitos autorais, ou por qualquer outro motivo, a critério exclusivo da Tribhus.
      </li>
      <li>
        <strong>Isenção de Responsabilidade:</strong> A Tribhus não é responsável pelo conteúdo dos vídeos incorporados e não endossa nenhum conteúdo de terceiros exibido através desta funcionalidade.
      </li>
      <li>
        <strong>Alterações na Funcionalidade:</strong> A Tribhus pode modificar ou descontinuar a funcionalidade de incorporação de vídeos a qualquer momento, com ou sem aviso prévio.
      </li>
    </ol>

    <h4>6.3 Suas Garantias</h4>
    <p>
      Ao publicar conteúdo na plataforma Tribhus, você garante que:
    </p>
    <ul>
      <li>Todo o conteúdo, incluindo, mas não se limitando a, músicas, imagens e vídeos, é de sua autoria, ou você possui todas as licenças, autorizações e permissões necessárias para publicá-lo na plataforma.</li>
      <li>Nenhum conteúdo enviado infringe direitos de terceiros, incluindo, mas não se limitando a, direitos autorais, direitos de imagem, direitos de marca registrada, ou outros direitos de propriedade intelectual.</li>
      <li>Nenhum royalty ou taxa será devida pela Tribhus a terceiros pelo uso do conteúdo.</li>
    </ul>

    <h4>6.4 Licença Concedida à Tribhus</h4>
    <p>
      Você concede à Tribhus uma licença limitada, mundial, não exclusiva, intransferível e isenta de royalties para usar seu conteúdo exclusivamente para os seguintes propósitos:
    </p>
    <ul>
      <li>Usar, reproduzir, disponibilizar ao público, publicar e distribuir seu conteúdo dentro da plataforma Tribhus para fins de streaming e exibição aos usuários.</li>
      <li>Promover sua música e perfil dentro da plataforma Tribhus e em materiais de marketing relacionados à Tribhus.</li>
      <li>Incluir trechos limitados de suas músicas em playlists promocionais ou amostras dentro da plataforma Tribhus.</li>
    </ul>
    <p>
      Esta licença expressamente NÃO permite à Tribhus:
    </p>
    <ul>
      <li>Vender ou comercializar diretamente seu conteúdo, seja dentro ou fora da plataforma Tribhus, sem sua autorização expressa.</li>
      <li>Criar ou distribuir cópias não autorizadas de seu conteúdo para fins não relacionados ao serviço Tribhus.</li>
      <li>Modificar, remixar, ou criar obras derivadas de seu conteúdo sem sua permissão expressa.</li>
      <li>Sublicenciar seu conteúdo a terceiros fora do escopo necessário para a operação da plataforma Tribhus.</li>
      <li>Usar seu conteúdo de qualquer maneira que possa sugerir endosso ou afiliação além de sua participação na plataforma Tribhus.</li>
      <li>Expandir o uso do seu conteúdo para novos serviços ou funcionalidades que não estão diretamente relacionadas ao serviço atual da Tribhus, sem obter seu consentimento prévio e expresso.</li>
    </ul>
    <p>
      Esta licença permanece em vigor apenas enquanto seu conteúdo estiver na plataforma Tribhus e pode ser revogada mediante a remoção do seu conteúdo, sujeita a um período razoável para a Tribhus ajustar seus sistemas e materiais promocionais (previamente solicitado pelo artista, especificando o conteúdo a ser removido).
    </p>

    <h3>7. Nossos Direitos e Obrigações</h3>
    <h4>7.1 Moderação de Conteúdo</h4>
    <p>
      A Tribhus pode, mas não é obrigada a, monitorar, revisar ou editar o conteúdo do artista. Reservamos o direito de remover ou desativar o acesso a qualquer conteúdo que viole estes termos ou represente um risco para a Tribhus ou terceiros.
    </p>

    <h4>7.2 Propriedade Intelectual</h4>
    <p>
      Todo o conteúdo do serviço Tribhus, incluindo, mas não se limitando a, software, designs, textos, gráficos, logos, ícones e imagens, é de propriedade exclusiva da Tribhus ou de seus licenciadores.
    </p>

    <h4>7.3 Uso Limitado do Conteúdo do Artista</h4>
    <p>
      A Tribhus se compromete a usar o conteúdo fornecido pelos artistas apenas das seguintes formas e com as seguintes limitações:
    </p>
    <ul>
      <li>Promoção do Artista: Usaremos seu conteúdo para promover sua banda ou projeto dentro da plataforma Tribhus. Poderemos incluir suas músicas, imagens ou vídeos em playlists, recomendações ou destaques na plataforma, sempre com a devida atribuição.</li>
      <li>Marketing da Plataforma: Poderemos utilizar partes limitadas do seu conteúdo em materiais promocionais da Tribhus, sempre com atribuição clara à sua banda/projeto.</li>
      <li>Limitações Expressas: A Tribhus não venderá, regravará, fará remixes, ou distribuirá comercialmente suas músicas ou conteúdos completos. A Tribhus não adquire nenhum direito de propriedade sobre seu conteúdo.</li>
      <li>Transparência e Controle: Manteremos a transparência sobre o uso do seu conteúdo e forneceremos meios para você controlar ou solicitar a remoção do seu conteúdo de materiais promocionais.</li>
      <li>Duração: Nosso direito de uso permanece em vigor enquanto seu conteúdo estiver na plataforma. Ao remover seu conteúdo, este direito será automaticamente rescindido, exceto para materiais promocionais já criados e em uso.</li>
    </ul>

    <h3>8. Diretrizes do Usuário</h3>
    <p>
      Ao usar o serviço Tribhus para Artistas, você concorda em não:
    </p>
    <ul>
      <li>Copiar, redistribuir ou reproduzir qualquer parte do serviço sem autorização;</li>
      <li>Usar o serviço para importar ou copiar arquivos que você não tem direito legal;</li>
      <li>Realizar engenharia reversa ou criar trabalhos derivados do serviço;</li>
      <li>Contornar restrições territoriais ou tecnológicas;</li>
      <li>Manipular artificialmente contagens de reprodução;</li>
      <li>Usar bots, scrapers ou outros meios automatizados para coletar informações;</li>
      <li>Publicar conteúdo ilegal, ofensivo, difamatório ou que viole direitos de terceiros;</li>
      <li>Enviar spam ou mensagens não solicitadas;</li>
      <li>Interferir na operação do serviço ou tentar acessar áreas restritas;</li>
      <li>Envolver-se em qualquer atividade que tenha como objetivo manipular artificialmente contagens de reprodução, engajamento, ou qualquer outra métrica da plataforma.</li>
    </ul>

    <h3>9. Proteção de Dados e Privacidade</h3>
    <h4>9.1 Conformidade com a LGPD</h4>
    <p>
      A Tribhus está comprometida em proteger sua privacidade e cumprir a Lei Geral de Proteção de Dados (LGPD). Coletamos, usamos e protegemos seus dados pessoais de acordo com nossa Política de Privacidade.
    </p>

    <h4>9.2 Seus Direitos</h4>
    <p>
      Você tem direito a acessar, corrigir, portar, deletar seus dados pessoais e revogar seu consentimento. Para exercer esses direitos, entre em contato conosco através do email: <a href="mailto:admin@tribhus.com.br">admin@tribhus.com.br</a>.
    </p>

    <h4>9.3 Segurança dos Dados</h4>
    <p>
      Implementamos medidas técnicas e organizacionais para proteger seus dados contra acesso não autorizado, alteração, divulgação ou destruição.
    </p>

    <h4>9.4 Uso de Dados Pessoais para Promoção</h4>
    <p>
      Seus dados pessoais poderão ser usados para promover seu conteúdo dentro da plataforma Tribhus, sempre em conformidade com as disposições da LGPD e demais regulamentações aplicáveis. Você tem o direito de solicitar a restrição desse uso a qualquer momento.
    </p>

    <h3>10. Rescisão</h3>
    <p>
      Podemos suspender ou encerrar sua conta a qualquer momento por violação destes termos ou por qualquer outro motivo, a nosso critério. Você pode encerrar sua conta entrando em contato conosco através do email: <a href="mailto:admin@tribhus.com.br">admin@tribhus.com.br</a>.
    </p>
    <p>
      Antes de suspender ou encerrar sua conta, faremos esforços razoáveis para notificar você sobre a violação detectada e fornecer um prazo para que a situação seja corrigida, exceto em casos de violação grave que justifiquem a suspensão imediata.
    </p>

    <h3>11. Limitação de Responsabilidade</h3>
    <p>
      Na extensão máxima permitida por lei, a Tribhus não será responsável por danos indiretos, incidentais, especiais, consequenciais ou punitivos, incluindo perda de dados, lucros ou oportunidades de negócios.
    </p>

    <h3>12. Resolução de Conflitos</h3>
    <h4>12.1 Mediação e Arbitragem</h4>
    <p>
      Em caso de disputa decorrente ou relacionada a estes Termos, as partes concordam em buscar uma solução amigável por meio de mediação. Caso a mediação não resolva a disputa, as partes poderão optar pela arbitragem, conforme as regras da Câmara de Arbitragem de São Paulo, a ser realizada na cidade de Limeira, São Paulo.
    </p>

    <h4>12.2 Jurisdição</h4>
    <p>
      Se a disputa não for resolvida por meio de mediação ou arbitragem, ou se a arbitragem não for aplicável por alguma razão, as partes concordam que a disputa será resolvida nos tribunais da Comarca de Limeira, Estado de São Paulo.
    </p>

    <h3>13. Disposições Gerais</h3>
    <h4>13.1 Acordo Integral</h4>
    <p>
      Estes Termos, juntamente com a Política de Privacidade, constituem o acordo integral entre você e a Tribhus em relação ao uso do serviço.
    </p>

    <h4>13.2 Divisibilidade</h4>
    <p>
      Se qualquer disposição destes Termos for considerada inválida ou inexequível, as demais disposições permanecerão em pleno vigor e efeito.
    </p>

    <h4>13.3 Cessão</h4>
    <p>
      Você não pode ceder ou transferir estes Termos sem o consentimento prévio por escrito da Tribhus. A Tribhus pode ceder estes Termos livremente.
    </p>

    <h4>13.4 Contato</h4>
    <p>
      Para quaisquer dúvidas sobre estes Termos ou o serviço Tribhus para Artistas, entre em contato conosco através do email: <a href="mailto:admin@tribhus.com.br">admin@tribhus.com.br</a>.
    </p>

    <p>
      Ao utilizar os serviços da Tribhus, você confirma que revisou e compreendeu plenamente suas responsabilidades legais em relação ao conteúdo que publica, e que se compromete a garantir que todo o conteúdo enviado é de sua propriedade ou que você possui todas as licenças e autorizações necessárias para distribuí-lo.
    </p>
  </>
);

export default TermosBandaDocument;
