import React, { useEffect, useRef, useState } from 'react';
import axios from '../Services/axiosInterceptor'; 
import ModalFotos from './ModalFotos';

interface AlbumFotos {
  id_album_foto: number;
  nome: string;
  descricao: string;
  id_proprietario: number;
  tipo_proprietario: string;
  capa?: string;
  data_criacao?: string;
  imagens?: string[]; // Adiciona a propriedade para armazenar URLs das imagens
}

interface Foto {
  id_foto: number;
  url_foto: string;
  descricao: string | null;
  titulo: string | null;
  caminho_arquivo: string;
  data_upload: string;
  id_album_foto: number;
}

interface AlbunsFotosProps {
  id_banda: number;
  token: string;
}

const AlbunsFotos: React.FC<AlbunsFotosProps> = ({ id_banda, token }) => {
  const [selectedAlbum, setSelectedAlbum] = useState<AlbumFotos | null>(null);
  const [albumsFotos, setAlbumsFotos] = useState<AlbumFotos[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const handleAlbumClick = (album: AlbumFotos) => {
    console.log('Álbum selecionado:', album);
    setSelectedAlbum(album);
  };

  useEffect(() => {
    const buscarAlbuns = async () => {
        try {
          const response = await axios.get(`https://tribhus.shop:5000/fotos/albuns`, {
            params: {
              id_proprietario: id_banda,
              tipo_proprietario: 'banda'
            },
            headers: { Authorization: `Bearer ${token}` }
          });
          const albuns: AlbumFotos[] = response.data;
      
          // Para cada álbum, buscar as fotos associadas
          const albunsComImagens: AlbumFotos[] = await Promise.all(albuns.map(async (album: AlbumFotos) => {
            const fotosResponse = await axios.get(`https://tribhus.shop:5000/fotos/fotos/${album.id_album_foto}`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            const fotos = fotosResponse.data.map((foto: Foto) => foto.url_foto);
            return { ...album, imagens: fotos }; // Adiciona as imagens ao álbum
          }));
      
          setAlbumsFotos(albunsComImagens);
        } catch (error) {
          console.error('Erro ao buscar álbuns:', error);
        }
      };    

    if (token) {
      buscarAlbuns();
    }
  }, [id_banda, token]);

  const handleScroll = (direction: string) => {
    if (direction === "left") {
      listRef.current?.scrollBy({
        top: 0,
        left: -window.innerWidth / 2,
        behavior: "smooth"
      });
    } else {
      listRef.current?.scrollBy({
        top: 0,
        left: window.innerWidth / 2,
        behavior: "smooth"
      });
    }
  };

  const checkArrowsVisibility = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener('scroll', checkArrowsVisibility);
    }
    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener('scroll', checkArrowsVisibility);
      }
    };
  }, []);

  return (
    <div>
      <p style={styles.sectionTitle}>Álbuns de Fotos</p>
      <div style={styles.container}>
        {showLeftArrow && (
          <div style={{ ...styles.arrow, ...styles.leftArrow }} onClick={() => handleScroll("left")}>
            &#9664;
          </div>
        )}
        <div style={styles.list} ref={listRef}>
          {albumsFotos.map((item: AlbumFotos) => (
            <div key={item.id_album_foto} style={styles.card} onClick={() => handleAlbumClick(item)}>
              <div style={styles.gridContainer}>
                {item.imagens && item.imagens.length > 0 ? (
                  item.imagens.slice(0, 4).map((imagem, index) => (
                    <img
                      key={index}
                      src={imagem}
                      alt={`Miniatura ${index + 1}`}
                      style={index % 2 === 0 ? styles.gridImageLeft : styles.gridImageRight}
                    />
                  ))
                ) : (
                  <div style={styles.noImagePlaceholder}>Sem imagens</div>
                )}
              </div>
              <p style={styles.title}>{item.nome}</p>
              <p style={styles.year}>
                {item.data_criacao ? new Date(item.data_criacao).getFullYear() : 'N/A'}
              </p>
            </div>
          ))}
        </div>
        {showRightArrow && (
          <div style={{ ...styles.arrow, ...styles.rightArrow }} onClick={() => handleScroll("right")}>
            &#9654;
          </div>
        )}
        {selectedAlbum && (
          <ModalFotos
            isOpen={true}
            album={selectedAlbum}
            onClose={() => setSelectedAlbum(null)}
            token={token}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row' as const,
    overflow: 'hidden',
    position: 'relative' as const,
    maxWidth: '100%',
    margin: '0 auto',
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold' as const,
    color: '#FF6600',
    margin: 0,
    marginTop: '40px',
  },
  list: {
    display: 'flex',
    flexDirection: 'row' as const,
    overflowX: 'scroll' as const,
    scrollSnapType: 'x mandatory' as const,
    scrollBehavior: 'smooth' as const,
    scrollbarWidth: 'none' as const,
    msOverflowStyle: 'none' as const,
    padding: '0 20px',
  },
  card: {
    flex: '0 0 auto',
    scrollSnapAlign: 'start' as const,
    margin: 5,
    padding: 10,
    borderRadius: 10,
    position: 'relative' as const,
    width: '200px',
    backgroundColor: '#202020',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    width: '180px',
    height: '180px',
    backgroundColor: '#000',
  },
  gridImageLeft: {
    width: '49%',
    height: '49%',
    marginBottom: '2%',
    objectFit: 'cover' as 'cover', // Definindo o valor como um literal
  },
  gridImageRight: {
    width: '49%',
    height: '49%',
    marginBottom: '2%',
    marginLeft: '2%',
    objectFit: 'cover' as 'cover', // Definindo o valor como um literal
  },
  noImagePlaceholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
    fontSize: '12px',
  },
  title: {
    color: 'lightgray',
    fontSize: 16,
    fontWeight: 'bold',
    margin: '10px 0',
  },
  year: {
    color: 'lightgray',
    fontSize: 12,
  },
  arrow: {
    position: 'absolute' as const,
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    color: 'lightgray',
    cursor: 'pointer',
    zIndex: 10,
    transition: 'opacity 0.3s',
    opacity: 0.5,
  },
  leftArrow: {
    left: '10px',
  },
  rightArrow: {
    right: '10px',
  },
};

export default AlbunsFotos;
