import React, { useContext, useState } from 'react';
import { BandaContext } from '../context/BandaContext';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const TelaBanda2: React.FC = () => {
  const { state, dispatch } = useContext(BandaContext);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState('');

  const handleNextButton = () => {
    if (!state.nome_responsavel) {
      toast.error('Por favor, insira o nome do responsável.');
      return;
    }

    if (!state.sobrenome_responsavel) {
      toast.error('Por favor, insira o sobrenome do responsável.');
      return;
    }

    if (!state.nasc_responsavel) {
      toast.error('Por favor, insira a data de nascimento do responsável.');
      return;
    }

    navigate('/TelaBanda3');
  };

  const formatInputDate = (value: string) => {
    // Remove tudo que não for dígito
    let input = value.replace(/\D/g, '');

    // Limita a quantidade de números (até 8 dígitos: DDMMYYYY)
    if (input.length > 8) {
      input = input.slice(0, 8);
    }

    // Aplica as barras no formato DD/MM/YYYY
    if (input.length >= 5) {
      input = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4)}`;
    } else if (input.length >= 3) {
      input = `${input.slice(0, 2)}/${input.slice(2)}`;
    }

    return input;
  };

  const handleDateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = formatInputDate(e.target.value);
    setSelectedDate(formattedDate);

    // Verifica se a data tem o formato completo (DD/MM/YYYY) para salvar no estado global
    if (formattedDate.length === 10) {
      dispatch({ type: 'SET_FORM', field: 'nasc_responsavel', value: formattedDate });
    } else {
      dispatch({ type: 'SET_FORM', field: 'nasc_responsavel', value: '' });
    }
  };

  return (
    <div style={styles.container}>
      <ToastContainer position="top-center" />
      <img src={require('../assets/logotipo.png')} alt="Logotipo" style={styles.logo} />

      <button onClick={() => navigate(-1)} style={styles.backButton}>
        <img src={require('../assets/voltar.png')} alt="Voltar" style={styles.backIcon} />
      </button>

      <h1 style={styles.title}>Por favor, insira seus dados pessoais.</h1>

      <div style={styles.inputContainer}>
        <label style={styles.label}>Nome</label>
        <input
          style={styles.input}
          onChange={(e) => dispatch({ type: 'SET_FORM', field: 'nome_responsavel', value: e.target.value })}
          value={state.nome_responsavel}
          placeholder="Nome do Responsável"
        />
      </div>

      <div style={styles.inputContainer}>
        <label style={styles.label}>Sobrenome</label>
        <input
          style={styles.input}
          onChange={(e) => dispatch({ type: 'SET_FORM', field: 'sobrenome_responsavel', value: e.target.value })}
          value={state.sobrenome_responsavel}
          placeholder="Sobrenome do Responsável"
        />

        <label style={styles.label}>Data de nascimento do responsável</label>
        <div>
          <input
            type="text"
            style={styles.input}
            value={selectedDate}
            onChange={handleDateInputChange}
            placeholder="DD/MM/AAAA"
            maxLength={10} // Limita a entrada a 10 caracteres
          />
        </div>
      </div>

      <button style={styles.button} onClick={handleNextButton}>
        <span style={styles.buttonText}>Próximo</span>
      </button>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#151922', // Mesma cor de fundo da TelaBanda1
    padding: '20px',
  },
  logo: {
    width: '280px',
    height: '360px',
    objectFit: 'contain',
    marginBottom: '20px',
  },
  label: {
    fontSize: '14px',
    color: '#FF6600', 
    textAlign: 'center',
  },
  backButton: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    background: 'none',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
  },
  title: {
    fontSize: '20px',
    color: '#fff', // Cor do título como na TelaBanda1
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '-30px',
  },
  inputContainer: {
    width: '80%',
    maxWidth: '300px',
    marginBottom: '30px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '10px',
    backgroundColor: '#151922',
    borderColor: '#914100',
    borderWidth: '1px',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
  },
  button: {
    backgroundColor: '#914100',
    padding: '15px 50px',
    borderRadius: '10px',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    textAlign: 'center',
    width: '200px',
    margin: '10px auto',
  },
  buttonText: {
    color: '#fff',
    fontSize: '16px',
  },
  backIcon: {
    width: 30,
    height: 30,
  },
};

export default TelaBanda2;
