import React from 'react';
import SobreDocument from './SobreDocument';
import TermosBandaDocument from './TermosBandaDocument';
import TermosUsuarioDocument from './TermosUsuarioDocument';
import PrivacidadeDocument from './PrivacidadeDocument';
import GuiaAjudaDocument from './GuiaAjudaDocument'; // Importe o novo documento

type DocumentType = 'sobre' | 'termos-banda' | 'termos-usuario' | 'privacidade' | 'guia-ajuda'; // Adicione 'guia-ajuda' aqui

interface DocumentModalProps {
  content: DocumentType;
  onClose: () => void;
}

const DocumentModal: React.FC<DocumentModalProps> = ({ content, onClose }) => {
  const getDocumentComponent = () => {
    switch (content) {
      case 'sobre':
        return <SobreDocument />;
      case 'termos-banda':
        return <TermosBandaDocument />;
      case 'termos-usuario':
        return <TermosUsuarioDocument />;
      case 'privacidade':
        return <PrivacidadeDocument />;
      case 'guia-ajuda': // Adicione o caso para 'guia-ajuda'
        return <GuiaAjudaDocument />;
      default:
        return <p>Conteúdo não encontrado</p>;
    }
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div style={styles.modalOverlay} onClick={handleOverlayClick}>
      <div style={styles.modalContent}>
        <button style={styles.closeButton} onClick={onClose}>X</button>
        <div style={styles.documentContent}>
          {getDocumentComponent()}
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#f0f0f0',
    padding: '2rem',
    borderRadius: '10px',
    maxWidth: '40%',
    maxHeight: '40%',
    overflow: 'auto',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '1.5rem',
    cursor: 'pointer',
  },
  documentContent: {
    color: '#333',
  },
};

export default DocumentModal;
