import React, { useReducer, createContext, Dispatch, ReactNode } from 'react';

interface State {
  slugPerfil: string;
}

const initialState: State = {
  slugPerfil: '',
};

type Action = 
  | { type: 'SET_SLUG_PERFIL'; slugPerfil: string };

  const reducer = (state: State, action: Action) => {
    switch (action.type) {
      case 'SET_SLUG_PERFIL':
        console.log('Action received:', action); // log the action
        const newState = { ...state, slugPerfil: action.slugPerfil };
        console.log('New state:', newState); // log the new state
        return newState;
      default:
        return state;
    }
  };  

interface ContextProps {
  state: State;
  dispatch: Dispatch<Action>;
}

export const SlugContext = createContext<ContextProps>({
  state: initialState,
  dispatch: () => null,
});

interface SlugProviderProps {
  children: ReactNode;
}

export const SlugProvider: React.FC<SlugProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SlugContext.Provider value={{ state, dispatch }}>
      {children}
    </SlugContext.Provider>
  );
};
