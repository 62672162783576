import React from 'react';

interface ProfileAlertProps {
  missingInfo: string[];
  onClose: () => void;
}

const ProfileAlert: React.FC<ProfileAlertProps> = ({ missingInfo, onClose }) => {
  return (
    <div style={{
      backgroundColor: '#f8d7da',
      color: '#000',
      padding: '12px',
      borderRadius: '4px',
      marginBottom: '16px',
      border: '1px solid #f5c6cb'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '8px'
      }}>
        <strong>Por favor, Complete seu perfil. Quanto mais completo, mais engajamento você terá!</strong>
        <button 
          onClick={onClose}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '18px'
          }}
        >
          &times;
        </button>
      </div>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        {missingInfo.includes('photo') && <li>Adicione uma foto de perfil</li>}
        {missingInfo.includes('description') && <li>Adicione uma descrição</li>}
        {missingInfo.includes('genres') && <li>Selecione seus gêneros de Rock</li>}
      </ul>
    </div>
  );
};

export default ProfileAlert;