import React, { useState, useEffect } from 'react';
import axios from '../Services/axiosInterceptor'; 

interface EventDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  eventId: number | null;
  token: string;
  idUsuario?: number; 
  onEventDeleted?: () => void; 
  isOwner: boolean;
}

interface Event {
  id_evento: number;
  nome_evento: string;
  data_evento: string;
  local_evento: string;
  cidade: string;
  estado: string;
  descricao: string;
  url_imagem_evento: string;
}

const EventDetailsModal: React.FC<EventDetailsModalProps> = ({ isOpen, onClose, eventId, token, idUsuario, onEventDeleted, isOwner }) => {
  const [event, setEvent] = useState<Event | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false); 
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen && eventId) {
      setLoading(true);
      axios.get<Event>(`https://tribhus.shop:5000/eventos/event/${eventId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(response => {
          setEvent(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching event details:', error);
          setLoading(false);
        });

      // Verificar se o usuário já confirmou presença no evento
      axios.get(`https://tribhus.shop:5000/homeuser/event/${eventId}/check`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(response => {
          setIsConfirmed(response.data.isConfirmed); // Atualiza se o usuário já confirmou presença
        })
        .catch(error => {
          console.error('Erro ao verificar confirmação de presença:', error);
        });
    }
  }, [isOpen, eventId, token]);

  const confirmPresence = (publico: boolean) => {
    if (!eventId) return;
    
    setIsConfirming(true);
    axios.post(`https://tribhus.shop:5000/homeuser/add-to-events`, {
      id_evento: eventId,
      publico
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        alert('Presença confirmada com sucesso!');
        setIsConfirmed(true); // Atualiza o estado para indicar que a presença foi confirmada
        setIsConfirming(false);
      })
      .catch(error => {
        console.error('Erro ao confirmar presença:', error);
        alert('Erro ao confirmar presença. Tente novamente.');
        setIsConfirming(false);
      });
  };

  // Função para remover o evento da lista de eventos confirmados do usuário
  const removeFromConfirmedEvents = () => {
    if (!eventId || !idUsuario) return;

    setIsRemoving(true);
    axios.delete(`https://tribhus.shop:5000/homeuser/remove-from-events`, {
      headers: { Authorization: `Bearer ${token}` },
      data: {
        id_usuario: idUsuario,
        id_evento: eventId,
      }
    })
      .then(() => {
        alert('Presença removida com sucesso!');
        setIsConfirmed(false); // Atualiza o estado para indicar que a presença foi removida
        setIsRemoving(false);
      })
      .catch(error => {
        console.error('Erro ao remover a presença do evento:', error);
        alert('Erro ao remover a presença do evento. Tente novamente.');
        setIsRemoving(false);
      });
  };

  const removeEvent = () => {
    if (!eventId) return;
  
    setIsRemoving(true);
    axios.delete(`https://tribhus.shop:5000/eventos/event/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(() => {
      alert('Evento removido com sucesso!');
      setIsRemoving(false);
      if (onEventDeleted) {
        onEventDeleted(); // Chama a função de callback para atualizar os eventos se ela foi passada
      }
      onClose(); // Fecha o modal
    })
    .catch(error => {
      console.error('Erro ao remover o evento:', error);
      alert('Erro ao remover o evento. Tente novamente.');
      setIsRemoving(false);
    });
  };

  if (!isOpen) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>Fechar</button>
        {loading ? (
          <div style={styles.loading}>Carregando...</div>
        ) : event ? (
          <>
            <div style={styles.content}>
              <img 
                src={event.url_imagem_evento} 
                alt={event.nome_evento} 
                style={styles.eventImage} 
              />
              <div style={styles.eventInfo}>
                <h1 style={styles.eventTitle}>{event.nome_evento}</h1>
                <h2 style={styles.eventDate}>
                  {new Date(event.data_evento).toLocaleDateString()}
                </h2>
                <p style={styles.eventLocation}>
                  {`${event.local_evento}, ${event.cidade}, ${event.estado}`}
                </p>
              </div>
            </div>
            <div style={styles.description}>
              <h2 style={styles.descriptionTitle}>Descrição:</h2>
              <p style={styles.descriptionText}>{event.descricao}</p>
            </div>
            
            {/* Exibir botões dependendo se é o dono do evento ou não */}
            {isOwner ? (
              <button 
                style={styles.removeButton} 
                onClick={removeEvent}
                disabled={isRemoving}
              >
                {isRemoving ? 'Removendo...' : 'Remover Evento'}
              </button>
            ) : (
              <>
            {isConfirmed ? (
              <>
                <p>Você já confirmou presença neste evento.</p>
                {isOwner && (
                  <button 
                    style={styles.removeButton} 
                    onClick={removeFromConfirmedEvents} 
                    disabled={isRemoving}
                  >
                    {isRemoving ? 'Removendo...' : 'Remover evento de minha lista'}
                  </button>
                )}
              </>
                ) : (
                  <button 
                    style={styles.removeButton} 
                    onClick={() => confirmPresence(true)} 
                    disabled={isConfirming}
                  >
                    {isConfirming ? 'Confirmando...' : 'Confirmar Presença'}
                  </button>
                )}
              </>
            )}
          </>
        ) : (
          <div style={styles.error}>Falha ao carregar detalhes do evento</div>
        )}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  } as React.CSSProperties,
  modal: {
    backgroundColor: '#202020',
    padding: '20px',
    width: '60%',
    maxHeight: '80%',
    overflowY: 'auto',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  } as React.CSSProperties,
  closeButton: {
    alignSelf: 'flex-end',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'lightgray',
    fontSize: '16px',
    cursor: 'pointer',
  } as React.CSSProperties,
  content: {
    display: 'flex',
    gap: '20px',
  } as React.CSSProperties,
  eventImage: {
    maxWidth: '200px',
    maxHeight: '200px',
    borderRadius: '10px',
    objectFit: 'cover',
  } as React.CSSProperties,
  eventInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
  } as React.CSSProperties,
  eventTitle: {
    color: 'lightgray',
    fontSize: '24px',
    margin: 0,
  } as React.CSSProperties,
  eventDate: {
    color: '#ccc',
    fontSize: '18px',
    margin: 0,
  } as React.CSSProperties,
  eventLocation: {
    color: '#ccc',
    fontSize: '16px',
    margin: 0,
  } as React.CSSProperties,
  description: {
    marginTop: '20px',
  } as React.CSSProperties,
  descriptionTitle: {
    color: 'lightgray',
    fontSize: '20px',
    marginBottom: '10px',
  } as React.CSSProperties,
  descriptionText: {
    color: 'lightgray',
    fontSize: '16px',
    lineHeight: '1.5',
  } as React.CSSProperties,
  loading: {
    color: 'lightgray',
    fontSize: '18px',
    textAlign: 'center',
  } as React.CSSProperties,
  removeButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#d9534f',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  } as React.CSSProperties,
  error: {
    color: 'red',
    fontSize: '18px',
    textAlign: 'center',
  } as React.CSSProperties,
};

export default EventDetailsModal;