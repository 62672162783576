import React, { useState, useEffect } from 'react';

type DescriBandaProps = {
  descricao: string;
};

const DescriBanda = ({ descricao }: DescriBandaProps) => {
  const [descricaoBanda, setDescricaoBanda] = useState(descricao || '');

  useEffect(() => {
    setDescricaoBanda(descricao || '');
  }, [descricao]);

  return (
    <div style={styles.descriptionContainer}>
      <p style={styles.descriptionText}>
        {descricaoBanda || 'Nenhuma descrição fornecida.'}
      </p>
    </div>
  );
};

const styles = {
  descriptionContainer: {
    backgroundColor: '#2D2D2D',
    padding: 10,
    paddingTop: 20,
    borderRadius: 10,
    marginTop: 30,
    marginBottom: 30,
    position: "relative" as "relative",
    maxWidth: '900px',
    margin: '0 auto',
  },
  descriptionText: {
    color: '#FFFFFF',
    marginTop: 5,
    textAlign: 'justify' as 'justify', // Justifica o texto
  },
};

export default DescriBanda;
