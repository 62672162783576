import { configureStore } from '@reduxjs/toolkit';
import topSongsReducer from './slices/topSongsSlice';
import playingSongReducer from './slices/playingSongSlice';

const store = configureStore({
  reducer: {
    topSongs: topSongsReducer,
    playingSong: playingSongReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>

export default store;
