import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../Services/axiosInterceptor';
import { BandaContext } from '../context/BandaContext';

const CodSMS: React.FC = () => {
  // Estados
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const [sendAttempts, setSendAttempts] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(140); // 2 minutos e 20 segundos
  const [lastSentMethod, setLastSentMethod] = useState<'sms' | 'whatsapp' | null>(null);
  const [toast, setToast] = useState({ show: false, message: '', type: 'info' as ToastType });

  // Contexto e hooks de navegação
  const { state, dispatch } = useContext(BandaContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Definição de tipo para o toast
  type ToastType = 'info' | 'success' | 'error' | 'warning';

  // useEffect para verificar e atualizar o auth_id no contexto
  useEffect(() => {
    const storedAuthId = localStorage.getItem('auth_id');
    
    if (!storedAuthId && !state.auth_id && location.pathname !== '/login') {
      navigate('/login');
    } else if (storedAuthId && !state.auth_id) {
      const parsedAuthId = parseInt(storedAuthId, 10);
      if (!isNaN(parsedAuthId)) {
        console.log('Valid auth_id found in localStorage, updating context:', parsedAuthId);
        dispatch({ type: 'SET_BANDA_AUTH_ID', auth_id: parsedAuthId });
      } else {
        console.error('Stored auth_id is not a valid number');
      }
    }
  }, [dispatch, navigate, location, state.auth_id]);

  // useEffect para redirecionar se não houver auth_id
  useEffect(() => {
    const storedAuthId = localStorage.getItem('auth_id');
    if (storedAuthId) {
      const parsedAuthId = parseInt(storedAuthId, 10);
      if (!isNaN(parsedAuthId)) {
        dispatch({ type: 'SET_BANDA_AUTH_ID', auth_id: parsedAuthId });
      } else {
        console.error('auth_id armazenado não é um número válido');
        navigate('/PrimeiraTela');
      }
    } else {
      navigate('/PrimeiraTela');
    }
  }, [dispatch, navigate]);

  // useEffect para gerenciar o countdown e exibir toast quando o tempo expirar
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (showVerificationInput && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      showToast(`Tempo expirado. Por favor, tente enviar o código ${lastSentMethod === 'sms' ? 'pelo WhatsApp' : 'por SMS'}.`, 'warning');
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [showVerificationInput, countdown, lastSentMethod]);

  // useEffect para gerenciar a exibição do toast
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (toast.show) {
      timer = setTimeout(() => {
        setToast(prev => ({ ...prev, show: false }));
      }, 3000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [toast]);

  // Função para exibir o toast
  const showToast = (message: string, type: ToastType = 'info') => {
    setToast({ show: true, message, type });
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, '');
    let formattedNumber = '';
    if (value.length > 2 && value.length <= 6) {
      formattedNumber = `(${value.slice(0, 2)}) ${value.slice(2)}`;
    } else if (value.length > 6 && value.length <= 10) {
      formattedNumber = `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6)}`;
    } else if (value.length > 10) {
      formattedNumber = `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
    } else {
      formattedNumber = value;
    }
    setPhoneNumber(formattedNumber);
  };

  const handleVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    setVerificationCode(value);
  };

  const submitPhoneNumber = async (method: 'sms' | 'whatsapp') => {
    if (phoneNumber.replace(/\D/g, '').length < 11) {
      showToast("Por favor, insira um número de telefone completo e válido.", "error");
      return;
    }

    if (sendAttempts >= 2) {
      showToast("Não foi possível enviar o código, por favor entre em contato.", "error");
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const endpoint = method === 'sms' ? '/users/send-sms' : '/users/send-whatsapp';
      const response = await axios.post(`https://tribhus.shop:5000${endpoint}`, {
        phoneNumber,
        auth_id: state.auth_id
      });

      if (response.data.message.includes("enviado com sucesso")) {
        setShowVerificationInput(true);
        setCountdown(140);
        setLastSentMethod(method);
        showToast(`Código enviado com sucesso ${method === 'sms' ? 'por SMS' : 'pelo WhatsApp'}.`, "success");
      } else {
        setSendAttempts(prev => prev + 1);
        showToast(`Falha ao enviar o código ${method === 'sms' ? 'por SMS' : 'pelo WhatsApp'}. Tente novamente.`, "error");
      }
    } catch (error) {
      console.error('Error submitting phone number:', error);
      setSendAttempts(prev => prev + 1);
      showToast('Erro ao conectar ao servidor. Por favor, tente novamente.', "error");
    } finally {
      setIsLoading(false);
    }
  };

  const verifySMSCode = async () => {
    if (!verificationCode) {
      showToast("Por favor, insira o código de verificação.", "error");
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post('https://tribhus.shop:5000/users/verify-sms-code', {
        phoneNumber,
        verificationCode,
        auth_id: state.auth_id
      });

      if (response.data.isVerified) {
        showToast("Código verificado com sucesso!", "success");
        navigate('/TelaBanda2');
      } else {
        showToast('Código de verificação inválido. Tente novamente.', "error");
      }
    } catch (error) {
      showToast('Erro ao verificar o código. Tente novamente.', "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      {toast.show && (
      <div style={{ ...styles.toast, ...styles[toast.type] }}>
          {toast.message}
        </div>
      )}
      <img src={require('../assets/logotipo.png')} alt="Logo" style={styles.logo} />
      <p style={styles.instruction}>Por favor, insira seu número de celular:</p>
      <input
        style={styles.input}
        onChange={handlePhoneChange}
        value={phoneNumber}
        type="tel"
        placeholder="(xx)9 XXXX-XXXX"
        disabled={isLoading}
      />
      {error && <p style={styles.error}>{error}</p>}
      {showVerificationInput && (
        <>
          <input
            className="no-spinner"
            style={styles.input}
            placeholder="Código de Verificação"
            onChange={handleVerificationCodeChange}
            value={verificationCode}
            type="text"
            inputMode="numeric"
            pattern="\d*"
            maxLength={6}
            disabled={isLoading}
          />
          <button style={styles.button} onClick={verifySMSCode} disabled={isLoading}>
            Validar código e prosseguir
          </button>
          <p style={styles.countdown}>
            Por favor, aguarde! Tempo restante: {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')}
          </p>
          {countdown === 0 && (
            <p style={styles.contactInfo}>
              Não conseguiu? Entre em contato pelo WhatsApp: <span style={styles.whatsAppNumber}>19 99664-7030</span>
            </p>
          )}
        </>
      )}
      <div style={styles.buttonContainer}>
        {sendAttempts < 2 ? (
          <>
            <button style={styles.button} onClick={() => submitPhoneNumber('sms')} disabled={isLoading}>
              {isLoading ? 'Enviando...' : 'Enviar código por SMS'}
            </button>
            <button style={{ ...styles.button, marginTop: '10px' }} onClick={() => submitPhoneNumber('whatsapp')} disabled={isLoading}>
              {isLoading ? 'Enviando...' : 'Enviar código pelo WhatsApp'}
            </button>
          </>
        ) : (
          <p style={styles.contactInfo}>
            Entre em contato pelo WhatsApp: <span style={styles.whatsAppNumber}>19 99664-7030</span>
          </p>
        )}
      </div>
      <p style={styles.securityText}>
        Validar o seu número de celular é crucial para garantir a segurança da sua conta. Esse processo nos ajuda a proteger suas informações contra acessos não autorizados e permite que você recupere sua conta mais facilmente em caso de problemas.
      </p>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    backgroundColor: '#1E1E1E',
    minHeight: '100vh',
    position: 'relative',
  },
  logo: {
    width: 'calc(100% - 80px)',
    maxWidth: '300px',
    height: 'auto',
    marginBottom: '20px',
  },
  instruction: {
    fontSize: '16px',
    color: '#FFF',
    marginBottom: '10px',
    textAlign: 'center',
  },
  input: {
    height: '40px',
    borderColor: 'gray',
    borderWidth: '1px',
    borderStyle: 'solid',
    marginBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: '#FFF',
    width: '80%',
    maxWidth: '300px',
    borderRadius: '20px',
    color: '#000',
  },
  buttonContainer: {
    width: '80%',
    maxWidth: '500px',
    marginTop: '10px',
  },
  button: {
    width: 'calc(50% - 5px)',
    padding: '10px',
    backgroundColor: 'transparent',
    color: '#FF6600',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    transition: 'color 0.3s ease',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    maxWidth: '500px',
    marginBottom: '10px',
  },
  error: {
    color: 'red',
    fontSize: '14px',
  },
  securityText: {
    fontSize: '14px',
    color: 'lightgray',
    marginTop: '50px',
    textAlign: 'center',
    maxWidth: '80%',
  },
  countdown: {
    color: '#FFF',
    fontSize: '14px',
    marginTop: '10px',
  },
  toast: {
    position: 'fixed',
    top: '20px',
    right: '20px',
    padding: '10px 20px',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '14px',
    zIndex: 1000,
  },
  info: {
    backgroundColor: '#3498db',
  },
  success: {
    backgroundColor: '#2ecc71',
  },
  warning: {
    backgroundColor: '#f39c12',
  },
  contactInfo: {
    color: '#FFF',
    fontSize: '14px',
    marginTop: '20px',
    textAlign: 'center',
  },
  whatsAppNumber: {
    color: '#25D366', // Verde do WhatsApp
    fontWeight: 'bold',
  },
};

export default CodSMS;