import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor';

interface CustomJwtPayload {
  exp: number;
  auth_id: number;
  specific_id: number;
  role: 'usuario' | 'banda';
}

const ProtectedLayout: React.FC = () => {
  const { state, dispatch: authDispatch, updateToken } = useContext(AuthContext);
  const [isChecking, setIsChecking] = useState(true);
  const updateTokenRef = useRef(updateToken);

  useEffect(() => {
    updateTokenRef.current = updateToken;
  }, [updateToken]);

  const updateAuthContext = useCallback((token: string) => {
    const decodedToken: CustomJwtPayload = jwtDecode(token);
    if (decodedToken.role === 'banda') {
      authDispatch({ type: 'SET_BANDA_ID', id_banda: decodedToken.specific_id });
    } else if (decodedToken.role === 'usuario') {
      authDispatch({ type: 'SET_USUARIO_ID', id_usuario: decodedToken.specific_id });
    }
    authDispatch({ type: 'SET_TOKEN', token });
  }, [authDispatch]);

  const clearTokensAndRedirect = useCallback(() => {
    console.log("Limpando tokens e redirecionando...");
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('sessionId');
    authDispatch({ type: 'RESET_AUTH' });
    setIsChecking(false);
  }, [authDispatch]);

  const refreshTokenAndVerify = async (refreshToken: string): Promise<boolean> => {
    try {
      console.log("Iniciando processo de refresh e verificação...");
      
      // 1. Primeiro faz o refresh
      const response = await axios.post('/users/refresh', {
        refreshToken,
        sessionId: localStorage.getItem('sessionId'),
        platform: 'web'
      });

      console.log("Resposta do refresh:", response.data);

      if (!response.data.accessToken || !response.data.refreshToken) {
        console.log("Refresh falhou - tokens ausentes na resposta");
        return false;
      }

      // 2. Atualiza os tokens localmente
      await updateTokenRef.current(response.data.accessToken, response.data.refreshToken);
      updateAuthContext(response.data.accessToken);

      // 3. Verifica a validade usando o NOVO refresh token
      const verifyResponse = await axios.post('/delete/verify-tokenreact', 
        { refreshToken: response.data.refreshToken },
        {
          headers: {
            'Authorization': `Bearer ${response.data.accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log("Resposta da verificação:", verifyResponse.data);
      return verifyResponse.data.valid === true;

    } catch (error) {
      console.error('Erro durante refresh e verificação:', error);
      return false;
    }
  };
  
  useEffect(() => {
    const verifySessionAndCheckTokens = async () => {
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const accessToken = localStorage.getItem('accessToken');
    
        console.log("Verificando tokens...");
        console.log("Refresh token:", refreshToken ? "presente" : "ausente");
        console.log("Access token:", accessToken ? "presente" : "ausente");
    
        if (!refreshToken || !accessToken) {
          console.log("Token(s) ausente(s)");
          clearTokensAndRedirect();
          return;
        }

        try {
          // Verifica se o access token está expirado
          const decodedToken: CustomJwtPayload = jwtDecode(accessToken);
          const currentTimestamp = Math.floor(Date.now() / 1000);

          let isValid = false;

          if (decodedToken.exp < currentTimestamp) {
            // Se expirado, faz refresh e verifica
            console.log("Access token expirado, iniciando refresh...");
            isValid = await refreshTokenAndVerify(refreshToken);
          } else {
            // Se não expirado, apenas verifica
            console.log("Access token válido, verificando sessão...");
            const verifyResponse = await axios.post('/delete/verify-tokenreact', 
              { refreshToken },
              {
                headers: {
                  'Authorization': `Bearer ${accessToken}`,
                  'Content-Type': 'application/json'
                }
              }
            );
            isValid = verifyResponse.data.valid === true;

            // Se a verificação falhar, tenta fazer refresh
            if (!isValid) {
              console.log("Verificação falhou, tentando refresh...");
              isValid = await refreshTokenAndVerify(refreshToken);
            } else {
              updateAuthContext(accessToken);
            }
          }

          if (!isValid) {
            console.log("Sessão inválida após todas as tentativas");
            clearTokensAndRedirect();
            return;
          }

          console.log("Sessão validada com sucesso!");
          setIsChecking(false);

        } catch (error) {
          console.error('Erro ao processar tokens:', error);
          clearTokensAndRedirect();
        }

      } catch (error) {
        console.error('Erro durante verificação de sessão:', error);
        clearTokensAndRedirect();
      }
    };
  
    verifySessionAndCheckTokens();
  }, [updateAuthContext, clearTokensAndRedirect]);

  if (isChecking) {
    return <div>Verificando autenticação...</div>;
  }

  if (!state.token) {
    return <Navigate to="/PrimeiraTela" />;
  }

  return <Outlet />;
};

export default ProtectedLayout;