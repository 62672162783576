import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { AuthContext } from '../context/AuthContext';
import CustomAlertModal from './CustomAlertModal';
import { useNavigate } from 'react-router-dom';

interface AmigosProps {
  idUsuarioRecebe?: number | null;
  isOwnProfile: boolean;
}

const AmigosUser: React.FC<AmigosProps> = ({ idUsuarioRecebe, isOwnProfile }) => {
  const [statusAmizade, setStatusAmizade] = useState('nao_amigos');
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [idUsuSolicita, setIdUsuSolicita] = useState<number | null>(null);
  const { state: { id_usuario, token } } = useContext(AuthContext);
  const navigate = useNavigate();

  // Verifica se deve exibir o botão de amizade
  const shouldShowFriendButton = id_usuario && idUsuarioRecebe;

  useEffect(() => {
    if (shouldShowFriendButton && id_usuario !== idUsuarioRecebe) {
      verificarAmizade();
    }
  }, [idUsuarioRecebe, token, shouldShowFriendButton]);

  const verificarAmizade = async () => {
    if (!token || !idUsuarioRecebe) return;

    try {
      const url = `https://tribhus.shop:5000/amigos/amigo/verificar/${idUsuarioRecebe}`;
      const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
      const { id_usu_solicita, status } = response.data;
      
      setIdUsuSolicita(id_usu_solicita);
      setStatusAmizade(status);
    } catch (error) {
      console.error('Erro ao verificar amizade:', error);
    }
  };

  const adicionarAmigo = async () => {
    if (!token || !idUsuarioRecebe) return;

    try {
      const response = await axios.post(
        'https://tribhus.shop:5000/amigos/amigo/adicionar',
        { id_usu_recebe: idUsuarioRecebe },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setStatusAmizade('aguardando');

      const idAmizade = response.data.id_amigo;
      const idUsuSolicitaFromResponse = response.data.id_usu_solicita;
      setIdUsuSolicita(idUsuSolicitaFromResponse);

      await axios.post(
        'https://tribhus.shop:5000/notificacoes/amigo',
        { id_usu_recebe: idUsuarioRecebe, id_amizade: idAmizade },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.error('Erro ao adicionar amigo:', error);
    }
  };

  const aceitarAmizade = async () => {
    if (!token || !idUsuSolicita) return;

    try {
      const url = `https://tribhus.shop:5000/amigos/amigo/aceitar/${idUsuSolicita}`;
      await axios.put(url, {}, { headers: { Authorization: `Bearer ${token}` } });
      setStatusAmizade('confirmado');
    } catch (error) {
      console.error('Erro ao aceitar amizade:', error);
    }
  };

  const removerAmizade = () => {
    setConfirmModalVisible(true);
  };

  const cancelarSolicitacaoAmizade = async () => {
    if (!token || !idUsuarioRecebe) return;

    try {
      await axios.delete(`https://tribhus.shop:5000/amigos/amigo/cancelar/${idUsuarioRecebe}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStatusAmizade('nao_amigos');
    } catch (error) {
      console.error('Erro ao cancelar solicitação de amizade:', error);
    }
  };

  const confirmarRemocaoAmizade = async () => {
    if (!token || !idUsuarioRecebe) return;

    try {
      await axios.delete(`https://tribhus.shop:5000/amigos/amigo/remover/${idUsuarioRecebe}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStatusAmizade('nao_amigos');
    } catch (error) {
      console.error('Erro ao remover amigo:', error);
    }
    setConfirmModalVisible(false);
  };

  const handleAmigosClick = () => {
    if (id_usuario) {
      navigate('/AmigosUser', { state: { id_usuario } });
    } else {
      console.error("ID do usuário não disponível.");
      // Considerar a navegação para uma tela de erro ou login aqui
    }
  };

  let buttonText = 'Amigos';
  if (!isOwnProfile) {
    buttonText = statusAmizade === 'confirmado' ? 'Amigo' :
                 statusAmizade === 'aguardando' && idUsuSolicita !== id_usuario ? 'Aceitar amigo' :
                 statusAmizade === 'aguardando' && idUsuSolicita === id_usuario ? 'Aguardando' : '+Amigo';
  }

  // Não renderiza nada se não deve mostrar o botão de amizade
  if (!shouldShowFriendButton) {
    return null;
  }

  return (
    <>
      {/* Botão Amigos para o próprio perfil do usuário */}
      {isOwnProfile && id_usuario ? (
        <button style={styles.button} onClick={handleAmigosClick}>
          Amigos
        </button>
      ) : (
        <button
          onClick={() => {
            if (statusAmizade === 'confirmado') {
              removerAmizade();
            } else if (statusAmizade === 'nao_amigos') {
              adicionarAmigo();
            } else if (statusAmizade === 'aguardando' && idUsuSolicita !== id_usuario) {
              aceitarAmizade();
            } else if (statusAmizade === 'aguardando' && idUsuSolicita === id_usuario) {
              cancelarSolicitacaoAmizade();
            }
          }}
          style={styles.actionButton}
        >
          {buttonText}
        </button>
      )}

      <CustomAlertModal
        isVisible={isConfirmModalVisible}
        onDismiss={() => setConfirmModalVisible(false)}
        onConfirm={confirmarRemocaoAmizade}
        title="Remover Amigo"
        message="Tem certeza que deseja remover este amigo?"
      />
    </>
  );
};

const styles = {
  button: {
    padding: '10px 15px',
    margin: '5px',
    backgroundColor: 'transparent', // Torna o fundo transparente
    color: '#FF6600', // Define a cor do texto
    border: 'none', // Remove a borda
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px', // Ajusta o tamanho da fonte, caso necessário
  } as React.CSSProperties,
  actionButton: {
    padding: '10px 15px',
    margin: '5px',
    backgroundColor: 'transparent', // Torna o fundo transparente
    color: '#FF6600', // Define a cor do texto
    border: 'none', // Remove a borda
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px', // Ajusta o tamanho da fonte, caso necessário
  } as React.CSSProperties,
};

export default AmigosUser;
