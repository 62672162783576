import React from 'react';

const PrivacidadeDocument = () => (
  <>
    <h2>Política de Privacidade - Tribhus</h2>
    <p>Última atualização: 15/09/2024</p>
    <p>
      A Tribhus valoriza a privacidade dos seus usuários e está comprometida em proteger os dados pessoais coletados em conformidade com a Lei Geral de Proteção de Dados (LGPD) e demais legislações aplicáveis. Esta Política de Privacidade descreve como coletamos, usamos, armazenamos, compartilhamos e protegemos as informações pessoais dos usuários da nossa plataforma de rede social e streaming dedicada ao rock, incluindo artistas, bandas e usuários em geral.
    </p>

    <h3>1. Introdução</h3>
    <p>
      Ao utilizar os serviços da Tribhus, você concorda com a coleta e uso de informações de acordo com esta Política de Privacidade. Se você não concorda com os termos aqui estabelecidos, por favor, não utilize nossos serviços.
    </p>

    <h3>2. Definições</h3>
    <ul>
      <li><strong>Usuário:</strong> Qualquer pessoa que utiliza a plataforma Tribhus, incluindo artistas, bandas e usuários em geral.</li>
      <li><strong>Dados Pessoais:</strong> Qualquer informação relacionada a uma pessoa física ou jurídica identificada ou identificável.</li>
      <li><strong>Tratamento de Dados:</strong> Qualquer operação realizada com os dados pessoais, como coleta, uso, armazenamento, compartilhamento, entre outros.</li>
    </ul>

    <h3>3. Dados Coletados</h3>
    <h4>3.1. Dados Fornecidos pelo Usuário</h4>
    <p>Ao se cadastrar e utilizar a Tribhus, podemos coletar os seguintes dados pessoais:</p>
    <ul>
      <li>Nome completo ou nome artístico.</li>
      <li>Endereço de e-mail.</li>
      <li>Telefone.</li>
      <li>Cidade.</li>
      <li>Estado.</li>
      <li>Data de nascimento.</li>
      <li>CNPJ (opcional para artistas).</li>
      <li>Gênero.</li>
      <li>Foto de perfil.</li>
      <li>Informações da banda ou projeto musical (para artistas).</li>
    </ul>

    <h4>3.2. Dados Coletados Automaticamente</h4>
    <p>Durante o uso da plataforma, podemos coletar automaticamente:</p>
    <ul>
      <li>Histórico de navegação e interações com o serviço.</li>
      <li>Preferências musicais e conteúdos acessados.</li>
      <li>Contagens de reproduções e engajamento com o conteúdo.</li>
      <li>Endereço IP.</li>
      <li>Tipo de dispositivo e navegador.</li>
      <li>Sistema operacional.</li>
      <li>Identificadores únicos do dispositivo.</li>
      <li>Cookies e tecnologias semelhantes.</li>
    </ul>

    <h3>4. Finalidade do Tratamento dos Dados</h3>
    <p>Utilizamos os dados pessoais para as seguintes finalidades:</p>
    <ul>
      <li>Permitir o acesso e uso da plataforma.</li>
      <li>Facilitar a interação entre usuários, artistas e bandas.</li>
      <li>Exibir conteúdo personalizado com base nas preferências do usuário.</li>
      <li>Analisar e compreender o comportamento dos usuários para aprimorar a plataforma.</li>
      <li>Enviar notificações, atualizações e comunicações relacionadas ao serviço.</li>
      <li>Promover conteúdo de artistas e bandas dentro da plataforma, mediante consentimento prévio.</li>
      <li>Cumprir obrigações legais e regulatórias.</li>
      <li>Prevenir fraudes, abusos e atos ilícitos.</li>
    </ul>

    <h3>5. Compartilhamento de Dados</h3>
    <p>A Tribhus poderá compartilhar seus dados pessoais nas seguintes situações:</p>
    <ul>
      <li>Com prestadores de serviços, como hospedagem e análise de dados, sujeitos a obrigações de confidencialidade.</li>
      <li>Com outros usuários, informações públicas do perfil serão visíveis na plataforma.</li>
      <li>Por motivos legais, quando exigido por lei ou para responder a autoridades governamentais.</li>
      <li>Em caso de transações comerciais, como fusões ou aquisições, desde que o destinatário respeite esta Política de Privacidade.</li>
    </ul>

    <h3>6. Segurança dos Dados</h3>
    <p>
      Implementamos medidas técnicas e organizacionais para proteger os dados pessoais, como criptografia, controle de acesso e sistemas de monitoramento. No entanto, nenhum método de transmissão ou armazenamento é totalmente seguro.
    </p>

    <h3>7. Retenção de Dados</h3>
    <p>
      Os dados pessoais serão retidos pelo tempo necessário para cumprir as finalidades descritas nesta Política, a menos que a lei exija um período de retenção mais longo.
    </p>

    <h3>8. Direitos dos Usuários</h3>
    <p>De acordo com a LGPD, você tem os seguintes direitos:</p>
    <ul>
      <li>Confirmação e acesso aos seus dados pessoais.</li>
      <li>Correção de dados incompletos, inexatos ou desatualizados.</li>
      <li>Anonimização, bloqueio ou eliminação de dados desnecessários ou em desconformidade com a lei.</li>
      <li>Portabilidade dos dados a outro fornecedor de serviço ou produto.</li>
      <li>Eliminação dos dados tratados com base no consentimento, exceto nos casos previstos em lei.</li>
      <li>Informação sobre compartilhamento de dados com entidades públicas e privadas.</li>
      <li>Revogação do consentimento a qualquer momento.</li>
    </ul>
    <p>Para exercer seus direitos, entre em contato conosco através do e-mail: <a href="mailto:admin@tribhus.com.br">admin@tribhus.com.br</a>.</p>

    <h3>9. Cookies e Tecnologias Semelhantes</h3>
    <p>
      Utilizamos cookies e tecnologias semelhantes para coletar dados sobre as atividades do usuário. Você pode gerenciar suas preferências de cookies nas configurações do navegador.
    </p>

    <h3>10. Transferência Internacional de Dados</h3>
    <p>
      Os dados pessoais podem ser transferidos para servidores fora do seu país. Tomaremos as medidas necessárias para garantir que as transferências sejam realizadas conforme as leis de proteção de dados.
    </p>

    <h3>11. Crianças e Adolescentes</h3>
    <p>
      A Tribhus não é direcionada a menores de 18 anos. Se coletarmos dados de menores sem consentimento, eliminaremos essas informações.
    </p>

    <h3>12. Alterações na Política de Privacidade</h3>
    <p>
      Podemos atualizar esta Política de Privacidade periodicamente. Notificaremos sobre alterações significativas. Recomendamos revisar esta política regularmente.
    </p>

    <h3>13. Contato</h3>
    <p>
      Se você tiver dúvidas ou solicitações relacionadas a esta Política de Privacidade, entre em contato conosco pelo e-mail: <a href="mailto:admin@tribhus.com.br">admin@tribhus.com.br</a>.
    </p>

    <h3>14. Lei Aplicável e Foro</h3>
    <p>
      Esta Política de Privacidade é regida pelas leis da República Federativa do Brasil, e qualquer disputa será submetida à Comarca de Limeira, Estado de São Paulo.
    </p>
  </>
);

export default PrivacidadeDocument;
