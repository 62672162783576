import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { SlugContext } from '../context/SlugContext';
import { AuthContext } from '../context/AuthContext';
import { RegisterContext } from '../context/RegisterContext';
import MusicContext from '../context/MusicContext';
import TabBar from '../Components/TabBar'; 
import BotoesSociais from '../Components/BotoesSociais'; 
import DescriUsuario from '../Components/DescriUsuario';
import AlbumEstante from '../Components/AlbumEstante';
import PlaylistUser from '../Components/PlaylistUser';
import EventosConfirmados from '../Components/EventosConfirmados';
import ModalMenuOpcoes from '../Components/ModalMenuOpcoes';
import MiniPlayer from '../Components/MiniPlayer';
import ProfileAlert from '../utils/ProfileAlert'; 

interface User {
  generos: string[];
  url_capa_usuario?: string;
  url_icone_usuario?: string;
  nome?: string;
  sobrenome?: string;
  descricao?: string;
  id_usuario?: number;
  cidade?: string;
  estado?: string;
  slug?: string;
}

const responsiveStyles = `
  @media (min-width: 1920px) {
    .mainContent {
      gap: 40px;
    }
    .feedColumn {
      max-width: 800px;
    }
  }

  @media (max-width: 1200px) {
    .mainContent {
      gap: 10px;
    }
    .feedColumn {
      max-width: 100%;
    }
    .tabBarColumn {
      display: none;
    }
  }
`;

const StyleSheet = () => (
  <style>
    {responsiveStyles}
  </style>
);

const TelaUsuarioPerfil: React.FC = () => {
  const [user, setUser] = useState<User>({generos: []});
  const { state: slugState } = useContext(SlugContext);
  const { dispatch } = useContext(RegisterContext); 
  const { state: authState } = useContext(AuthContext);
  const musicContext = useContext(MusicContext);
  const currentTrack = musicContext?.state.track;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [missingInfo, setMissingInfo] = useState<string[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [isOwner, setIsOwner] = useState(false); 

  useEffect(() => {
    // Atualiza o valor de isOwner sempre que authState ou band mudarem
    setIsOwner(authState.id_usuario === user.id_usuario);
  }, [authState.id_usuario, user.id_usuario]);

  useEffect(() => {
    const fetchData = async () => {
      let id_usuario = authState.id_usuario;

      if (slugState.slugPerfil) {
        try {
          const response = await axios.get(`https://tribhus.shop:5000/users/get-user-id/${slugState.slugPerfil}`, {
            headers: { Authorization: `Bearer ${authState.token}` },
          });
          id_usuario = response.data.id_usuario;
        } catch (error) {
          console.error('Erro ao buscar ID do usuário:', error);
          return;
        }
      }

      if (!id_usuario) {
        console.error("id_usuario é inválido:", id_usuario);
        return;
      }

      if (authState.token && id_usuario) {
        try {
          const response = await axios.get(`https://tribhus.shop:5000/users/${id_usuario}`, {
            headers: { Authorization: `Bearer ${authState.token}` },
          });
          console.log('Response data:', response.data);
          setUser(response.data);
          dispatch({
            type: 'SET_USER_PROFILE',
            id: response.data.id_usuario,
            nome: response.data.nome,
            sobrenome: response.data.sobrenome
          });
          if (response.data.url_capa_usuario) {
            dispatch({ type: 'SET_USER_COVER_URL', url: response.data.url_capa_usuario });
          }

          const missing: string[] = [];
          if (!response.data.url_icone_usuario) missing.push('photo');
          if (!response.data.descricao) missing.push('description');
          if (!response.data.generos || response.data.generos.length === 0) missing.push('genres');
          
          setMissingInfo(missing);
          setShowAlert(missing.length > 0);
        } catch (error) {
          console.error('Erro ao buscar detalhes do usuário:', error);
        }
      }
    };
  
    fetchData();
  }, [authState.id_usuario, authState.token, slugState.slugPerfil]);

  return (
    <div style={styles.container}>
      <StyleSheet />
      {showAlert && isOwner && (
        <ProfileAlert 
          missingInfo={missingInfo} 
          onClose={() => setShowAlert(false)} 
        />
      )}
      
{/* Cabeçalho com imagem de capa */}
<div style={styles.headerContainer}>
  <div
    style={{
      ...styles.coverImage,
      backgroundImage: `url(${user.url_capa_usuario})`,
    }}
  />
  
  {/* Contêiner para alinhar o ícone de perfil e o menu */}
  <div style={styles.profileContainer}>
    {/* Ícone de perfil posicionado à direita e abaixo da imagem de capa */}
    <div
      style={{
        ...styles.profileIcon,
        backgroundImage: `url(${user.url_icone_usuario})`,
      }}
    />
    {/* Botão de menu alinhado à direita do ícone de perfil */}
    <button style={styles.menuButton} onClick={() => setIsMenuOpen(true)}>
      ⋮
    </button>
  </div>
</div>


      {/* Botões sociais e o menu abaixo da capa, alinhados à esquerda */}
      <div style={styles.socialButtonsContainer}>
        {user.id_usuario && (
          <BotoesSociais
            idUsuarioRecebe={user.id_usuario}
            isOwnProfile={authState.id_usuario === user.id_usuario}
          />
        )}
        </div>

      <ModalMenuOpcoes
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        isOwner={isOwner}
        itemId={user.id_usuario ?? ''}
        tipoItem="usuario"
      />

      {/* Conteúdo principal com TabBar e Feed */}
      <div style={styles.mainContent}>
        <div style={styles.leftColumn}>
          <TabBar currentScreen="Home" currentSlug={user.slug}/>
          {currentTrack && (
            <div style={styles.miniPlayerContainer}>
              <MiniPlayer />
            </div>
          )}
        </div>

        <div style={styles.feedColumn}>
          <h1 style={styles.userName}>{user.nome} {user.sobrenome}</h1>
          <div style={styles.generosContainer}>
            {user.generos.map((genero, index) => (
              <span key={index} style={styles.genreTag}>{genero}</span>
            ))}
          </div>
          <DescriUsuario descricao={user.descricao || ''} />
          {user.id_usuario && (
            <AlbumEstante idUsuario={user.id_usuario} token={authState.token || ''} />
          )}
          {user.id_usuario && authState.token && (
            <PlaylistUser id_usuario={user.id_usuario} token={authState.token} />
          )}
          {user.id_usuario && authState.token && (
            <EventosConfirmados idUsuario={user.id_usuario} token={authState.token} />
          )}
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    backgroundColor: '#151922',
    minHeight: '100vh',
    color: 'lightgray',
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: '1200px',
    height: '220px', // Aumenta a altura para dar mais espaço para o ícone e botão
    marginBottom: '100px',
  },
  coverImage: {
    width: '100%',
    height: '300px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '8px',
  },
  profileIcon: {
    position: 'absolute',
    bottom: '-120px', // Posiciona o ícone mais abaixo da imagem de capa
    right: '20px', // Mantém o ícone mais à direita
    width: '180px',
    height: '180px',
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '4px solid #151922',
    boxShadow: '0 2px 4px rgba(255,255,255,0.2)',
  },
  socialButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    maxWidth: '1200px',
    width: '100%',
    paddingLeft: '20px',
    marginTop: '-30px',
    marginBottom: '30px',
  },
  menuButton: {
    position: 'absolute',
    bottom: '-75px', // Alinha o botão na parte inferior do ícone
    right: '-20px', // Alinha o botão ao final da tela, à direita do ícone
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#151922',
    color: 'lightgray',
    fontSize: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  },
  mainContent: {
    display: 'flex',
    padding: '0 20px',
    maxWidth: '1200px', // Ajuste para alinhar ao final da imagem de capa
    width: '100%',
    gap: '20px',
    alignItems: 'flex-start',
  },
  leftColumn: {
    width: '250px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  feedColumn: {
    flex: 1,
    maxWidth: '930px', // Ajuste a largura máxima para ocupar o restante da linha
    overflowY: 'auto',
  },
  userName: {
    marginTop: '0px',
    marginBottom: '10px',
    fontSize: '24px',
  },
  genreTag: {
    display: 'inline-block',
    backgroundColor: '#2a2e39',
    color: 'lightgray',
    padding: '5px 10px',
    borderRadius: '15px',
    margin: '3px',
    fontSize: '14px',
  },
  generosContainer: {
    marginBottom: '15px',
  },
  profileContainer: {
    position: 'absolute',
    bottom: '-50px',
    right: '20px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px', // espaço entre o ícone e o botão de menu
  },
};

export default TelaUsuarioPerfil;