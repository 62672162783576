import React, { useState, useContext, useEffect, CSSProperties } from 'react';
import Modal from 'react-modal';
import { BandaContext } from '../context/BandaContext';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

interface EditSecurityModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
  }  

  const EditSecurityModal: React.FC<EditSecurityModalProps> = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();
  const { state } = useContext(BandaContext);
  const { state: authState, dispatch } = useContext(AuthContext); 
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [slug, setSlug] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null); 
  const [currentPassword, setCurrentPassword] = useState('');
  const [countdown, setCountdown] = useState(180);
  const [timerActive, setTimerActive] = useState(false); 
  const [showCustomToast, setShowCustomToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
    // estados para o modal de confirmação de exclusão
    const [deleteConfirmModalIsOpen, setDeleteConfirmModalIsOpen] = useState(false);
    const [passwordForDeletion, setPasswordForDeletion] = useState('');

  // Função para abrir o modal de confirmação de exclusão
  const openDeleteConfirmModal = () => {
    setDeleteConfirmModalIsOpen(true);
  };

  // Função para fechar o modal de confirmação de exclusão
  const closeDeleteConfirmModal = () => {
    setDeleteConfirmModalIsOpen(false);
    setPasswordForDeletion('');
  };

  // Função modificada para verificar a senha antes de excluir a conta
  const deleteAccount = async () => {
    try {
      // Primeiro, verificar a senha
      const verifyResponse = await axios.post('https://tribhus.shop:5000/users/verify-password', {
        auth_id: authState.auth_id,
        password: passwordForDeletion
      }, {
        headers: { Authorization: `Bearer ${authState.token}` }
      });

      if (!verifyResponse.data.isValid) {
        toast.error('Senha incorreta. Por favor, tente novamente.');
        return;
      }

      // Se a senha estiver correta, prosseguir com a exclusão
      const url = `https://tribhus.shop:5000/delete/delete-account/banda/${authState.id_banda}`;

      const response = await axios.post(url, { auth_id: authState.auth_id }, {
        headers: { Authorization: `Bearer ${authState.token}` }
      });

      if (response.data.message === 'Conta excluída com sucesso.') {
        console.log("Conta excluída com sucesso");
        toast.success('Conta excluída com sucesso!');
        onRequestClose();

        // Limpar dados locais
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('sessionId');
        localStorage.removeItem('slug');
        localStorage.removeItem('auth_id');
        localStorage.removeItem('id_banda');

        dispatch({ type: 'RESET_AUTH' });
        navigate('/PrimeiraTela');
      } else {
        throw new Error(response.data.error || 'Falha ao excluir a conta.');
      }
    } catch (error) {
      console.error("Erro durante a exclusão da conta:", error);
      const message = extractErrorMessage(error);
      toast.error(`Erro ao excluir a conta: ${message}`);
    } finally {
      closeDeleteConfirmModal();
    }
  };
  
  const validatePassword = () => {
    if (password.length < 8) {
      toast.error('A senha deve ter no mínimo 8 caracteres.');
      return false;
    }
  
    if (password !== confirmPassword) {
      toast.error('As senhas não correspondem!');
      return false;
    }
  
    return true;
  };  

  const saveNewPassword = async () => {
    if (!validatePassword()) {
      return;
    }
  
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/change-password', {
        currentPassword: currentPassword,
        newPassword: password,
        auth_id: state.auth_id
      }, {
        headers: { Authorization: `Bearer ${authState.token}` }
      });
    
      if (response.data.message === 'Senha atualizada com sucesso.') {
        toast.success('Senha atualizada com sucesso!');
        // Limpar os estados
        setPassword('');
        setConfirmPassword('');
        setCurrentPassword('');
        onRequestClose();
      } else {
        throw new Error(response.data.error || 'Falha ao atualizar a senha.');
      }
    } catch (error) {
      const message = extractErrorMessage(error);
      toast.error(`Erro ao atualizar a senha: ${message}`);
    }
  };

  const checkSlugAvailability = async (): Promise<boolean> => {
    try {
      const response = await axios.post('https://tribhus.shop:5000/bands/check-slug', { slug });
      const responseData = response.data;
  
      if (responseData.message === "Nome de Usuário já está em uso, por favor escolha outro.") {
        setErrorMsg(responseData.message); // Usando setErrorMsg
        return false; // Slug indisponível
      } else {
        setErrorMsg(null); // Limpa a mensagem de erro
        return true; // Slug disponível
      }
    } catch (error) {
      console.error(error);
      setErrorMsg('Ocorreu um problema ao verificar a disponibilidade do slug.');
      return false; // Slug indisponível
    }
  };  

  function extractErrorMessage(error: unknown): string {
    if (typeof error === 'object' && error !== null) {
      const e = error as { response?: { data?: { error?: string } } };
      if (e.response && e.response.data && e.response.data.error) {
        return e.response.data.error;
      }
      if ('message' in e) {
        return (e as { message?: string }).message || 'Erro desconhecido';
      }
    }
    return 'Erro desconhecido';
  }  

  const saveNewSlug = async () => {
    if (!(await checkSlugAvailability())) {
      return;
    }
  
    try {
      const response = await axios.post('https://tribhus.shop:5000/bands/change-slug', {
        newSlug: slug,
        id_banda: state.id_banda
      }, {
        headers: { Authorization: `Bearer ${authState.token}` }
      });
  
      if (response.data.message === 'Slug atualizado com sucesso.') {
        toast.success('Slug atualizado com sucesso!');
        onRequestClose();
      } else {
        throw new Error(response.data.error || 'Falha ao atualizar o slug.');
      }
    } catch (error) {
      const message = extractErrorMessage(error);
      toast.error(`Erro ao atualizar o slug: ${message}`);
    }
  };  

  useEffect(() => {
    let timer: number | undefined; // Defina o tipo do timer como number
  
    if (timerActive && countdown > 0) {
      timer = window.setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setToastMessage('Envio indisponível ou número não encontrado. Tente novamente em alguns minutos.');
      setShowCustomToast(true);
      setTimerActive(false);
      setShowVerificationInput(false);
      setCellphone('');
      setCountdown(180);
    }
  
    return () => {
      if (timer !== undefined) {
        clearTimeout(timer);
      }
    };
  }, [countdown, timerActive]);  

  const verifySMSCode = async () => {
    if (!verificationCode) {
      toast.error('Por favor, insira o código de verificação.');
      return;
    }
  
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/verify-sms-update', {
        phoneNumber: cellphone,
        verificationCode,
        auth_id: state.auth_id
      });
  
      if (response.data.isVerified) {
        onRequestClose();
        toast.success('Número de celular verificado com sucesso!');
      } else {
        toast.error('Código de verificação inválido. Tente novamente.');
      }
    } catch (error: unknown) {
      const errorMessage = extractErrorMessage(error);
      toast.error(`Erro ao verificar o código: ${errorMessage}`);
    }
  };  

  const submitPhoneNumber = async () => {
    if (cellphone.length < 11) { 
      return;
    }
  
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/send-sms-update', {
        phoneNumber: cellphone,
        auth_id: state.auth_id
      });
  
      if (response.data.message === "SMS enviado com sucesso.") {
        setShowVerificationInput(true);
        setTimerActive(true); 
        setCountdown(180); 
      } else {
      }
    } catch (error) {
      console.log(error); 
    }
  };  

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formattedNumber = e.target.value.replace(/\D/g, ''); // Remove tudo que não é número
    if (formattedNumber.length > 2 && formattedNumber.length <= 6) {
      formattedNumber = `(${formattedNumber.slice(0, 2)}) ${formattedNumber.slice(2)}`;
    } else if (formattedNumber.length > 6 && formattedNumber.length <= 10) {
      formattedNumber = `(${formattedNumber.slice(0, 2)}) ${formattedNumber.slice(2, 6)}-${formattedNumber.slice(6)}`;
    } else if (formattedNumber.length > 10) {
      formattedNumber = `(${formattedNumber.slice(0, 2)}) ${formattedNumber.slice(2, 7)}-${formattedNumber.slice(7, 11)}`;
    }
    setCellphone(formattedNumber);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Editar Dados de Segurança"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '700px', // Largura máxima do modal
          maxWidth: '90%', // Garante que o modal seja responsivo em telas menores
          backgroundColor: '#1E1E1E',
          padding: '30px',
          borderRadius: '10px',
          boxSizing: 'border-box',
        },
      }}
    >
      <h2 style={{ color: '#FFF', marginBottom: '20px' }}>Editar Dados de Segurança</h2>
  
      <label style={{ color: '#FF6600' }}>Senha Atual</label>
      <input
        type="password"
        onChange={(e) => setCurrentPassword(e.target.value)}
        value={currentPassword}
        style={inputStyle}
      />
  
      <label style={{ color: '#FF6600' }}>Nova Senha</label>
      <input
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        style={inputStyle}
      />
  
      <label style={{ color: '#FF6600' }}>Confirmar Nova Senha</label>
      <input
        type="password"
        onChange={(e) => setConfirmPassword(e.target.value)}
        value={confirmPassword}
        style={inputStyle}
      />
  
      <button style={buttonStyle} onClick={saveNewPassword}>
        Salvar Nova Senha
      </button>
  
      <label style={{ color: '#FF6600' }}>Novo Usuário (Slug)</label>
      <input
        onChange={(e) => setSlug(e.target.value)}
        value={slug}
        style={inputStyle}
        autoCapitalize="none"
      />
  
      <button style={buttonStyle} onClick={saveNewSlug}>
        Salvar Novo Usuário
      </button>

      <button style={deleteButtonStyle} onClick={openDeleteConfirmModal}>
          Excluir Conta
        </button>

{/* Modal de confirmação de exclusão */}
<Modal
  isOpen={deleteConfirmModalIsOpen}
  onRequestClose={closeDeleteConfirmModal}
  contentLabel="Confirmar Exclusão de Conta"
  style={{
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px', // Um pouco menor que o modal principal
      maxWidth: '90%',
      backgroundColor: '#1E1E1E',
      padding: '30px',
      borderRadius: '10px',
      boxSizing: 'border-box',
    },
  }}
>
  <h2 style={{ color: '#FFF', marginBottom: '20px' }}>Confirmar Exclusão de Conta</h2>
  <p style={{ color: '#FFF', marginBottom: '15px' }}>Por favor, insira sua senha para confirmar a exclusão da conta:</p>
  <input
    type="password"
    value={passwordForDeletion}
    onChange={(e) => setPasswordForDeletion(e.target.value)}
    style={inputStyle}
    placeholder="Digite sua senha"
  />
  <button style={{...buttonStyle, backgroundColor: '#FF0000', marginTop: '20px'}} onClick={deleteAccount}>
    Confirmar Exclusão
  </button>
  <button style={{...buttonStyle, backgroundColor: '#333', marginTop: '10px'}} onClick={closeDeleteConfirmModal}>
    Cancelar
  </button>
</Modal>

      {errorMsg && <p style={{ color: '#FF6600' }}>{errorMsg}</p>}
  
      <label style={{ color: '#FF6600' }}>Novo Celular</label>
    <input
      onChange={handlePhoneChange}
      value={cellphone}
      style={inputStyle}
      placeholder="(xx) xxxx-xxxx"
    />
  
      {timerActive && (
        <p style={{ color: 'orange' }}>
          Enviando... por favor, aguarde {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')} minutos
        </p>
      )}
  
      {showVerificationInput ? (
        <>
          <input
            type="text"
            placeholder="Digite o Código de Verificação aqui"
            onChange={(e) => setVerificationCode(e.target.value)}
            value={verificationCode}
            maxLength={6}
            style={inputStyle}
          />
          <button style={buttonStyle} onClick={verifySMSCode}>
            Validar código e prosseguir
          </button>
        </>
      ) : (
        <button style={buttonStyle} onClick={submitPhoneNumber}>
          Enviar código SMS
        </button>
      )}
  
      {showCustomToast && <div style={{ color: 'orange' }}>{toastMessage}</div>}
    </Modal>
  
  );
};

const inputStyle: CSSProperties = {
  width: '100%',
  padding: '12px',
  marginBottom: '16px',
  borderRadius: '5px',
  border: '1px solid gray',
  backgroundColor: '#1E1E1E',
  color: '#FFF',
  boxSizing: 'border-box',
};
  
  const buttonStyle = {
    width: '100%',
    padding: '12px',
    backgroundColor: '#914100',
    border: 'none',
    borderRadius: '5px',
    color: '#FFF',
    cursor: 'pointer',
    marginBottom: '16px',
    fontWeight: 'bold',
  };

  const deleteButtonStyle: CSSProperties = {
    width: '100%',
    padding: '12px',
    backgroundColor: '#FF0000', // Cor vermelha para indicar exclusão
    border: 'none',
    borderRadius: '5px',
    color: '#FFF',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginBottom: '16px',
  };

  export default EditSecurityModal;