import React, { createContext, useContext, useState } from 'react';
import axios from '../Services/axiosInterceptor';

interface PasswordResetContextType {
  requestPasswordReset: (email: string) => Promise<boolean>;
  resetPassword: (token: string, newPassword: string) => Promise<boolean>;
  loading: boolean;
  error: string | null;
}

const PasswordResetContext = createContext<PasswordResetContextType | undefined>(undefined);

export const usePasswordReset = () => {
  const context = useContext(PasswordResetContext);
  if (!context) {
    throw new Error('usePasswordReset must be used within a PasswordResetProvider');
  }
  return context;
};

export const PasswordResetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const requestPasswordReset = async (email: string): Promise<boolean> => {
    setLoading(true);
    setError(null);
    try {
      await axios.post('/reset-pass/request-reset', { email });
      setLoading(false);
      return true;
    } catch (error) {
      console.error('Erro ao solicitar redefinição de senha:', error);
      setError('Erro ao solicitar redefinição de senha. Tente novamente.');
      setLoading(false);
      return false;
    }
  };

  const resetPassword = async (token: string, newPassword: string): Promise<boolean> => {
    setLoading(true);
    setError(null);
    try {
      await axios.post('/reset-pass/reset', { token, newPassword });
      setLoading(false);
      return true;
    } catch (error) {
      console.error('Erro ao redefinir senha:', error);
      setError('Erro ao redefinir senha. Tente novamente.');
      setLoading(false);
      return false;
    }
  };

  return (
    <PasswordResetContext.Provider value={{ requestPasswordReset, resetPassword, loading, error }}>
      {children}
    </PasswordResetContext.Provider>
  );
};