import React, { useState, useEffect } from 'react';

type DescriUsuarioProps = {
  descricao: string;
};

const DescriUsuario = ({ descricao }: DescriUsuarioProps) => {
  const [descricaoUsuario, setDescricaoUsuario] = useState(descricao || '');

  useEffect(() => {
    setDescricaoUsuario(descricao || '');
  }, [descricao]);


  return (
    <div style={styles.descriptionContainer}>
      <p style={styles.descriptionText}>
        {descricaoUsuario || 'Nenhuma descrição fornecida.'}
      </p>
    </div>
  );
};

const styles = {
  descriptionContainer: {
    backgroundColor: '#2D2D2D',
    padding: 10,
    paddingTop: 20,
    borderRadius: 10,
    marginTop: 30,
    marginBottom: 30,
    position: "relative" as "relative",
    maxWidth: '900px',
    margin: '0 auto',
  },
  descriptionText: {
    color: '#FFFFFF',
    marginTop: 5,
    textAlign: 'justify' as 'justify', // Justifica o texto
  },
};

export default DescriUsuario;