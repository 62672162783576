import React, { useEffect, useState, useRef } from 'react';
import axios from '../Services/axiosInterceptor'; 
import EventDetailsModal from './EventDetailsModal';

interface EventoConfirmadoProps {
  idUsuario: number;
  token: string;
}

interface Evento {
  id_evento: number;
  nome_evento: string;
  data_evento: string;
  local_evento: string;
  cidade: string;
  url_imagem_evento: string;
}

const EventosConfirmados: React.FC<EventoConfirmadoProps> = ({ idUsuario, token }) => {
  const [eventos, setEventos] = useState<Evento[]>([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const listRef = useRef<HTMLDivElement>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);

  useEffect(() => {
    console.log("ID do Usuário: ", idUsuario);
    console.log("Token: ", token);
  
    axios.get(`https://tribhus.shop:5000/homeuser/${idUsuario}/events`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => {
      console.log("Resposta da API:", response.data);
      setEventos(response.data);
    })
    .catch(error => {
      console.error('Erro ao buscar eventos:', error);
    });
  }, [idUsuario, token]);

  const handleEventClick = (evento: Evento) => {
    setSelectedEventId(evento.id_evento);
    setModalOpen(true);
  };

  const handleScroll = (direction: 'left' | 'right') => {
    if (listRef.current) {
      const scrollAmount = 200;
      if (direction === 'left') {
        listRef.current.scrollLeft -= scrollAmount;
      } else {
        listRef.current.scrollLeft += scrollAmount;
      }
    }
  };

  const checkScrollPosition = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const list = listRef.current;
    const handleScroll = () => checkScrollPosition();
    
    if (list) {
      list.addEventListener('scroll', handleScroll);
    }
    
    return () => {
      if (list) {
        list.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div>
      <h2 style={styles.sectionTitle}>Eventos Confirmados</h2>
      <div style={styles.container}>
        {showLeftArrow && (
          <div style={{ ...styles.arrow, ...styles.leftArrow }} onClick={() => handleScroll("left")}>
            &#9664;
          </div>
        )}
        <div style={styles.list} ref={listRef}>
          {eventos.map((evento) => (
            <div key={evento.id_evento} style={styles.card} onClick={() => handleEventClick(evento)}>
              <img src={evento.url_imagem_evento} alt={evento.nome_evento} style={styles.image} />
              <p style={styles.eventName}>{evento.nome_evento}</p>
              <p style={styles.eventLocation}>{evento.local_evento}</p>
              <p style={styles.eventDate}>{new Date(evento.data_evento).toLocaleDateString()}</p>
            </div>
          ))}
        </div>
        {showRightArrow && (
          <div style={{ ...styles.arrow, ...styles.rightArrow }} onClick={() => handleScroll("right")}>
            &#9654;
          </div>
        )}
      </div>
     <EventDetailsModal 
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        eventId={selectedEventId}
        token={token}
        idUsuario={idUsuario} 
        isOwner={false} 
      />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    position: 'relative',
    maxWidth: '100%',
    margin: '0 auto',
    alignItems: 'center',
  } as React.CSSProperties,
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FF6600',
    margin: 0,
    marginTop: '40px',
    padding: '10px',
  } as React.CSSProperties,
  list: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    padding: '0 20px',
  } as React.CSSProperties,
  card: {
    flex: '0 0 auto',
    scrollSnapAlign: 'start',
    margin: 5,
    padding: 10,
    borderRadius: 10,
    position: 'relative',
    width: '150px',
    backgroundColor: '#202020',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  } as React.CSSProperties,
  image: {
    width: '150px',
    height: '150px',
    borderRadius: '20%',
    objectFit: 'cover',
  } as React.CSSProperties,
  eventName: {
    color: 'lightgray',
    fontSize: 16,
    fontWeight: 'bold',
    margin: '10px 0',
    textAlign: 'center',
  } as React.CSSProperties,
  eventLocation: {
    color: 'lightgray',
    fontSize: 14,
    textAlign: 'center',
  } as React.CSSProperties,
  eventDate: {
    color: 'lightgray',
    fontSize: 12,
    textAlign: 'center',
  } as React.CSSProperties,
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    color: 'lightgray',
    cursor: 'pointer',
    zIndex: 10,
    transition: 'opacity 0.3s',
    opacity: 0.5,
  } as React.CSSProperties,
  leftArrow: {
    left: '10px',
  } as React.CSSProperties,
  rightArrow: {
    right: '10px',
  } as React.CSSProperties,
};

export default EventosConfirmados;