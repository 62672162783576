import React from 'react';

const GuiaAjudaDocument = () => (
    <div style={{
        backgroundColor: '#f9f9f9', // Fundo cinza claro
        color: '#151922', // Cor do texto
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '900px', // Aumenta o tamanho da tela
        margin: 'auto',
        lineHeight: '1.6',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
    }}>
        <h2 style={{ color: '#914100', marginBottom: '16px' }}>Guia de Ajuda do Tribhus</h2>
        <p>
            Bem-vindo ao Tribhus! Este guia foi criado para ajudá-lo a aproveitar ao máximo nossa plataforma de streaming e rede social focada em bandas de rock, especialmente as independentes e underground. Aqui você encontrará instruções detalhadas sobre como se cadastrar, configurar seu perfil e usar todas as funcionalidades do aplicativo.
        </p>

        <hr />

        <h3 style={{ color: '#914100', marginTop: '24px' }}>Sumário</h3>
        <ol style={{ marginLeft: '20px' }}>
            <li>
                <a href="#cadastro-e-login" style={{ textDecoration: 'none', color: '#914100' }}>Cadastro e Login</a>
                <ul>
                    <li>Como criar uma conta</li>
                    <li>Recuperação de senha</li>
                    <li>Não recebi o e-mail de ativação</li>
                </ul>
            </li>
            <li>
                <a href="#configurando-seu-perfil" style={{ textDecoration: 'none', color: '#914100' }}>Configurando seu Perfil</a>
                <ul>
                    <li>Editar perfil (usuários e bandas)</li>
                    <li>Adicionar foto de perfil</li>
                    <li>Configurações de privacidade</li>
                </ul>
            </li>
            <li>
                <a href="#usando-a-plataforma" style={{ textDecoration: 'none', color: '#914100' }}>Usando a Plataforma</a>
                <ul>
                    <li>Palco</li>
                    <li>Bandas</li>
                    <li>Perfil</li>
                    <li>Adicionar postagem</li>
                    <li>Playlist</li>
                    <li>Player</li>
                    <li>Compartilhamento</li>
                    <li>Notificações</li>
                </ul>
            </li>
            <li>
                <a href="#suporte-adicional" style={{ textDecoration: 'none', color: '#914100' }}>Suporte Adicional</a>
            </li>
        </ol>

        <hr />

        <h3 id="cadastro-e-login" style={{ color: '#914100', marginTop: '24px' }}>1. Cadastro e Login</h3>
        <h4>Como criar uma conta</h4>
        <ol style={{ marginLeft: '20px' }}>
            <li>
                <strong>Download do App:</strong> Baixe o aplicativo Tribhus na Google Play Store (Android) ou na App Store (iOS). Você também pode acessar a versão web pelo nosso site oficial.
            </li>
            <li>
                <strong>Iniciar Cadastro:</strong>
                <ul>
                    <li>Abra o aplicativo e toque em “Não tem login? Faça seu cadastro!”</li>
                    <li>Escolha o tipo de conta:
                        <ul>
                            <li><strong>Usuário:</strong> para usuários, fãs e ouvintes.</li>
                            <li><strong>Banda:</strong> para artistas que desejam criar seu perfil e exibir todo seu material, eventos, redes sociais, e se conectar aos fãs.</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Preencher Informações:</strong>
                <ul>
                    <li><strong>Dados Pessoais:</strong>
                        <ul>
                            <li>Nome completo ou nome da banda.</li>
                            <li>Endereço de e-mail válido.</li>
                            <li>Criação de uma senha segura.</li>
                        </ul>
                    </li>
                    <li><strong>Termos e Políticas:</strong>
                        <ul>
                            <li>Leia e aceite os Termos de Uso e a Política de Privacidade.</li>
                            <li>Marque a caixa de seleção para concordar.</li>
                            <li>Depois siga as etapas de cadastro preenchendo os dados necessários e escolhendo os gêneros do rock que você curte.</li>
                            <li>Você será solicitado a confirmar o email e o telefone, com código enviado. Por favor, faça a confirmação para ter mais segurança em sua conta.</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Concluir Cadastro:</strong>
                <ul>
                    <li>Toque em “Salvar”.</li>
                    <li>Você será redirecionado para sua página de perfil. Por favor, preencha para ficar completo.</li>
                </ul>
            </li>
        </ol>

        <h4>Recuperação de Senha</h4>
        <ul style={{ marginLeft: '20px' }}>
            <li>
                <strong>Esqueceu sua senha?</strong>
                <ul>
                    <li>Na tela de login, toque em “Esqueceu a senha?”.</li>
                    <li>Insira o e-mail associado à sua conta e toque em “Enviar”.</li>
                    <li>Verifique seu e-mail e siga as instruções para redefinir sua senha.</li>
                </ul>
            </li>
        </ul>

        <h4>Não Recebi o E-mail de Ativação</h4>
        <ul style={{ marginLeft: '20px' }}>
            <li><strong>Verifique as Pastas de Spam/Lixo Eletrônico:</strong> O e-mail pode ter sido filtrado.</li>
            <li><strong>Reenviar E-mail de Ativação:</strong>
                <ul>
                    <li>Na tela de login, toque em “Reenviar e-mail de ativação”.</li>
                </ul>
            </li>
            <li><strong>Ainda com Problemas?</strong> Entre em contato com nosso suporte pelo WhatsApp <a href="tel:+5519996647030" style={{ color: '#914100' }}>+55 19 99664-7030</a></li>
        </ul>

        <hr />

        <h3 id="configurando-seu-perfil" style={{ color: '#914100', marginTop: '24px' }}>2. Configurando seu Perfil</h3>
        <h4>Editar Perfil (Usuários e Bandas)</h4>
        <ol style={{ marginLeft: '20px' }}>
            <li>
                <strong>Acessar Perfil:</strong>
                <ul>
                    <li>Toque no ícone de três pontinhos no canto superior direito e depois clique em “Editar Perfil”.</li>
                </ul>
            </li>
            <li>
                <strong>Editar Informações:</strong>
                <ul>
                    <li>Toque em “Editar Perfil”.</li>
                    <li><strong>Para Usuários:</strong>
                        <ul>
                            <li>Atualize seu nome, nome de usuário e descrição.</li>
                        </ul>
                    </li>
                    <li><strong>Para Bandas:</strong>
                        <ul>
                            <li>Atualize o nome da banda, descrição e gênero musical.</li>
                            <li>Inclua links para redes sociais e site oficial.</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>Salvar Alterações:</strong>
                <ul>
                    <li>Após editar, toque em “Salvar” para atualizar seu perfil.</li>
                </ul>
            </li>
        </ol>

        <h4>Adicionar Foto de Perfil</h4>
        <ul style={{ marginLeft: '20px' }}>
            <li>
                <strong>Passo a Passo:</strong>
                <ul>
                    <li>No modo de edição do perfil, toque na área da foto.</li>
                    <li>Escolha entre “Tirar Foto” ou “Escolher da Galeria”.</li>
                    <li>Selecione ou capture a imagem desejada.</li>
                    <li>Ajuste o enquadramento e confirme.</li>
                </ul>
            </li>
        </ul>

        <hr />

        <h3 id="usando-a-plataforma" style={{ color: '#914100', marginTop: '24px' }}>3. Usando a Plataforma</h3>
        <h4>Palco</h4>
        <ul style={{ marginLeft: '20px' }}>
            <li>
                <strong>O que é o Palco?</strong>
                <ul>
                    <li>É a tela inicial do aplicativo, onde você encontra postagens da comunidade, como últimas novidades, eventos, músicas, vídeos, etc.</li>
                </ul>
            </li>
            <li>
                <strong>Navegando no Palco:</strong>
                <ul>
                    <li>Role para ver postagens, novas músicas, eventos e atualizações.</li>
                    <li>Interaja com conteúdo curtindo, comentando ou compartilhando.</li>
                </ul>
            </li>
        </ul>

        <h4>Bandas</h4>
        <ul style={{ marginLeft: '20px' }}>
            <li>
                <strong>Descobrindo Bandas:</strong>
                <ul>
                    <li>Acesse a aba “Bandas” no menu inferior.</li>
                    <li>Explore bandas por gênero, popularidade ou novas adições.</li>
                </ul>
            </li>
            <li>
                <strong>Relacionamentos:</strong>
                <ul>
                    <li>Usuários podem entrar para a família de uma banda, quando se sentem fãs e querem fazer parte da família.</li>
                    <li>Usuários ou bandas podem se inscrever em uma banda para ficar por dentro das novidades.</li>
                    <li>Bandas podem convidar usuários a fazer parte de sua família.</li>
                </ul>
            </li>
            <li>
                <strong>Para Bandas:</strong>
                <ul>
                    <li>Gerencie seu perfil, adicione músicas, álbuns e eventos.</li>
                    <li>Interaja com seus fãs respondendo comentários e mensagens ou até mesmo conversando por chat.</li>
                </ul>
            </li>
        </ul>

        <h4>Perfil</h4>
        <ul style={{ marginLeft: '20px' }}>
            <li>
                <strong>Seu Espaço Pessoal:</strong>
                <ul>
                    <li>Acesse seu perfil para ver seus álbuns salvos, eventos, estantes de álbuns, playlists, notificações, mensagens, etc.</li>
                </ul>
            </li>
        </ul>

        <h4>Adicionar Postagem</h4>
        <ul style={{ marginLeft: '20px' }}>
            <li>
                <strong>Criando uma Nova Postagem:</strong>
                <ul>
                    <li>No Palco, toque em “+” ou “Adicionar Postagem”.</li>
                    <li>Escolha o tipo de conteúdo:
                        <ul>
                            <li>Texto simples.</li>
                            <li>Imagem (faça upload ou tire uma foto).</li>
                            <li>Vídeo (insira um link do YouTube).</li>
                        </ul>
                    </li>
                    <li>Escreva uma legenda ou comentário.</li>
                </ul>
            </li>
            <li>
                <strong>Publicar:</strong>
                <ul>
                    <li>Revise seu conteúdo e toque em “Publicar”.</li>
                    <li>Sua postagem aparecerá no seu perfil e no Palco de seus seguidores.</li>
                </ul>
            </li>
        </ul>

        <h4>Playlist</h4>
        <ul style={{ marginLeft: '20px' }}>
            <li>
                <strong>Criando uma Playlist:</strong>
                <ul>
                    <li>Vá até a seção “Playlists” no seu perfil.</li>
                    <li>Toque em “Criar Nova Playlist”.</li>
                    <li>Dê um nome à sua playlist e, opcionalmente, uma descrição.</li>
                </ul>
            </li>
            <li>
                <strong>Adicionando Músicas:</strong>
                <ul>
                    <li>Enquanto escuta uma música, toque nos três pontos “...” e selecione “Adicionar à Playlist”.</li>
                    <li>Escolha a playlist desejada ou crie uma nova.</li>
                </ul>
            </li>
            <li>
                <strong>Gerenciando Playlists:</strong>
                <ul>
                    <li>Edite o nome, descrição ou ordem das músicas.</li>
                    <li>Compartilhe suas playlists com amigos ou torne-as públicas para que outros usuários possam segui-las.</li>
                </ul>
            </li>
        </ul>

        <h4>Player</h4>
        <ul style={{ marginLeft: '20px' }}>
            <li>
                <strong>Usando o Player de Música:</strong>
                <ul>
                    <li><strong>Miniplayer:</strong>
                        <ul>
                            <li>Aparece no rodapé ao reproduzir uma música.</li>
                            <li>Toque no miniplayer para expandir para o player completo.</li>
                        </ul>
                    </li>
                    <li><strong>Player Completo:</strong>
                        <ul>
                            <li>Controles de reprodução: play/pausa, próxima, anterior.</li>
                            <li>Barra de progresso para avançar ou retroceder na faixa.</li>
                            <li>Opções para repetir, embaralhar e adicionar à playlist.</li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>

        <h4>Compartilhamento</h4>
        <ul style={{ marginLeft: '20px' }}>
            <li>
                <strong>Compartilhar Conteúdo:</strong>
                <ul>
                    <li>Toque nos três pontos “...” ao lado de músicas, álbuns, postagens ou perfis.</li>
                    <li>Selecione “Compartilhar” e escolha a plataforma (WhatsApp, Facebook, Instagram, etc.).</li>
                </ul>
            </li>
            <li>
                <strong>Convidar Amigos:</strong>
                <ul>
                    <li>Nas configurações, toque em “Convidar Amigos”.</li>
                    <li>Envie um convite para seus contatos e ajude a comunidade Tribhus a crescer.</li>
                </ul>
            </li>
        </ul>

        <h4>Notificações</h4>
        <ul style={{ marginLeft: '20px' }}>
            <li>
                <strong>Permitir Notificações:</strong>
                <ul>
                    <li>Para ficar por dentro de todas as novidades, permita que o Tribhus envie notificações.</li>
                    <li><strong>Como Ativar:</strong>
                        <ul>
                            <li>No seu celular, vá em Configurações &gt; Notificações.</li>
                            <li>Encontre o Tribhus e ative as notificações.</li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>

        <hr />

        <h3 id="suporte-adicional" style={{ color: '#914100', marginTop: '24px' }}>4. Suporte Adicional</h3>
        <p>
            Se você tiver dúvidas ou encontrar problemas não abordados neste guia, nossa equipe de suporte está pronta para ajudar.
        </p>
        <ul style={{ marginLeft: '20px' }}>
            <li>
                <strong>Contato pelo WhatsApp:</strong>
                <ul>
                    <li>Chame em nosso WhatsApp: <a href="tel:+5519996647030" style={{ color: '#914100' }}>+55 19 99664-7030</a> para dúvidas, sugestões ou críticas.</li>
                    <li>Horário de funcionamento: de segunda a sexta, das 8h às 21h.</li>
                </ul>
            </li>
            <li>
                <strong>Feedback e Sugestões:</strong>
                <ul>
                    <li>Sua opinião é importante para nós!</li>
                </ul>
            </li>
        </ul>

        <hr />

        <p>
            Agradecemos por fazer parte da comunidade Tribhus. Nossa missão é conectar bandas e fãs, promovendo a cena do rock independente. Aproveite ao máximo todas as funcionalidades e, qualquer coisa, estamos à disposição para ajudar. Rock on! 🎸
        </p>
    </div>
);

export default GuiaAjudaDocument;
