import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MusicContext from '../context/MusicContext';
import { Volume2, VolumeX } from 'lucide-react';

const MiniPlayer: React.FC = () => {
    const musicContext = useContext(MusicContext);
    const navigate = useNavigate();
 //   const [showVolumeSlider, setShowVolumeSlider] = useState(false);

    if (!musicContext) return null;

    const { state, togglePlay, nextTrack, prevTrack, seekTo, setVolume, toggleMute } = musicContext;
    const { track, isPlaying, isMiniPlayerVisible, currentTime, duration, volume, isMuted } = state;

    if (!track || !isMiniPlayerVisible) return null;

    const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVolume = parseFloat(e.target.value);
        setVolume(newVolume);
    };

    const handleToggleMute = (e: React.MouseEvent) => {
        e.stopPropagation();
        toggleMute();
    };

    const formatTime = (timeInSeconds: number): string => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handlePlayPause = (e: React.MouseEvent) => {
        e.stopPropagation();
        togglePlay();
    };

    const handleNextTrack = (e: React.MouseEvent) => {
        e.stopPropagation();
        nextTrack();
    };

    const handlePrevTrack = (e: React.MouseEvent) => {
        e.stopPropagation();
        prevTrack();
    };

    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        musicContext.dispatch({ type: 'CLOSE_MINIPLAYER' });
    };

    const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const progressBar = e.currentTarget;
        const clickPosition = e.clientX - progressBar.getBoundingClientRect().left;
        const newPercentage = clickPosition / progressBar.offsetWidth;
        const newTime = newPercentage * duration;
        seekTo(newTime);
    };

    const navigateToPlayer = () => {
        navigate('/AudioPlayer');
    };

    const progressPercentage = (currentTime / duration) * 100;

    return (
        <div style={styles.container} onClick={navigateToPlayer}>
            <button onClick={handleClose} style={styles.closeButton}>✕</button>
            <img src={track.url_imagem} alt="Album Cover" style={styles.image} />
            <div style={styles.info}>
                <p style={styles.trackName}>{track.nome_musica}</p>
                <p style={styles.artistName}>{track.nome_banda}</p>
            </div>
            <div style={styles.progressBarContainer} onClick={handleProgressBarClick}>
                <div style={{...styles.progressBar, width: `${progressPercentage}%`}} />
            </div>
            <div style={styles.timeContainer}>
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
            </div>
            <div style={styles.controlsContainer}>
                <div style={styles.mainControls}>
                    <button onClick={handlePrevTrack} style={styles.button}>◀◀</button>
                    <button onClick={handlePlayPause} style={styles.playButton}>
                        {isPlaying ? '❚❚' : '▶'}
                    </button>
                    <button onClick={handleNextTrack} style={styles.button}>▶▶</button>
                </div>
                <div style={styles.volumeControl}>
                    <button onClick={handleToggleMute} style={styles.volumeButton}>
                        {isMuted ? <VolumeX size={16} /> : <Volume2 size={16} />}
                    </button>
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={isMuted ? 0 : volume}
                        onChange={handleVolumeChange}
                        style={styles.volumeSlider}
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>
            </div>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#1a1a1a',
        padding: '20px',
        borderRadius: '15px',
        boxShadow: '0 0 20px rgba(145, 65, 0, 0.3)',
        border: '2px solid #914100',
        maxWidth: '300px',
        margin: '0 auto',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#914100',
        fontSize: '16px',
        cursor: 'pointer',
        padding: '5px',
        zIndex: 10,
    },
    image: {
        width: '200px',
        height: '200px',
        borderRadius: '10px',
        marginBottom: '15px',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    },
    info: {
        width: '100%',
        textAlign: 'center',
        marginBottom: '15px',
    },
    trackName: {
        color: '#fff',
        fontSize: '18px',
        fontWeight: 'bold',
        margin: '0 0 5px 0',
    },
    artistName: {
        color: '#aaa',
        fontSize: '14px',
        margin: 0,
    },
    progressBarContainer: {
        width: '100%',
        height: '6px',
        backgroundColor: '#333',
        borderRadius: '3px',
        marginBottom: '5px',
        cursor: 'pointer',
    },
    progressBar: {
        height: '100%',
        backgroundColor: '#914100',
        borderRadius: '3px',
    },
    timeContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        color: '#aaa',
        fontSize: '12px',
        marginBottom: '15px',
    },
    controls: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    controlsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: '10px',
    },
    mainControls: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
    },
    button: {
        backgroundColor: '#2a2a2a',
        border: '1px solid #914100',
        color: '#914100',
        fontSize: '16px',
        cursor: 'pointer',
        padding: '10px 15px',
        margin: '0 5px',
        borderRadius: '5px',
        transition: 'all 0.3s ease',
    },
    playButton: {
        backgroundColor: '#914100',
        border: 'none',
        color: '#fff',
        fontSize: '24px',
        cursor: 'pointer',
        padding: '15px 20px',
        margin: '0 10px',
        borderRadius: '50%',
        transition: 'all 0.3s ease',
    },
    volumeControl: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginTop: '10px',
    },
    volumeButton: {
        backgroundColor: 'transparent',
        border: 'none',
        color: '#914100',
        cursor: 'pointer',
        padding: '5px',
        marginRight: '10px',
    },
    volumeSlider: {
        flex: 1,
        height: '5px',
        backgroundColor: '#2a2a2a',
        appearance: 'none',
        outline: 'none',
        opacity: 0.7,
        transition: 'opacity .2s',
    },
};

export default MiniPlayer;