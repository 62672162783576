import React, { useState, useContext, useRef, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import logoHorizontal from '../assets/logo_horizontal.png';
import AudioConverter, { AudioConverterRef } from '../Services/AudioConverter'; 
import EditarAlbuns from '../Components/EditarAlbuns';
import FotosAdicionar from '../Components/FotosAdicionar';
import VideosAdicionar from '../Components/VideosAdicionar';
import { useNavigate } from 'react-router-dom';
import axios from '../Services/axiosInterceptor'; 
import ContratoUpload from '../Components/ContratoUpload'; 

type CampoMusica = {
    musicId?: number; 
    nome: string;
    compositor: string;
    ano: string;
    arquivo: File | null;
    letra: string; 
};

const DashBoardBand: React.FC = () => {
    const { state: authState } = useContext(AuthContext);
    const navigate = useNavigate();
    const onClose = () => navigate(-1);
    const token = authState.token;
    const idBanda = authState.id_banda;
    const [nomeAlbum, setNomeAlbum] = useState('');
    const [anoLancamento, setAnoLancamento] = useState('');
    const [imagemAlbum, setImagemAlbum] = useState<File | null>(null);
    const [camposMusica, setCamposMusica] = useState<CampoMusica[]>([{ nome: '', compositor: '', ano: '', arquivo: null, letra: '' }]);
    const [idAlbum, setIdAlbum] = useState<number | null>(null);
    const [albumSalvo, setAlbumSalvo] = useState(false);
    const [urlCapaAlbum, setUrlCapaAlbum] = useState<string | null>(null);
    const [modoEdicao, setModoEdicao] = useState(false);
    const [dadosOriginaisAlbum, setDadosOriginaisAlbum] = useState({ nome: '', ano: '' });
    const [musicasSalvas, setMusicasSalvas] = useState<CampoMusica[]>([]);
    const [conversionProgress, setConversionProgress] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const audioConverterRef = useRef<AudioConverterRef>(null);
    const [albumUpdated, setAlbumUpdated] = useState(false);
    const [abaAtivaFotos, setAbaAtivaFotos] = useState(false);
    const [abaAtivaVideos, setAbaAtivaVideos] = useState(false);
    const [abaAtiva, setAbaAtiva] = useState('albuns');
    const [contratoAceito, setContratoAceito] = useState(false);
    const [mostrarContrato, setMostrarContrato] = useState(false);

const handleAbaChange = (aba: 'albuns' | 'fotos' | 'videos') => {
    setAbaAtiva(aba);
    setAbaAtivaFotos(aba === 'fotos');
    setAbaAtivaVideos(aba === 'videos');
};

    useEffect(() => {
        if (albumUpdated) {
            // Realizar ações necessárias quando um álbum for atualizado
            // Por exemplo, recarregar a lista de álbuns
            console.log('Álbum atualizado, recarregando dados...');
            
            // Resetar o estado após lidar com a atualização
            setAlbumUpdated(false);
        }
    }, [albumUpdated]);

    useEffect(() => {
        // Verificar se o contrato já foi aceito
        const verificarContrato = async () => {
            try {
                const response = await axios.get(`https://tribhus.shop:5000/bands/check-contract/${idBanda}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setContratoAceito(response.data.aceito);
            } catch (error) {
                console.error("Erro ao verificar contrato:", error);
            }
        };

        if (idBanda) {
            verificarContrato();
        }
    }, [idBanda, token]);

    // Função para converter imagens
    const resizeImage = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const MAX_WIDTH = 800;
                    const MAX_HEIGHT = 800;
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    ctx?.drawImage(img, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        if (blob) resolve(blob);
                    }, 'image/jpeg', 0.8);
                };
                img.src = e.target?.result as string;
            };
            reader.readAsDataURL(file);
        });
    };

    const salvarAlbum = async () => {
        console.log("Iniciando função salvarAlbum");
        const formData = new FormData();
    
        try {
            if (imagemAlbum) {
                setIsProcessing(true);
                console.log("Redimensionando imagem do álbum");
                const resizedImage = await resizeImage(imagemAlbum);
    
                const fileName = imagemAlbum.name.replace(/\.[^/.]+$/, "");
                const jpegFileName = `${fileName}.jpeg`;
    
                formData.append('image-album', resizedImage, jpegFileName);
                console.log("Imagem do álbum adicionada ao FormData");
            }
            
            formData.append('nome', nomeAlbum);
            formData.append('ano', anoLancamento);
            console.log("Dados do álbum adicionados ao FormData:", { nome: nomeAlbum, ano: anoLancamento });
    
            const url = modoEdicao 
                ? `https://tribhus.shop:5000/bands/edit-album/${idAlbum}/${idBanda}` 
                : `https://tribhus.shop:5000/bands/create-album/${idBanda}`;
    
            console.log(`Enviando requisição para: ${url}`);
    
            const resposta = await axios({
                method: modoEdicao ? 'put' : 'post',
                url: url,
                data: formData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent: any) => {
                    if (progressEvent.total) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                        console.log(`Upload progress: ${percentCompleted}%`);
                    } else {
                        console.log(`Uploaded ${progressEvent.loaded} bytes`);
                        setUploadProgress(-1);
                    }
                }
            });
        
            console.log("Resposta do servidor:", resposta.data);
            if (resposta.data.id_album) {
                setIdAlbum(resposta.data.id_album);
                setUrlCapaAlbum(resposta.data.albumCoverUrl);
                setAlbumSalvo(true);
                setModoEdicao(false);
                setDadosOriginaisAlbum({ nome: nomeAlbum, ano: anoLancamento });
                alert(modoEdicao ? 'Álbum editado com sucesso!' : 'Álbum criado com sucesso!');
            } else {
                console.error('id_album não retornado na resposta');
                alert('Erro: ID do álbum não retornado pelo servidor');
            }
        } catch (erro: any) {
            console.error("Erro detalhado:", erro.response || erro);
            alert(`Erro ao ${modoEdicao ? 'editar' : 'salvar'} álbum: ${erro.response?.data?.message || erro.message}`);
        } finally {
            setIsProcessing(false);
            setUploadProgress(0);
        }
    };

    const excluirAlbum = () => {
        if (window.confirm("Tem certeza que deseja excluir este álbum?")) {
            axios.delete(`https://tribhus.shop:5000/bands/delete-album/${idAlbum}/${idBanda}`, {
                headers: { 
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(() => {
                alert("Álbum excluído com sucesso!");
                setAlbumSalvo(false);
                setNomeAlbum('');
                setAnoLancamento('');
                setImagemAlbum(null);
                setUrlCapaAlbum(null);
                setIdAlbum(null);
                setDadosOriginaisAlbum({ nome: '', ano: '' });
            })
            .catch(erro => {
                console.error("Erro ao excluir álbum:", erro);
                alert(`Erro ao excluir álbum: ${erro.response?.data?.message || erro.message}`);
            });
        }
    };

    const editarAlbum = () => {
        setModoEdicao(true);
        setDadosOriginaisAlbum({ nome: nomeAlbum, ano: anoLancamento });
    };

    const cancelarEdicao = () => {
        setModoEdicao(false);
        setNomeAlbum(dadosOriginaisAlbum.nome);
        setAnoLancamento(dadosOriginaisAlbum.ano);
    };
    
    const alterarCampoMusica = (index: number, campo: keyof CampoMusica, valor: string | File | null) => {
        const novosCamposMusica = [...camposMusica];
    
        if (campo === 'nome' || campo === 'compositor' || campo === 'ano' || campo === 'letra') {
            novosCamposMusica[index][campo] = valor as string;
        } else if (campo === 'arquivo') {
            novosCamposMusica[index][campo] = valor as File | null;
        }
    
        setCamposMusica(novosCamposMusica);
    };

    const salvarMusica = async (index: number) => {
        if (!contratoAceito) {
            setMostrarContrato(true);
            return;
        }
        if (!idAlbum || idAlbum <= 0) {
            alert('Por favor, salve o álbum antes de adicionar músicas.');
            return;
        }
        if (!idBanda) {
            alert('Erro: ID da banda não disponível. Por favor, faça login novamente.');
            return;
        }
    
        const arquivo = camposMusica[index].arquivo;
        if (!arquivo) {
            alert('Por favor, selecione um arquivo de música antes de salvar.');
            return;
        }
    
        setIsProcessing(true);
        setConversionProgress(0);
        setUploadProgress(0);
    
        try {
            // Iniciar a conversão
            if (audioConverterRef.current) {
                await audioConverterRef.current.convertToAAC();
            }
    
            const formData = new FormData();
            formData.append('id_banda', idBanda.toString());
            formData.append('id_album', idAlbum.toString());
            formData.append('nome_musica', camposMusica[index].nome);
            formData.append('compositor', camposMusica[index].compositor);
            formData.append('ano_lancamento', camposMusica[index].ano);
            formData.append('url_imagem', urlCapaAlbum || '');
            formData.append('letra', camposMusica[index].letra); 
    
            // Adicionar o arquivo de música
            formData.append('music', arquivo);
    
            console.log('Arquivo de música:', arquivo);
            console.log('FormData:', formData);
    
            const resposta = await axios.post(
                `https://tribhus.shop:5000/bands/upload-music/${idBanda}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent: any) => {
                        if (progressEvent.total) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentCompleted);
                        } else {
                            console.log(`Uploaded ${progressEvent.loaded} bytes`);
                            setUploadProgress(-1);
                        }
                    }
                }
            );
    
            console.log('Resposta do servidor:', resposta.data);
            const novaMusica = {
              ...camposMusica[index],
              musicId: resposta.data.musicId
            };
            setMusicasSalvas([...musicasSalvas, novaMusica]);
            setCamposMusica([{ nome: '', compositor: '', ano: '', arquivo: null, letra: '' }]);
            
            // Reset the AudioConverter
            if (audioConverterRef.current) {
              audioConverterRef.current.reset();
            }
    
            alert('Música salva com sucesso!');
        } catch (erro: any) {
            console.error('Erro ao salvar música:', erro);
            alert(`Erro ao salvar música: ${erro.response?.data?.message || erro.message}`);
        } finally {
            setIsProcessing(false);
            setConversionProgress(0);
            setUploadProgress(0);
        }
    };

    const excluirMusica = (musicId: number) => {
        if (window.confirm("Tem certeza que deseja excluir esta música?")) {
            axios.delete(`https://tribhus.shop:5000/bands/delete-music/${musicId}/${idBanda}`, {
                headers: { 
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(() => {
                alert("Música excluída com sucesso!");
                setMusicasSalvas(musicasSalvas.filter(m => m.musicId !== musicId));
            })
            .catch(erro => {
                console.error("Erro ao excluir música:", erro);
                alert(`Erro ao excluir música: ${erro.response?.data?.message || erro.message}`);
            });
        }
    };

    const aceitarContrato = async () => {
        try {
            await axios.post(`https://tribhus.shop:5000/bands/accept-contract/${idBanda}`, {
                tipo_contrato: 'upload_musica'
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setContratoAceito(true);
            setMostrarContrato(false);
        } catch (error) {
            console.error("Erro ao aceitar contrato:", error);
            alert("Erro ao aceitar o contrato. Por favor, tente novamente.");
        }
    };
    
    return (
        <div style={estilos.container}>
            <div style={estilos.centeredContent}>
                <div style={estilos.header}>
                    <button onClick={onClose} style={estilos.backButton}>
                        <img
                            src={require('../assets/voltar.png')}
                            alt="Voltar"
                            style={estilos.backIcon}
                        />
                    </button>
                    <img src={logoHorizontal} alt="Logo da Empresa" style={estilos.logo} />
                    <h1 style={estilos.titulo}>Dashboard da Banda</h1>
                </div>
                <div style={estilos.abasContainer}>
                    <button
                        onClick={() => handleAbaChange('albuns')}
                        style={{
                            ...estilos.botaoAba,
                            ...(abaAtiva === 'albuns' ? estilos.activeTab : estilos.inactiveTab)
                        }}
                    >
                        Álbuns e Músicas
                    </button>
                    <button
                        onClick={() => handleAbaChange('fotos')}
                        style={{
                            ...estilos.botaoAba,
                            ...(abaAtiva === 'fotos' ? estilos.activeTab : estilos.inactiveTab)
                        }}
                    >
                        Fotos
                    </button>
                    <button
                        onClick={() => handleAbaChange('videos')}
                        style={{
                            ...estilos.botaoAba,
                            ...(abaAtiva === 'videos' ? estilos.activeTab : estilos.inactiveTab)
                        }}
                    >
                        Vídeos
                    </button>
                </div>
                <div style={estilos.content}>
                    {!abaAtivaFotos && !abaAtivaVideos && (
                        <>
                          <div style={estilos.leftColumn}>
    <h2 style={estilos.subtitulo}>Cadastre seus álbuns e faça upload das suas músicas</h2>
    {!albumSalvo || modoEdicao ? (
        <div style={estilos.formContainer}>
            <input
                type="text"
                value={nomeAlbum}
                onChange={(e) => setNomeAlbum(e.target.value)}
                placeholder="Nome do Álbum"
                style={estilos.input}
            />
            <input
                type="text"
                value={anoLancamento}
                onChange={(e) => setAnoLancamento(e.target.value)}
                placeholder="Ano de Lançamento"
                style={estilos.input}
            />
            <button
                onClick={() => document.getElementById('fileInput')?.click()}
                style={estilos.botaoArquivo}
            >
                Selecionar Capa do Álbum
            </button>
            <input
                id="fileInput"
                type="file"
                onChange={(e) => {
                    const file = e.target.files ? e.target.files[0] : null;
                    setImagemAlbum(file);
                    console.log("Imagem selecionada:", file?.name);
                }}
                style={estilos.hiddenInput}
            />
            <button 
                onClick={() => {
                    console.log("Botão Salvar/Atualizar Álbum clicado");
                    salvarAlbum();
                }} 
                style={estilos.botao}
                disabled={isProcessing}
            >
                {isProcessing ? 'Processando...' : (modoEdicao ? 'Atualizar Álbum' : 'Salvar Álbum')}
            </button>
            {modoEdicao && (
                <button onClick={cancelarEdicao} style={estilos.botaoCancelar}>
                    Cancelar Edição
                </button>
            )}
            {isProcessing && (
                <p>Progresso do upload: {uploadProgress >= 0 ? `${uploadProgress}%` : 'Processando...'}</p>
            )}
        </div>
    ) : (
        <div style={estilos.formContainer}>
            <button onClick={editarAlbum} style={estilos.botao}>Editar Álbum</button>
            <button onClick={excluirAlbum} style={estilos.botaoExcluir}>Excluir Álbum</button>
        </div>
    )}
    {albumSalvo && (
        <div style={estilos.albumInfo}>
            <h3 style={estilos.albumTitle}>Álbum Cadastrado</h3>
            <p style={estilos.albumDetails}>Nome: {nomeAlbum}</p>
            <p style={estilos.albumDetails}>Ano: {anoLancamento}</p>
            {urlCapaAlbum && <img src={urlCapaAlbum} alt="Capa do Álbum" style={estilos.albumCover} />}
        </div>
    )}
    <div>
    </div>
</div>
                            
{albumSalvo && (
    <div style={estilos.rightColumn}>
        <div style={estilos.musicUploadContainer}>
            <p style={estilos.successMessage}>
                Pronto, seu álbum foi criado, agora faça o upload das músicas desse álbum por favor!
            </p>
            <h2 style={estilos.subtitulo}>
                {musicasSalvas.length > 0 ? 'Músicas adicionadas' : 'Adicionar Músicas'}
            </h2>

            {musicasSalvas.map((musica, index) => (
    <div key={musica.musicId}>
        <div style={estilos.campoMusica}>
            <p style={estilos.albumDetails}>Nome: {musica.nome}</p>
            <p style={estilos.albumDetails}>Compositor: {musica.compositor}</p>
            <p style={estilos.albumDetails}>Ano: {musica.ano}</p>
            <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between',
                marginTop: '8px' 
            }}>
                <div style={{
                    color: '#FF0000',
                    fontSize: '12px',
                    fontWeight: 'bold'
                }}>
                    PENDENTE
                </div>
                <button 
                    onClick={() => {
                        if (musica.musicId !== undefined) {
                            excluirMusica(musica.musicId);
                        }
                    }} 
                    style={estilos.botaoExcluirMusica}
                >
                    Excluir música
                </button>
            </div>
        </div>
        {index < musicasSalvas.length - 1 && <hr style={estilos.linhaDelimitadora} />}
    </div>
))}

{camposMusica.map((campo, index) => (
    <div key={index} style={estilos.campoMusica}>
        <input
            type="text"
            value={campo.nome}
            onChange={(e) => alterarCampoMusica(index, 'nome', e.target.value)}
            placeholder="Nome da Música"
            style={estilos.input}
        />
        <input
            type="text"
            value={campo.compositor}
            onChange={(e) => alterarCampoMusica(index, 'compositor', e.target.value)}
            placeholder="Compositor"
            style={estilos.input}
        />
        <input
            type="text"
            value={campo.ano}
            onChange={(e) => alterarCampoMusica(index, 'ano', e.target.value)}
            placeholder="Ano"
            style={estilos.input}
        />
        <textarea
            value={campo.letra}
            onChange={(e) => alterarCampoMusica(index, 'letra', e.target.value)}
            placeholder="Letra da Música"
            style={{...estilos.input, height: '100px'}}
        />
<input
  id={`fileInput-${index}`}
  type="file"
  accept="audio/*"
  onChange={(e) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      alterarCampoMusica(index, 'arquivo', file);
    }
  }}
  style={{ display: 'none' }}
/>
<button
  onClick={() => document.getElementById(`fileInput-${index}`)?.click()}
  style={estilos.botaoArquivo}
>
  Escolher Música
</button>
<AudioConverter 
  ref={audioConverterRef}
  onConversionComplete={(convertedFile) => {
    const novosCamposMusica = [...camposMusica];
    novosCamposMusica[index].arquivo = convertedFile;
    setCamposMusica(novosCamposMusica);
  }}
  songName={campo.nome}
  onProgress={setConversionProgress}
  file={campo.arquivo}
/>
        
        {/* Botão Salvar Música - movido para fora da condicional */}
        <button 
            onClick={() => {
                if (!contratoAceito) {
                    alert('Por favor, aceite o Contrato de Upload e Licenciamento de Conteúdo Musical antes de salvar a música.');
                    setMostrarContrato(true);
                } else {
                    salvarMusica(index);
                }
            }} 
            style={estilos.botao}
            disabled={isProcessing || !campo.arquivo}
        >
            {isProcessing ? 'Processando...' : 'Salvar Música'}
        </button>

        {isProcessing && (
            <div>
                <p style={estilos.progressText}>Conversão: {conversionProgress.toFixed(2)}%</p>
                <p style={estilos.progressText}>
                    Upload: {uploadProgress >= 0 ? `${uploadProgress.toFixed(2)}%` : 'Em progresso...'}
                </p>
            </div>
        )}

    {/* Verificação de contrato aceito */}
    {!contratoAceito ? (
            <div style={estilos.contratoAviso}>
                <p style={estilos.contratoText}>
                    Para fazer upload de músicas, você precisa aceitar nosso Contrato de Upload e Licenciamento de Conteúdo Musical.
                </p>
                <button 
                    onClick={() => setMostrarContrato(true)} 
                    style={estilos.botaoVerContrato}
                >
                    Ver Contrato
                </button>
            </div>
        ) : (
            <div style={estilos.contratoAviso2}>
                <p style={estilos.contratoText}>
                    Você já aceitou o Contrato de Upload. Se quiser revisá-lo, clique abaixo.
                </p>
                <button 
                    onClick={() => setMostrarContrato(true)} 
                    style={estilos.botaoVerContrato}
                >
                    Ver Contrato
                </button>
            </div>
        )}
    </div>
))}
        </div>
    </div>
)}
</>
)}

{/* Exibir o contrato quando solicitado */}
{mostrarContrato && (
    <ContratoUpload
        onAccept={aceitarContrato}
        onClose={() => setMostrarContrato(false)}
    />
)}


{abaAtivaFotos && (
    <div style={estilos.fotosAdicionarColumn}>
        {idBanda !== null && token !== null && (
            <FotosAdicionar 
                idBanda={idBanda}
                token={token}
            />
        )}
    </div>
)}

{abaAtivaVideos && (
    <div style={estilos.videosAdicionarColumn}>
        {token !== null && (
        <VideosAdicionar 
        />
    )}
</div>
)}

{!abaAtivaFotos && !abaAtivaVideos && (
    <div style={estilos.editarAlbunsColumn}>
        {idBanda !== null && token !== null && (
            <EditarAlbuns 
                id_banda={idBanda}
                token={token}
                onAlbumUpdated={() => setAlbumUpdated(true)}
            />
        )}
    </div>
)}
                </div>
            </div>
        </div>
    );
};

const estilos: { [key: string]: React.CSSProperties } = {
    container: {
        flex: 1,
        backgroundColor: '#151922',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
    },
    centeredContent: {
        width: '100%',
        maxWidth: '1200px',
        padding: '20px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',  // Reduz o espaço inferior do cabeçalho
        padding: '10px 20px',  // Reduz o padding superior e inferior
    },
    backButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        padding: '0',
        marginRight: '60px',  // Espaço entre o botão voltar e a logo ajustado
    },
    backIcon: {
        width: '24px',
        height: '24px',
    },
    logo: {
        maxWidth: '150px',  // Ajusta o tamanho da logo se necessário
        marginBottom: '0',  // Remove o margin inferior
    },
    titulo: {
        fontSize: '24px',  // Ajusta o tamanho da fonte para economizar espaço
        color: 'lightgray',
        fontWeight: 'bold',
        textAlign: 'right',
        flex: 1,
        margin: '0',  // Remove margin do título
    },
    botaoTitulo: {
        backgroundColor: 'transparent',
        color: '#FF6600',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '20px',
        fontWeight: 'bold',
        transition: 'color 0.3s',
    },
    titulo2: {
        fontSize: '20px',
        color: 'lightgray',
        fontWeight: 'bold',
        textAlign: 'left',
    },
    tituloContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    leftColumn: {
        width: '100%',
        maxWidth: '260px',
        marginRight: '20px',
    },
    fotosAdicionarColumn: {
        flex: 1,
        backgroundColor: '#151922',
        padding: '20px',
        borderRadius: '10px',
        marginLeft: '20px',
    },
    videosAdicionarColumn: {
        flex: 1,
        backgroundColor: '#151922',
        padding: '20px',
        borderRadius: '10px',
        marginLeft: '20px',
    },
    rightColumn: {
        flex: 1,
        backgroundColor: '#1E2736',
        padding: '20px',
        borderRadius: '10px',
        minWidth: '300px', // Aumente conforme necessário
        maxWidth: '600px', // Defina um limite máximo para controlar o layout
        marginLeft: '20px', // Adicione uma margem, se necessário
        marginTop: '20px',
    },
    editarAlbunsColumn: {
        width: '100%',
        marginTop: '20px',
    },
    subtitulo: {
        fontSize: '16px',
        color: '#FFF',
        marginBottom: '15px',
    },
    formContainer: {
        backgroundColor: '#1E2736',
        padding: '20px',
        borderRadius: '10px',
        marginBottom: '20px',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: '#2C3E50',
        border: 'none',
        borderRadius: '5px',
        color: 'lightgray',
    },
    botao: {
        backgroundColor: '#FF6600',
        color: 'lightgray',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '10px',
        transition: 'background-color 0.3s',
    },
    botaoArquivo: {
        backgroundColor: '#34495E',
        color: 'lightgray',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '10px',
        marginBottom: '10px',
        transition: 'background-color 0.3s',
    },
    hiddenInput: {
        display: 'none',
    },
    albumInfo: {
        backgroundColor: '#1E2736',
        padding: '20px',
        borderRadius: '10px',
        marginBottom: '20px',
    },
    albumTitle: {
        fontSize: '20px',
        color: 'lightgray',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    albumDetails: {
        fontSize: '16px',
        color: 'lightgray',
        marginBottom: '5px',
    },
    albumCover: {
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '5px',
        marginTop: '10px',
    },
    successMessage: {
        fontSize: '16px',
        color: '#fff',
        fontWeight: 'bold',
        marginTop: '15px',
        textAlign: 'center',
    },
    musicUploadContainer: {
        backgroundColor: '#1E2736',
        padding: '20px',
        borderRadius: '10px',
    },
    campoMusica: {
        marginBottom: '20px',
    },
    botaoExcluir: {
        backgroundColor: '#e74c3c',
        color: 'lightgray',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '10px',
        marginLeft: '10px',
        transition: 'background-color 0.3s',
    },
    botaoCancelar: {
        backgroundColor: '#95a5a6',
        color: 'lightgray',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '10px',
        marginLeft: '10px',
        transition: 'background-color 0.3s',
    },
    audioConvertidoText: {
        color: 'lightgray',
        marginTop: '5px',
        fontSize: '14px',
    },
    linhaDelimitadora: {
        border: '1px solid #2C3E50',
        margin: '10px 0',
    },
    progressText: {
        color: 'lightgray',
        fontSize: '14px',
        marginTop: '5px',
        fontWeight: 'bold',
    },
         abasContainer: {
            display: 'flex',
            justifyContent: 'space-around',  // Ajusta o espaçamento entre os botões
            width: '100%',
            marginBottom: '10px',
        },
        botaoAba: {
            flex: 1,
            padding: '10px',
            background: 'none',
            border: 'none',
            color: 'lightgray',
            fontSize: '16px',
            cursor: 'pointer',
        },
        activeTab: {
            borderBottom: '2px solid #FF6600',
        },
        inactiveTab: {
            borderBottom: 'none',  // Sem sublinhado para abas inativas
        },    
        contratoAviso: {
            marginBottom: '10px',
            padding: '10px',
            backgroundColor: '#fff',
            borderRadius: '4px',
            marginTop: '20px',
           },
        contratoText: {
            color: '#000',
            marginBottom: '20px',
        },
        botaoVerContrato: {
            backgroundColor: '#000',
            color: 'lightgray',
            border: 'none',
            padding: '5px 10px',
            borderRadius: '4px',
            cursor: 'pointer',
        },
        contratoAviso2: {
            padding: '10px',
            backgroundColor: '#fff',
            borderRadius: '4px',
            marginTop: '60px',
           },
};

export default DashBoardBand;
