import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SlugContext } from '../context/SlugContext';
import noImage from '../assets/no_image.png'; // Importa a imagem padrão

type LikesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  likesList: Array<{
    nome: string,
    slug: string,
    tipo: 'usuario' | 'banda',
    id: number,
    imagem?: string; // Adicionamos a imagem aqui
  }>;
};

const LikesModal: React.FC<LikesModalProps> = ({ isOpen, onClose, likesList }) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(SlugContext);

  const handleClick = useCallback((slug: string, tipo: string) => {
    console.log('handleClick slug:', slug);
    console.log('handleClick tipo:', tipo);
    dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
    
    if (tipo === 'usuario') {
      navigate('/TelaUsuarioPerfil');
    } else {
      navigate('/TelaBandaPerfil');
    }
  }, [dispatch, navigate]);

  if (!isOpen) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button onClick={onClose} style={styles.closeButton}>Fechar</button>
        <h2 style={styles.title}>Curtidas</h2>
        <ul style={styles.likesList}>
          {likesList.map((like, index) => (
            <li key={index} style={styles.likeItem}>
              <img
                src={like.imagem && like.imagem.trim() !== "" ? like.imagem : noImage} // Verifica se há imagem, caso contrário, usa a imagem padrão
                alt={like.nome}
                style={styles.likeImage}
              />
              <button onClick={() => handleClick(like.slug, like.tipo)} style={styles.likeButton}>
                {like.nome}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999, // Z-index mais baixo para overlay
  } as React.CSSProperties,
  modal: {
    backgroundColor: '#202020',
    padding: '20px',
    width: '60%',
    maxHeight: '80%',
    overflowY: 'auto',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    position: 'relative',
    zIndex: 1000, // Z-index aumentado para o modal
  } as React.CSSProperties,
  closeButton: {
    alignSelf: 'flex-end',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'lightgray',
    fontSize: '16px',
    cursor: 'pointer',
  } as React.CSSProperties,
  title: {
    color: '#FF6600',
    fontSize: '24px',
    margin: '0 0 20px 0',
  } as React.CSSProperties,
  likesList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  } as React.CSSProperties,
  likeItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  } as React.CSSProperties,
  likeImage: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px',
  } as React.CSSProperties,
  likeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'lightgray',
    padding: 0,
    //textDecoration: 'underline',
    cursor: 'pointer',
  } as React.CSSProperties,
};

export default LikesModal;
