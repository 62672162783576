import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../Services/axiosInterceptor';

const CodEmail: React.FC = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Permite apenas dígitos
    if (/^\d*$/.test(value)) {
      setCode(value);
    }
  };

  const verifyCode = async () => {
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/verify-code', { code });
      if (response.data.isVerified) {
        navigate('/CodSMS');
      } else {
        setError('Código de verificação inválido. Tente novamente.');
      }
    } catch (error) {
      setError('Erro ao verificar o código. Tente novamente.');
    }
  };

  const checkLater = async () => {
    navigate('/CodSMS');
  };

  return (
    <div style={styles.container}>
      <img src={require('../assets/logotipo.png')} alt="Logo" style={styles.logo} />
      <p style={styles.instruction}>Por favor, insira o código de verificação enviado para o seu email.</p>
      <input
        style={styles.input}
        onChange={handleCodeChange}
        value={code}
        type="text"
        inputMode="numeric"
        pattern="\d*"
        autoFocus
      />
      {error ? <p style={styles.error}>{error}</p> : null}
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={verifyCode}>Verificar</button>
      </div>
      <div style={styles.buttonContainer}>
        <button style={styles.buttonSecondary} onClick={checkLater}>Verificar depois</button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    padding: '20px',
    backgroundColor: '#1E1E1E',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  instruction: {
    fontSize: '16px',
    color: '#FFF',
    marginBottom: '10px',
    textAlign: 'center' as const,
  },
  logo: {
    width: '280px',
    height: '360px',
    objectFit: 'contain' as const,
    marginTop: '-50vh',
  },
  input: {
    height: '40px',
    borderColor: 'gray',
    borderWidth: '1px',
    borderStyle: 'solid',
    marginBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: '#000',
    width: '80%',
    maxWidth: '300px',
    borderRadius: '20px',
    color: '#fff',
    appearance: 'textfield' as const,
    MozAppearance: 'textfield' as const,
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  buttonContainer: {
    width: '80%',
    maxWidth: '300px',
    borderRadius: '20px',
    overflow: 'hidden',
    marginTop: '10px',
  },
  button: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#914100',
    color: '#fff',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
  },
  buttonSecondary: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#000',
    color: '#fff',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    fontSize: '14px',
  },
};

export default CodEmail;