import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { AuthContext } from '../context/AuthContext';

// Interface para as props
interface AddToPlaylistModalProps {
  currentTrackId: number;
  currentTrackName: string;
  closeModal: () => void;
}

interface Track {
  id_musica: number;
  // Adicione outras propriedades necessárias aqui, se houver
}

interface Playlist {
  id_playlist: number;
  nome: string;
  // Inclua outras propriedades conforme necessário
}

const AddToPlaylistModal: React.FC<AddToPlaylistModalProps> = ({ currentTrackId, currentTrackName, closeModal }) => {
  const [playlistTracks, setPlaylistTracks] = useState<Track[]>([]);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [newPlaylistName, setNewPlaylistName] = useState('');
  const { state: authState } = useContext(AuthContext);
  const [playlistDescription, setPlaylistDescription] = useState('');
  const [isPlaylistPublic, setIsPlaylistPublic] = useState(true);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [selectedPlaylist, setSelectedPlaylist] = useState<number | null>(null);

  const fetchPlaylistTracks = (playlistId: number) => {
    axios.get(`https://tribhus.shop:5000/playlists/${playlistId}/musicas`, {
      headers: {
        Authorization: `Bearer ${authState.token}`,
      },
    })
    .then(response => {
      setPlaylistTracks(response.data);
    })
    .catch(error => {
      console.error("Erro ao buscar músicas da playlist:", error);
    });
  };

  useEffect(() => {
    if (selectedPlaylist) {
      fetchPlaylistTracks(selectedPlaylist);
    }
  }, [selectedPlaylist]);

  const addMusicToSelectedPlaylist = () => {
    if (playlistTracks.some(track => track.id_musica === currentTrackId)) {
      showToast("Esta música já está na playlist selecionada.");
      return;
    }

    axios.post(`https://tribhus.shop:5000/playlists/${selectedPlaylist}/musica`, {
      id_musica: currentTrackId,
    }, {
      headers: {
        Authorization: `Bearer ${authState.token}`,
      },
    })
    .then(() => {
      showToast("Música adicionada com sucesso!");
      closeModal();
    })
    .catch(error => {
      showToast("Erro ao adicionar música à playlist.");
      console.error("Erro ao adicionar música à playlist:", error);
    });
  };

  const showToast = (message: string) => {
    setToastMessage(message);
    setTimeout(() => {
      setToastMessage(null);
    }, 3000);
  };

  useEffect(() => {
    axios.get('https://tribhus.shop:5000/playlists', {
      headers: {
        Authorization: `Bearer ${authState.token}`,
      },
    })
    .then(response => {
      setPlaylists(response.data);
    })
    .catch(error => {
      console.error("Erro ao buscar playlists:", error);
    });
  }, []);

  const createNewPlaylist = () => {
    axios.post('https://tribhus.shop:5000/playlists/', {
      nome: newPlaylistName,
      descricao: playlistDescription,
      publica: isPlaylistPublic,
    }, {
      headers: {
        Authorization: `Bearer ${authState.token}`,
      },
    })
    .then(response => {
      showToast("Playlist criada com sucesso!");
      const newPlaylistId = response.data.id_playlist;
      setPlaylists(prevPlaylists => [...prevPlaylists, response.data as Playlist]);
      setNewPlaylistName('');
      addMusicToPlaylist(newPlaylistId);
    })
    .catch(error => {
      showToast("Erro ao criar nova playlist.");
      console.error("Erro ao criar nova playlist:", error);
    });
  };

  const addMusicToPlaylist = (playlistId: number) => {
    axios.post(`https://tribhus.shop:5000/playlists/${playlistId}/musica`, {
      id_musica: currentTrackId,
    }, {
      headers: {
        Authorization: `Bearer ${authState.token}`,
      },
    })
    .then(() => {
      showToast("Música adicionada com sucesso à nova playlist!");
      closeModal();
    })
    .catch(error => {
      showToast("Erro ao adicionar música à playlist.");
      console.error("Erro ao adicionar música à playlist:", error);
    });
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <div style={styles.modalOverlay} onClick={handleOutsideClick}>
      <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <h2 style={styles.trackName}>{currentTrackName}</h2>
        <div style={styles.lineSeparator}></div>
        <p style={styles.instructionText}>
          Escolha a Playlist abaixo para salvar a música
        </p>
        <select
          value={selectedPlaylist || ''}
          onChange={(e) => setSelectedPlaylist(Number(e.target.value))}
          style={styles.picker}
        >
          <option value="">Clique e selecione a Playlist</option>
          {playlists.map((playlist) => (
            <option key={playlist.id_playlist} value={playlist.id_playlist}>
              {playlist.nome}
            </option>
          ))}
        </select>
        <button style={styles.button} onClick={addMusicToSelectedPlaylist}>
          Adicionar música à playlist selecionada
        </button>

        <p style={styles.instructionText}>
          Ou se preferir, crie uma nova Playlist
        </p>
        <input
          style={styles.nomeArea}
          value={newPlaylistName}
          onChange={(e) => setNewPlaylistName(e.target.value)}
          placeholder="Insira o nome da nova playlist"
        />
        <textarea
          style={styles.descriArea}
          value={playlistDescription}
          onChange={(e) => setPlaylistDescription(e.target.value)}
          placeholder="Insira a descrição da nova playlist"
        />
        
        <div style={styles.checkboxContainer}>
  <input
    type="checkbox"
    checked={isPlaylistPublic}
    onChange={() => setIsPlaylistPublic(!isPlaylistPublic)}
    style={styles.checkbox}
  />
  <label style={styles.label}>Playlist Pública</label>
</div>
        
        <button style={styles.button} onClick={createNewPlaylist}>
          Criar nova playlist
        </button>
        
        <button style={styles.button} onClick={closeModal}>
          Fechar
        </button>

        {toastMessage && (
          <div style={styles.toast}>
            <p>{toastMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, 
  },
  modalContent: {
    backgroundColor: '#151922',
    borderRadius: '10px',
    padding: '20px',
    width: '90%',
    maxWidth: '500px',
    maxHeight: '90%',
    overflow: 'auto',
    position: 'relative' as const,
  },
  trackName: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center' as const,
    marginTop: '10px',
    marginBottom: '10px',
    color: '#D3D3D3',
  },
  lineSeparator: {
    height: '2px',
    backgroundColor: "#D3D3D3",
    width: '100%',
    marginBottom: '10px',
  },
  picker: {
    width: '100%',
    marginBottom: '20px',
    color: '#D3D3D3',
    backgroundColor: '#000',
    padding: '10px',
    borderRadius: '5px',
  },
  button: {
    backgroundColor: '#914100',
    color: '#D3D3D3',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    marginBottom: '10px',
  },
  nomeArea: {
    height: '40px',
    width: '100%',
    marginBottom: '10px',
    padding: '5px',
    color: '#FFFFFF',
    backgroundColor: '#000000',
    borderRadius: '10px',
    border: '1px solid #D3D3D3',
  },
  descriArea: {
    height: '100px',
    width: '100%',
    marginBottom: '10px',
    padding: '5px',
    color: '#FFFFFF',
    backgroundColor: '#000000',
    borderRadius: '12px',
    border: '1px solid #D3D3D3',
    resize: 'vertical' as const,
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  checkbox: {
    marginRight: '8px',
  },
  label: {
    color: 'lightgray', // Isso garante que o label fique branco, mesmo se o container não tiver a cor definida
  },
  toast: {
    position: 'fixed' as const,
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#333',
    color: 'lightgray',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center' as const,
  },
  instructionText: {
    fontSize: '16px',
    marginBottom: '10px',
    marginTop: '30px',
    textAlign: 'center' as const,
    color: '#FF6600',
  },
};

export default AddToPlaylistModal;