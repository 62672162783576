import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor'; 

interface AddEventoModalProps {
    isOpen: boolean;
    onClose: () => void;
    onEventAdded: () => void;
    id_banda: number;
    token: string;
  }

const AddEventoModal: React.FC<AddEventoModalProps> = ({ isOpen, onClose, onEventAdded }) => {
  const { state } = useContext(AuthContext);
  const [nomeEvento, setNomeEvento] = useState('');
  const [date, setDate] = useState<string>('');
  const [localEvento, setLocalEvento] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [descricao, setDescricao] = useState('');
  const [imagemEvento, setImagemEvento] = useState<File | null>(null);

  const capitalizeWords = (text: string) => {
    return text.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!date || !nomeEvento || !localEvento || !cidade || !estado || !descricao) {
      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    const formData = new FormData();
    formData.append('id_banda', String(state.id_banda));
    formData.append('nome_evento', nomeEvento);
    formData.append('data_evento', date);
    formData.append('local_evento', localEvento);
    formData.append('cidade', cidade);
    formData.append('estado', estado);
    formData.append('descricao', descricao);
    if (imagemEvento) {
      formData.append('eventImage', imagemEvento);
    }

    try {
      const response = await axios.post('https://tribhus.shop:5000/eventos/add-event', formData, {
        headers: {
          'Authorization': `Bearer ${state.token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 201) {
        alert('Evento adicionado com sucesso!');
        onEventAdded();
        onClose();
      }
    } catch (error) {
      console.error('Erro ao adicionar evento:', error);
      alert('Erro ao adicionar evento. Por favor, tente novamente.');
    }
  };

  if (!isOpen) return null;
 
  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>Fechar</button>
        <h1 style={styles.title}>Cadastre Seu Evento</h1>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputGroup}>
            <label htmlFor="nomeEvento" style={styles.label}>Nome do Evento</label>
            <input
              type="text"
              id="nomeEvento"
              value={nomeEvento}
              onChange={(e) => setNomeEvento(e.target.value)}
              onBlur={(e) => setNomeEvento(capitalizeWords(e.target.value))}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="dataEvento" style={styles.label}>Data do Evento</label>
            <input
              type="date"
              id="dataEvento"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="localEvento" style={styles.label}>Local do Evento</label>
            <input
              type="text"
              id="localEvento"
              value={localEvento}
              onChange={(e) => setLocalEvento(e.target.value)}
              onBlur={(e) => setLocalEvento(capitalizeWords(e.target.value))}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="cidade" style={styles.label}>Cidade</label>
            <input
              type="text"
              id="cidade"
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
              onBlur={(e) => setCidade(capitalizeWords(e.target.value))}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="estado" style={styles.label}>Estado</label>
            <input
              type="text"
              id="estado"
              value={estado}
              onChange={(e) => setEstado(e.target.value)}
              onBlur={(e) => setEstado(capitalizeWords(e.target.value))}
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="descricao" style={styles.label}>Descrição</label>
            <textarea
              id="descricao"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              rows={4}
              style={styles.textarea}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="imagemEvento" style={styles.label}>Imagem do Evento</label>
            <input
              type="file"
              id="imagemEvento"
              onChange={(e) => setImagemEvento(e.target.files ? e.target.files[0] : null)}
              accept="image/*"
              style={styles.fileInput}
            />
          </div>
          <button type="submit" style={styles.submitButton}>Adicionar Evento</button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  } as React.CSSProperties,
  modal: {
    backgroundColor: '#202020',
    padding: '20px',
    width: '60%',
    maxHeight: '80%',
    overflowY: 'auto',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  } as React.CSSProperties,
  closeButton: {
    alignSelf: 'flex-end',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'lightgray',
    fontSize: '16px',
    cursor: 'pointer',
  } as React.CSSProperties,
  title: {
    color: 'lightgray',
    fontSize: '24px',
    margin: 0,
  } as React.CSSProperties,
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  } as React.CSSProperties,
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  } as React.CSSProperties,
  label: {
    color: 'lightgray',
    fontSize: '16px',
  } as React.CSSProperties,
  input: {
    padding: '10px',
    backgroundColor: '#333',
    color: 'lightgray',
    border: 'none',
    borderRadius: '5px',
    fontSize: '14px',
  } as React.CSSProperties,
  textarea: {
    padding: '10px',
    backgroundColor: '#333',
    color: 'lightgray',
    border: 'none',
    borderRadius: '5px',
    fontSize: '14px',
    resize: 'vertical',
  } as React.CSSProperties,
  fileInput: {
    color: 'lightgray',
  } as React.CSSProperties,
  submitButton: {
    padding: '10px',
    backgroundColor: '#914100',
    color: 'lightgray',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    marginTop: '10px',
  } as React.CSSProperties,
};

export default AddEventoModal;