import React, { useState, useContext, useEffect, CSSProperties } from 'react';
import axios from '../Services/axiosInterceptor'; 
import LikesModal from './LikesModal'; 
import { AuthContext } from '../context/AuthContext';
import noImage from '../assets/no_image.png'; 

type LikeButtonProps = {
  postId: number;
  initialLikes: number;
  user_or_banda_liked_id: number | null; 
  contentType: 'musica' | 'album' | 'postagem'; 
  liked: boolean;
  slug: string; 
};

const LikeButton: React.FC<LikeButtonProps> = ({ postId, initialLikes, user_or_banda_liked_id = null, contentType, slug }) => {
  const [liked, setLiked] = useState(Boolean(user_or_banda_liked_id));
  const [likesCount, setLikesCount] = useState(Number(initialLikes));
  const [modalOpen, setModalOpen] = useState(false);
  //const { state } = useContext(AuthContext);
  const { state: authState } = useContext(AuthContext); 

  type LikeData = {
    nome: string;
    slug: string;
    tipo: 'usuario' | 'banda';
    id: number;
    imagem: string; 
  };

  const [likesList, setLikesList] = useState<LikeData[]>([]);
  const [recentLikes, setRecentLikes] = useState<LikeData[]>([]); 

  useEffect(() => {
    setLiked(Boolean(user_or_banda_liked_id));  // Tenta atualizar o estado 'liked'
  
    // Loga o valor recebido e o estado atualizado
    console.log('user_or_banda_liked_id:', user_or_banda_liked_id, 'Estado liked atualizado para:', Boolean(user_or_banda_liked_id));
  }, [user_or_banda_liked_id]);
  

  const fetchLikes = async () => {
    if (!authState.token) {
      console.log('Nenhum token disponível - usuário provavelmente deslogado');
      return;
    }
  
    try {
      const response = await axios.get(`https://tribhus.shop:5000/like/show/${postId}`, {
        headers: { Authorization: `Bearer ${authState.token}` },
        params: {
          content_type: contentType
        }
      });
  
      const data = response.data.map((like: any) => {
        if (like.tipo === 'usuario') {
          return {
            nome: like.user_name,
            slug: like.user_slug,
            tipo: like.tipo,
            imagem: like.url_icone_usuario
          };
        } else if (like.tipo === 'banda') {
          return {
            nome: like.banda_name,
            slug: like.banda_slug,
            tipo: like.tipo,
            imagem: like.url_icone_banda
          };
        } else {
          // Caso padrão para lidar com tipos desconhecidos
          console.warn(`Tipo de like desconhecido: ${like.tipo}`);
          return {
            nome: 'Desconhecido',
            slug: '',
            tipo: 'desconhecido',
            imagem: ''
          };
        }
      });

      setLikesList(data);
      setRecentLikes(data.slice(-3));
    } catch (error) {
      console.error("Erro ao buscar curtidas:", error);
    }
  };

  useEffect(() => {
    fetchLikes();
  }, [authState.token, postId, contentType]); 

  const handleLikesClick = async () => {
    await fetchLikes();
    setModalOpen(true);
  };

  const handleButtonClick = async () => {
    if (liked) {
      handleUnlike();
    } else {
      handleLike();
    }
  };

  const handleUnlike = async () => {
    try {
        await axios.delete(`https://tribhus.shop:5000/like/unlike/${contentType}/${postId}`, {
            headers: { Authorization: `Bearer ${authState.token}` },
        });
        setLikesCount(prev => prev - 1);
        setLiked(false);
        console.log('Liked State after unliking:', liked);
    } catch (error) {
        console.error("Erro ao descurtir:", error);
    }
};

const handleLike = async () => {
  try {
   
    const response = await axios.post('https://tribhus.shop:5000/like', {
      contentId: postId, // ou musicId ou albumId conforme o caso
      contentType: contentType || 'postagem' // Default para postagem se não especificado
    }, {
      headers: { Authorization: `Bearer ${authState.token}` },
    });

    if (response.status === 201) {
      const id_curtida = response.data.id_curtida;

      setLikesCount(prev => prev + 1); // Atualiza o contador apenas uma vez
      setLiked(true);
      console.log('Liked State after liking:', liked);
      // Chamada para criar notificação
      try {
        await axios.post('https://tribhus.shop:5000/notificacoes/curtida', {
          id_postagem: postId,  
          id_curtida: id_curtida,
          contentType: contentType,
          slug: slug 
        }, {
          headers: { Authorization: `Bearer ${authState.token}` }
        });
      } catch (notifError) {
        console.error('Erro ao criar notificação:', notifError);
      }
    }      
  } catch (error) {
    console.error('Erro ao curtir:', error);
  }
};

useEffect(() => {
  setLikesCount(Number(initialLikes));  // Atualiza o contador de curtidas da música
  setLiked(Boolean(user_or_banda_liked_id));  // Atualiza se o usuário curtiu
}, [initialLikes, user_or_banda_liked_id]);  // Dependências atualizadas

const getLikesMessage = () => {
  if (likesCount === 0) return "0 curtidas";
  if (likesCount === 1) return "1 pessoa curtiu isso";
  return `${likesCount} pessoas curtiram isso`;
};
  
const styles: { [key: string]: CSSProperties } = {
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 20,
    },
    likeCount: {
      marginRight: 30,
      color: '#545C6F',
      fontWeight: 'bold',
      marginLeft: 15,
      fontSize: 12, 
    },
    likeButton: {
      width: 20, 
      height: 20, 
      marginRight: 20, 
    },
    image: {
      width: 20,
      height: 20,
      borderRadius: 15,
      marginRight: -5,
    },
  };

  const curtirIcon = require('../assets/curtir.png');
  const curtiuIcon = require('../assets/curtiu.png');

  return (
    <div style={styles.buttonContainer}>
      <button onClick={handleButtonClick} style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}>
        <img
          src={liked ? curtiuIcon : curtirIcon}
          alt="Like"
          style={{
            ...styles.likeButton,
            filter: liked
              ? 'brightness(0) saturate(100%) invert(55%) sepia(59%) saturate(1786%) hue-rotate(359deg) brightness(100%) contrast(106%)'
              : 'none',
          }}
        />
      </button>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {recentLikes.map((like, index) => (
          <img
            key={like.id ? like.id.toString() : index.toString()}
            src={like.imagem && like.imagem.trim() !== "" ? like.imagem : noImage} // Verifica se há imagem, caso contrário, usa a imagem padrão
            alt={`Recent like ${index + 1}`}
            style={styles.image}
          />
        ))}
      </div>
      <span style={styles.likeCount} onClick={handleLikesClick}>
        {getLikesMessage()}
      </span>
      <LikesModal isOpen={modalOpen} onClose={() => setModalOpen(false)} likesList={likesList} />
    </div>
  );
};  

export default LikeButton;