import React, { useState, useEffect, useContext, Dispatch, SetStateAction } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import CustomAlertModal from './CustomAlertModal';

interface InscritosProps {
  idBandaRecebe?: number;
  idUsuarioRecebe?: number;
  isOwnProfile: boolean;
  isFamilia: boolean;
  isInscrito: boolean;
  setIsInscrito: Dispatch<SetStateAction<boolean>>;
}

const Inscritos: React.FC<InscritosProps> = ({ idBandaRecebe, idUsuarioRecebe, isOwnProfile, isFamilia, isInscrito, setIsInscrito }) => {
  const [isUnsubscribeModalVisible, setIsUnsubscribeModalVisible] = useState(false);
  const { state: { id_usuario, id_banda, token } } = useContext(AuthContext);
  const navigate = useNavigate();

 useEffect(() => {
    if (!isOwnProfile && idBandaRecebe) {
      verificarInscricao();
    }
  }, [idBandaRecebe, token, isOwnProfile]);

  const verificarInscricao = async () => {
    if (!token || !idBandaRecebe) {
      console.log('Token ou ID da banda não disponível');
      return;
    }

    try {
      const response = await axios.get(`https://tribhus.shop:5000/amigos/inscricao/verificar/${idBandaRecebe}`, 
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsInscrito(response.data.isInscrito);
    } catch (error) {
      console.error('Erro ao verificar inscrição:', error);
      setIsInscrito(false);
    }
  };

  const inscreverNaBanda = async () => {
    if (!idBandaRecebe || !token) return;

    try {
      const response = await axios.post('https://tribhus.shop:5000/amigos/inscricao/inscrever', 
        { id_inscrito_banda: idBandaRecebe },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const idInscricao = response.data.id_inscricao;
      setIsInscrito(true);

      await axios.post('https://tribhus.shop:5000/notificacoes/inscricao', 
        { id_inscritor: id_usuario, id_inscrito_banda: idBandaRecebe, id_inscricao: idInscricao },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.error('Erro ao se inscrever na banda ou ao criar notificação:', error);
    }
  };

  if (isFamilia) {
    return null;
  }

  const removerInscricao = () => {
    setIsUnsubscribeModalVisible(true);
  };

  const confirmarRemocaoInscricao = async () => {
    setIsUnsubscribeModalVisible(false);
    if (!idBandaRecebe || !token) return;

    try {
      await axios.delete(`https://tribhus.shop:5000/amigos/inscricao/cancelar/${idBandaRecebe}`, 
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsInscrito(false);
    } catch (error) {
      console.error('Erro ao cancelar inscrição:', error);
    }
  };

  const handleInscritosClick = () => {
    navigate('/InscritosBanda');
  };

  // Se estamos visualizando um perfil de usuário (idUsuarioRecebe está presente), não exibimos o botão
  if (idUsuarioRecebe) {
    return null;
  }

  // Se o usuário faz parte da família da banda, não exibimos o botão
  if (isFamilia) {
    return null;
  }

  let buttonText = 'Inscritos';
  if (!isOwnProfile) {
    buttonText = isInscrito ? 'Inscrito' : 'Inscrever-se';
  }

  return (
    <>
      <button 
        onClick={() => {
          if (isOwnProfile && id_banda) {
            handleInscritosClick();
          } else if (!isOwnProfile && idBandaRecebe) {
            isInscrito ? removerInscricao() : inscreverNaBanda();
          }
        }}
        style={{ 
          backgroundColor: 'transparent', // Remove a cor de fundo
          color: '#FF6600', // Cor do texto
          border: 'none', // Remove a borda, se necessário
          padding: '10px 15px',
          borderRadius: '20px',
          cursor: 'pointer',
          fontSize: '16px',
          transition: 'color 0.3s', // Transição de cor no hover
        }}
      >
        {buttonText}
      </button>

      <CustomAlertModal
        isVisible={isUnsubscribeModalVisible}
        onDismiss={() => setIsUnsubscribeModalVisible(false)}
        onConfirm={confirmarRemocaoInscricao}
        title="Confirmação"
        message="Tem certeza que deseja cancelar sua inscrição?"
      />
    </>
  );
};

export default Inscritos;