import React, { useState, useEffect, useRef } from 'react';
import axios from '../Services/axiosInterceptor'; 
import AudioConverter, { AudioConverterRef } from '../Services/AudioConverter';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContratoUpload from '../Components/ContratoUpload';

interface Album {
    id_album: number;
    nome_album: string;
    ano_lancamento: string;
    url_capa_album: string;
}

interface Music {
    id_musica: number;
    nome_musica: string;
    nome: string;
    compositor: string;
    ano: string;
    letra: string; 
    status: 'pendente' | 'aprovado' | 'reprovado'; 
}

interface EditarAlbunsProps {
    id_banda: number;
    token: string;
    onAlbumUpdated: () => void;
}

const EditarAlbuns: React.FC<EditarAlbunsProps> = ({ id_banda, token, onAlbumUpdated }) => {
    const [albums, setAlbums] = useState<Album[]>([]);
    const [selectedAlbum, setSelectedAlbum] = useState<Album | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [newAlbumName, setNewAlbumName] = useState('');
    const [newAlbumYear, setNewAlbumYear] = useState('');
    const [newAlbumCover, setNewAlbumCover] = useState<File | null>(null);
    const [contratoAceito, setContratoAceito] = useState(false);
    const [mostrarContrato, setMostrarContrato] = useState(false);
    const [musics, setMusics] = useState<Music[]>([]);
    const [newMusic, setNewMusic] = useState({
        nome: '',
        compositor: '',
        ano: '',
        arquivo: null as File | null,
        letra: '' // Novo campo para a letra
    });
    const [isProcessing, setIsProcessing] = useState(false);
    const [conversionProgress, setConversionProgress] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);
    const audioConverterRef = useRef<AudioConverterRef>(null);
    const [showAlbumDetails, setShowAlbumDetails] = useState(false);

    useEffect(() => {
        if (id_banda === null || token === null) return;

        fetchAlbums();
        verificarContrato();
    }, [id_banda, token]);

    const verificarContrato = async () => {
        try {
            const response = await axios.get(`https://tribhus.shop:5000/bands/check-contract/${id_banda}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setContratoAceito(response.data.aceito);
        } catch (error) {
            console.error("Erro ao verificar contrato:", error);
        }
    };

    const aceitarContrato = async () => {
        try {
            await axios.post(`https://tribhus.shop:5000/bands/accept-contract/${id_banda}`, {
                tipo_contrato: 'upload_musica'
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setContratoAceito(true);
            setMostrarContrato(false);
            toast.success("Contrato aceito com sucesso!");
        } catch (error) {
            console.error("Erro ao aceitar contrato:", error);
            toast.error("Erro ao aceitar o contrato. Por favor, tente novamente.");
        }
    };

    useEffect(() => {
        if (id_banda === null || token === null) return;

        fetchAlbums();
    }, [id_banda, token]);

    const fetchAlbums = async () => {
        try {
            const response = await axios.get(`https://tribhus.shop:5000/bands/albuns/${id_banda}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setAlbums(response.data);
            if (selectedAlbum) {
                // Atualizar o álbum selecionado com os dados mais recentes
                const updatedAlbum = response.data.find((album: Album) => album.id_album === selectedAlbum.id_album);
                if (updatedAlbum) {
                    setSelectedAlbum(updatedAlbum);
                    setNewAlbumName(updatedAlbum.nome_album);
                    setNewAlbumYear(updatedAlbum.ano_lancamento);
                }
            }
        } catch (error) {
            console.error("Erro ao buscar álbuns:", error);
        }
    };

    const fetchMusics = async (albumId: number) => {
        try {
            const response = await axios.get(`https://tribhus.shop:5000/bands/album-tracks-dash/${albumId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            setMusics(response.data);
        } catch (error) {
            console.error("Erro ao buscar músicas:", error);
        }
    };

    const handleAlbumSelect = (album: Album) => {
        setSelectedAlbum(album);
        setNewAlbumName(album.nome_album);
        setNewAlbumYear(album.ano_lancamento);
        fetchMusics(album.id_album);
        setEditMode(false);
    };

    const handleEditAlbum = () => {
        setEditMode(true);
    };

    const handleSaveAlbum = async () => {
        if (!selectedAlbum) return;
    
        const formData = new FormData();
        formData.append('nome', newAlbumName);
        formData.append('ano', newAlbumYear);
        if (newAlbumCover) {
            formData.append('image-album', newAlbumCover);
        }
    
        try {
            await axios.put(`https://tribhus.shop:5000/bands/edit-album/${selectedAlbum.id_album}/${id_banda}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setEditMode(false);
            fetchAlbums(); // Atualiza a lista de álbuns
            toast.success("Álbum atualizado com sucesso!");
        } catch (error) {
            console.error("Erro ao atualizar álbum:", error);
            if (axios.isAxiosError(error)) {
                console.error('Detalhes do erro:', error.response?.data);
            }
            toast.error("Não foi possível atualizar o álbum. Por favor, tente novamente.");
        }
    };

    const handleDeleteAlbum = async () => {
        if (!selectedAlbum || !window.confirm("Tem certeza que deseja excluir este álbum?")) return;
    
        try {
            await axios.delete(`https://tribhus.shop:5000/bands/delete-album/${selectedAlbum.id_album}/${id_banda}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setSelectedAlbum(null);
            fetchAlbums(); // Atualiza a lista de álbuns após a exclusão
            toast.success("Álbum excluído com sucesso!");
        } catch (error) {
            console.error("Erro ao excluir álbum:", error);
            toast.error("Não foi possível excluir o álbum. Por favor, tente novamente.");
        }
    };
    
    const handleAddMusic = async () => {
        if (!selectedAlbum) {
            toast.error('Por favor, selecione um álbum antes de adicionar músicas.');
            return;
        }
        if (!id_banda) {
            toast.error('Erro: ID da banda não disponível. Por favor, faça login novamente.');
            return;
        }
    
        setIsProcessing(true);
        setConversionProgress(0);
        setUploadProgress(0);
    
        try {
            // Iniciar a conversão
            if (audioConverterRef.current) {
                await audioConverterRef.current.convertToAAC();
            }
    
            // Certifique-se de que o arquivo convertido foi salvo no estado
            if (!newMusic.arquivo) {
                toast.error('Por favor, clique novamente em Adicionar Música.');
                return;
            }
    
            const formData = new FormData();
            formData.append('id_banda', id_banda.toString());
            formData.append('id_album', selectedAlbum.id_album.toString());
            formData.append('nome_musica', newMusic.nome);
            formData.append('compositor', newMusic.compositor);
            formData.append('ano_lancamento', newMusic.ano);
            formData.append('url_imagem', selectedAlbum.url_capa_album || '');
            formData.append('letra', newMusic.letra); // Adicionando a letra
            if (newMusic.arquivo) {
                formData.append('music', newMusic.arquivo);
            }    
    
            const resposta = await axios.post(
                `https://tribhus.shop:5000/bands/upload-music/${id_banda}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent: any) => {
                        if (progressEvent.total) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentCompleted);
                        } else {
                            console.log(`Uploaded ${progressEvent.loaded} bytes`);
                            setUploadProgress(-1);
                        }
                    }
                }
            );
            
            // Atualizar a criação da nova música para incluir o status
            const novaMusica: Music = {
                id_musica: resposta.data.musicId,
                nome_musica: newMusic.nome,
                nome: newMusic.nome,
                compositor: newMusic.compositor,
                ano: newMusic.ano,
                letra: newMusic.letra,
                status: 'pendente' // Como sabemos que música nova sempre começa como pendente
            };
            
            setMusics([...musics, novaMusica]);
            setNewMusic({ nome: '', compositor: '', ano: '', arquivo: null, letra: '' });    
            
            toast.success('Música salva com sucesso!');
            await fetchMusics(selectedAlbum!.id_album);
            onAlbumUpdated();
        } catch (erro: any) {
            console.error('Erro detalhado ao salvar música:', erro);
            if (axios.isAxiosError(erro)) {
                console.error('Detalhes do erro de rede:', {
                    message: erro.message,
                    response: erro.response?.data,
                    status: erro.response?.status,
                    headers: erro.response?.headers
                });
            } else {
                console.error('Erro não relacionado ao Axios:', erro.message);
            }
            toast.error(`Erro ao salvar música: ${erro.message}. Por favor, tente novamente.`);
        } finally {
            setIsProcessing(false);
            setConversionProgress(0);
            setUploadProgress(0);
        }
    };

    const handleDeleteMusic = async (musicId: number) => {
        if (!window.confirm("Tem certeza que deseja excluir esta música?")) return;

        try {
            await axios.delete(`https://tribhus.shop:5000/bands/delete-music/${musicId}/${id_banda}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            fetchMusics(selectedAlbum!.id_album); // Atualiza a lista de músicas após a exclusão
        } catch (error) {
            console.error("Erro ao excluir música:", error);
            toast.error("Não foi possível excluir a música. Por favor, tente novamente.");
        }
    };

    const handleAlbumClick = (album: Album) => {
        handleAlbumSelect(album);
        setShowAlbumDetails(true);
    };    

    const handleCloseDetails = () => {
        setShowAlbumDetails(false);
    };

    if (!id_banda || !token) {
        return null;
    }
    
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        setNewMusic(prev => ({ ...prev, arquivo: file }));
    };

    return (
        <div style={estilos.container}>
            <div style={estilos.centeredContent}>
                <div style={estilos.header}>
                    {/* Você pode adicionar elementos de cabeçalho aqui se necessário */}
                </div>
                <h2 style={estilos.titulo}>Editar Álbuns</h2>
                <div style={estilos.content}>
                    <div style={estilos.leftColumn}>
                        <h3 style={estilos.subtitulo}>Álbuns</h3>
                        {albums.map(album => (
                            <div 
                                key={album.id_album} 
                                onClick={() => handleAlbumClick(album)}
                                style={{ 
                                    cursor: 'pointer', 
                                    padding: '10px', 
                                    backgroundColor: selectedAlbum?.id_album === album.id_album ? '#2C3E50' : '#34495E',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '10px'
                                }}
                            >
                                <img src={album.url_capa_album} alt={album.nome_album} style={estilos.albumImage} />
                                <span style={{ color: '#FFFFFF', marginLeft: '10px' }}>{album.nome_album}</span>
                            </div>
                        ))}
                    </div>
                    
                    {showAlbumDetails && selectedAlbum && (
                        <div style={estilos.rightColumn}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h3 style={estilos.subtitulo}>Detalhes do Álbum</h3>
                                <button onClick={handleCloseDetails} style={estilos.botaoFechar}>
                                    Fechar
                                </button>
                            </div>
                            {editMode ? (
                                <>
                                    <input 
                                        type="text" 
                                        value={newAlbumName} 
                                        onChange={(e) => setNewAlbumName(e.target.value)}
                                        placeholder="Nome do Álbum"
                                        style={estilos.input}
                                    />
                                    <input 
                                        type="text" 
                                        value={newAlbumYear} 
                                        onChange={(e) => setNewAlbumYear(e.target.value)}
                                        placeholder="Ano de Lançamento"
                                        style={estilos.input}
                                    />
                                    <input 
                                        type="file" 
                                        onChange={(e) => setNewAlbumCover(e.target.files ? e.target.files[0] : null)}
                                        style={estilos.input}
                                    />
                                    <button onClick={handleSaveAlbum} style={estilos.botao}>
                                        Salvar Alterações
                                    </button>
                                </>
                            ) : (
                                <>
                                    <p style={estilos.albumDetailsText}>Nome: {selectedAlbum.nome_album}</p>
                                    <p style={estilos.albumDetailsText}>Ano: {selectedAlbum.ano_lancamento}</p>
                                    <img src={selectedAlbum.url_capa_album} alt={selectedAlbum.nome_album} style={estilos.albumImageLarge} />
                                    <button onClick={handleEditAlbum} style={estilos.botao}>
                                        Editar Álbum
                                    </button>
                                    <button onClick={handleDeleteAlbum} style={estilos.botaoExcluir}>
                                        Excluir Álbum
                                    </button>
                                </>
                            )}
                            
                            <h3 style={estilos.subtitulo}>Músicas</h3>
                            {musics.map(music => (
    <div key={music.id_musica} style={estilos.musicItem}>
        <div style={estilos.musicInfo}>
            <span style={estilos.musicName}>{music.nome_musica}</span>
            <span style={estilos.musicComposer}>{music.compositor}</span>
            <span style={{
                color: music.status === 'aprovado' ? '#00FF00' : '#FF0000', // Verde se aprovado, vermelho caso contrário
                fontSize: '12px',
                fontWeight: 'bold'
            }}>
                {music.status.toUpperCase()}
            </span>
        </div>
        <button onClick={() => handleDeleteMusic(music.id_musica)} style={estilos.ExcluirMusica}>
            Excluir
        </button>
    </div>
))}
 
 <h4 style={estilos.subtitulo}>Adicionar Nova Música</h4>
    <input 
        type="text" 
        value={newMusic.nome} 
        onChange={(e) => setNewMusic({...newMusic, nome: e.target.value})}
        placeholder="Nome da Música"
        style={estilos.input}
    />
    <input 
        type="text" 
        value={newMusic.compositor} 
        onChange={(e) => setNewMusic({...newMusic, compositor: e.target.value})}
        placeholder="Compositor"
        style={estilos.input}
    />
    <input 
        type="text" 
        value={newMusic.ano} 
        onChange={(e) => setNewMusic({...newMusic, ano: e.target.value})}
        placeholder="Ano de Lançamento"
        style={estilos.input}
    />
    <textarea 
        value={newMusic.letra}
        onChange={(e) => setNewMusic({...newMusic, letra: e.target.value})}
        placeholder="Letra da Música"
        style={{...estilos.input, height: '100px'}}
    />
    <input
        type="file"
        onChange={handleFileSelect}
        accept="audio/*"
        style={estilos.input}
    />
    <AudioConverter 
        ref={audioConverterRef}
        onConversionComplete={(convertedFile) => setNewMusic(prev => ({ ...prev, arquivo: convertedFile }))}
        songName={newMusic.nome}
        onProgress={setConversionProgress}
        file={newMusic.arquivo}
    />
                            {!contratoAceito ? (
                                <button 
                                    onClick={() => alert('Por favor, aceite o Contrato de Upload e Licenciamento de Conteúdo Musical. Você só precisa fazer isso uma vez.')} 
                                    style={estilos.botao}
                                >
                                    Adicionar Música
                                </button>
                            ) : (
                                <button 
                                    onClick={handleAddMusic} 
                                    disabled={isProcessing}
                                    style={estilos.botao}
                                >
                                    {isProcessing ? 'Processando...' : 'Adicionar Música'}
                                </button>
                            )}
                              {isProcessing && (
                                <div>
                                    <p style={estilos.progressText}>Conversão: {conversionProgress.toFixed(2)}%</p>
                                    <p style={estilos.progressText}>
                                        Upload: {uploadProgress >= 0 ? `${uploadProgress.toFixed(2)}%` : 'Em progresso...'}
                                    </p>
                                </div>
                            )}
                            {!contratoAceito ? (
                                <div style={estilos.contratoAviso}>
                                    <p style={estilos.contratoText}>
                                        Para fazer upload de músicas, você precisa aceitar nosso Contrato de Upload e Licenciamento de Conteúdo Musical.
                                    </p>
                                    <button 
                                        onClick={() => setMostrarContrato(true)} 
                                        style={estilos.botaoVerContrato}
                                    >
                                        Ver Contrato
                                    </button>
                                </div>
                            ) : (
                                <div style={estilos.contratoAviso2}>
                                    <p style={estilos.contratoText}>
                                        Você já aceitou o Contrato de Upload. Se quiser revisá-lo, clique abaixo.
                                    </p>
                                    <button 
                                        onClick={() => setMostrarContrato(true)} 
                                        style={estilos.botaoVerContrato}
                                    >
                                        Ver Contrato
                                    </button>
                                </div>
                            )}
                        
                        </div>
                    )}
                </div>
            </div>
    
            {mostrarContrato && (
                <ContratoUpload
                    onAccept={aceitarContrato}
                    onClose={() => setMostrarContrato(false)}
                />
            )}
        </div>
    );
    };
    
const estilos: { [key: string]: React.CSSProperties } = {
    container: {
        flex: 1,
        backgroundColor: '#2D2D2D',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        border: '2px solid #FF6600', // Adiciona uma borda laranja com 2px de espessura
        borderRadius: '8px', // Adiciona cantos arredondados, ajuste conforme necessário
        padding: '10px', // Adiciona um padding interno para afastar o conteúdo da borda
    },    
    centeredContent: {
        width: '100%',
        maxWidth: '900px',
        padding: '20px',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
    },
    titulo: {
        fontSize: '20px',
        color: 'lightgray',
        fontWeight: 'bold',
        textAlign: 'left',
    },
    musicInfo: {
        display: 'flex',
        flexDirection: 'column'
    },
    musicName: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#ECF0F1'
    },
    musicComposer: {
        fontSize: '14px',
        color: '#BDC3C7'
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    leftColumn: {
        width: '100%',
        maxWidth: '200px',
        marginRight: '20px',
    },
    rightColumn: {
        flex: 1,
        backgroundColor: '#1E2736',
        padding: '20px',
        borderRadius: '10px',
    },
    subtitulo: {
        fontSize: '18px',
        color: '#FF6600',
        fontWeight: 'bold',
        marginBottom: '15px',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: '#2C3E50',
        border: 'none',
        borderRadius: '5px',
        color: 'lightgray',
    },
    botao: {
        backgroundColor: '#FF6600',
        color: 'lightgray',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '10px',
        transition: 'background-color 0.3s',
    },
    botaoExcluir: {
        backgroundColor: '#e74c3c',
        color: 'lightgray',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '10px',
        marginLeft: '10px',
        transition: 'background-color 0.3s',
    },
    ExcluirMusica: {
        backgroundColor: 'transparent',  // Remover a cor de fundo
        color: '#FF6600',  // Cor do texto em laranja
        padding: '0',  // Remover padding adicional
        cursor: 'pointer',  // Manter o cursor de pointer
        fontSize: '16px',
        marginTop: '10px',
        marginLeft: '10px',
        border: 'none',  // Remover qualquer borda existente
        boxShadow: 'none',  // Remover qualquer sombra existente
        outline: 'none',  // Remover o outline que pode aparecer ao focar
        transition: 'color 0.3s',  // Transição apenas para a cor do texto
    },
    albumImage: {
        width: '50px',
        height: '50px',
        marginRight: '10px',
        borderRadius: '4px',
    },
    albumImageLarge: {
        width: '100px',
        height: '100px',
        marginBottom: '10px',
        borderRadius: '4px',
    },
    albumDetailsText: {
        fontSize: '16px',
        color: 'lightgray',
    },
    musicItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 0',
        borderBottom: '1px solid #34495E',
    },
    progressContainer: {
        marginTop: '10px',
    },
    progressText: {
        fontSize: '14px',
        color: 'lightgray',
    },
    botaoFechar: {
        backgroundColor: '#e74c3c',
        color: 'lightgray',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
        transition: 'background-color 0.3s',
    },
    contratoAviso: {
        marginBottom: '10px',
        padding: '10px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        marginTop: '20px',
       },
    contratoText: {
        color: '#000',
        marginBottom: '20px',
    },
    botaoVerContrato: {
        backgroundColor: '#000',
        color: 'lightgray',
        border: 'none',
        padding: '5px 10px',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    contratoAviso2: {
        padding: '10px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        marginTop: '60px',
       },
};

export default EditarAlbuns;