import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  song: null,
};

const playingSongSlice = createSlice({
  name: 'playingSong',
  initialState,
  reducers: {
    setPlayingSong: (state, action) => {
      state.song = action.payload;
    },
  },
});

export const { setPlayingSong } = playingSongSlice.actions;
export const selectPlayingSong = (state) => state.playingSong.song;
export default playingSongSlice.reducer;
