import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import axios from '../Services/axiosInterceptor'; 
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


interface EditSecurityModalUserProps {
        isOpen: boolean;
        onRequestClose: () => void;
      }  

const EditSecurityModalUser: React.FC<EditSecurityModalUserProps> = ({ isOpen, onRequestClose })=> {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [slug, setSlug] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const [sendAttempts, setSendAttempts] = useState(0);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [error, setError] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const { state: authState, dispatch } = useContext(AuthContext); 
  const [countdown, setCountdown] = useState(180);
  const [timerActive, setTimerActive] = useState(false);
  const [authId, setAuthId] = useState<number | null>(null);
     // estados para o modal de confirmação de exclusão
  const [deleteConfirmModalIsOpen, setDeleteConfirmModalIsOpen] = useState(false);
  const [passwordForDeletion, setPasswordForDeletion] = useState('');

  // Função para excluir a conta
  const openDeleteConfirmModal = () => {
    setDeleteConfirmModalIsOpen(true);
  };

  const closeDeleteConfirmModal = () => {
    setDeleteConfirmModalIsOpen(false);
    setPasswordForDeletion('');
  };

  const deleteAccount = async () => {
    try {
      const verifyResponse = await axios.post('https://tribhus.shop:5000/users/verify-password', {
        auth_id: authState.auth_id,
        password: passwordForDeletion
      }, {
        headers: { Authorization: `Bearer ${authState.token}` }
      });

      if (!verifyResponse.data.isValid) {
        toast.error('Senha incorreta. Por favor, tente novamente.');
        return;
      }

      const url = `https://tribhus.shop:5000/delete/delete-account/usuario/${authState.id_usuario}`;
      const response = await axios.post(url, { auth_id: authState.auth_id }, {
        headers: { Authorization: `Bearer ${authState.token}` }
      });

      if (response.data.message === 'Conta excluída com sucesso.') {
        toast.success('Conta excluída com sucesso!');
        onRequestClose();

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('sessionId');
        localStorage.removeItem('slug');
        localStorage.removeItem('auth_id');
        localStorage.removeItem('id_usuario');

        dispatch({ type: 'RESET_AUTH' });
        navigate('/PrimeiraTela');
      } else {
        throw new Error(response.data.error || 'Falha ao excluir a conta.');
      }
    } catch (error) {
      const message = extractErrorMessage(error);
      toast.error(`Erro ao excluir a conta: ${message}`);
    } finally {
      closeDeleteConfirmModal();
    }
  };

  useEffect(() => {
    const fetchAuthId = async () => {
      try {
        console.log('Buscando auth_id para id_usuario:', authState.id_usuario);
        const id_usuario = authState.id_usuario;
        const response = await axios.get(`https://tribhus.shop:5000/users/get-auth-id/${id_usuario}`, {
          headers: { Authorization: `Bearer ${authState.token}` }
        });
        console.log('Fetched auth_id:', response.data.auth_id);
        setAuthId(response.data.auth_id);
      } catch (error) {
        console.error('Erro ao buscar auth_id:', error);
      }
    };

    if (authState.id_usuario) {
      fetchAuthId();
    }
  }, [authState.id_usuario]);

  const validatePassword = () => {
    if (password.length < 8) {
      alert('A senha deve ter no mínimo 8 caracteres.');
      return false;
    }

    if (password !== confirmPassword) {
      alert('As senhas não correspondem!');
      return false;
    }

    return true;
  };

  const saveNewPassword = async () => {
    if (!validatePassword()) {
      return;
    }

    try {
      const response = await axios.post('https://tribhus.shop:5000/users/change-password', {
        currentPassword: currentPassword,
        newPassword: password,
        auth_id: authId
      }, {
        headers: { Authorization: `Bearer ${authState.token}` }
      });

      if (response.data.message === 'Senha atualizada com sucesso.') {
        alert('Senha atualizada com sucesso!');
        setPassword('');
        setConfirmPassword('');
        setCurrentPassword('');
        navigate(-1);
      } else {
        throw new Error(response.data.error || 'Falha ao atualizar a senha.');
      }
    } catch (error) {
      const message = extractErrorMessage(error);
      alert(`Erro ao atualizar a senha: ${message}`);
    }
  };

  function extractErrorMessage(error: unknown): string {
    if (typeof error === 'object' && error !== null) {
      const e = error as { response?: { data?: { error?: string; }; }; message?: string; };
      if (e.response && e.response.data && e.response.data.error) {
        return e.response.data.error;
      } else if (e.message) {
        return e.message;
      }
    }
    return 'Erro desconhecido';
  }

  const checkSlugAvailability = async () => {
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/check-slug', { slug });
      const responseData = response.data;
  
      if (responseData.message === "Nome de Usuário já está em uso, por favor escolha outro.") {
        setErrorMsg(responseData.message);
        return false; // Slug indisponível
      } else {
        setErrorMsg(null); // Limpa a mensagem de erro
        return true; // Slug disponível
      }
    } catch (error) {
      console.error(error);
      setErrorMsg('Ocorreu um problema ao verificar a disponibilidade do slug.');
      return false; // Slug indisponível
    }
  };
  
  const saveNewSlug = async () => {
    if (!(await checkSlugAvailability())) {
      return;
    }
  
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/change-slug', {
        newSlug: slug,
        id_usuario: authState.id_usuario
      }, {
        headers: { Authorization: `Bearer ${authState.token}` }
      });
  
      if (response.data.message === 'Slug atualizado com sucesso.') {
        toast.success('Slug atualizado com sucesso!');
        navigate(-1); // Equivalente a navigation.goBack() no React Navigation
      } else {
        throw new Error(response.data.error || 'Falha ao atualizar o slug.');
      }
    } catch (error) {
      const message = extractErrorMessage(error);
      toast.error(`Erro ao atualizar o slug: ${message}`);
    }
  };
  
  const handlePhoneChange = (value: string) => {
    let formattedNumber = value.replace(/\D/g, ''); // Remove tudo que não é número
    if (formattedNumber.length > 2 && formattedNumber.length <= 6) {
      formattedNumber = `(${formattedNumber.slice(0, 2)}) ${formattedNumber.slice(2)}`;
    } else if (formattedNumber.length > 6 && formattedNumber.length <= 10) {
      formattedNumber = `(${formattedNumber.slice(0, 2)}) ${formattedNumber.slice(2, 6)}-${formattedNumber.slice(6)}`;
    } else if (formattedNumber.length > 10) {
      formattedNumber = `(${formattedNumber.slice(0, 2)}) ${formattedNumber.slice(2, 7)}-${formattedNumber.slice(7, 11)}`;
    }
    setCellphone(formattedNumber);
  };  

  const submitPhoneNumber = async () => {
    if (cellphone.length < 11) { // Verifica se o número é suficientemente longo
      setError("Por favor, insira um número de telefone completo e válido.");
      return;
    }
  
    if (sendAttempts >= 2) { // Limita o número de tentativas de envio
      setError("Número máximo de tentativas alcançado.");
      return;
    }
  
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/send-sms-update', {
        phoneNumber: cellphone,
        auth_id: authId
      });
  
      if (response.data.message === "SMS enviado com sucesso.") {
        setShowVerificationInput(true);
        setError('');
        setTimerActive(true); // Ativa o timer
        setCountdown(180); // Inicia a contagem de 3 minutos
      } else {
        setSendAttempts(sendAttempts + 1);
        setError("Falha ao enviar SMS. Por favor, tente novamente."); // Mensagem genérica de erro
      }
    } catch (error) {
      console.log(error); // Melhor log do erro
      setSendAttempts(sendAttempts + 1);
      if (sendAttempts >= 1) {
        setError("Não foi possível enviar o SMS, por favor entre em contato.");
      } else {
        setError('Erro ao conectar ao servidor. Por favor, tente novamente mais tarde.');
      }
    }
  };  
  const verifySMSCode = async () => {
    if (!verificationCode) {
      toast.error('Por favor, insira o código de verificação.');
      return;
    }
  
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/verify-sms-update', {
        phoneNumber: cellphone,
        verificationCode,
        auth_id: authId
      });
  
      console.log(response.data); // Continuar logando para confirmar as mudanças
      
      if (response.data.isVerified) {
        navigate(-1); // Equivalente a navigation.goBack()
        toast.success('Número de celular verificado com sucesso!', {
          onClose: () => {
            // Ações adicionais após o toast ser fechado, se necessário
          }
        });
      } else {
        toast.error('Código de verificação inválido. ' + (response.data.error || 'Tente novamente.'));
      }
    } catch (error: unknown) {
      // Usar asserção de tipo para lidar com o erro de forma segura
      const err = error as { response?: { data?: { error?: string } } };
      console.log(err.response ? err.response.data : error); // Log para entender o erro recebido
      const errorMessage = err.response && err.response.data && err.response.data.error 
        ? err.response.data.error 
        : 'Tente novamente.';
      toast.error('Erro ao verificar. ' + errorMessage);
    }
  };

  useEffect(() => {
    let timer: number | undefined;
  
    if (timerActive && countdown > 0) {
      timer = window.setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      toast.info('Envio indisponível ou número não encontrado. Tente novamente em alguns minutos, se persistir o problema, mude para outro número.', {
        autoClose: 5000, // O toast ficará visível por 5 segundos
        onClose: () => {
          // Ações a serem executadas quando o toast for fechado
          setTimerActive(false);
          setShowVerificationInput(false);
          setCellphone('');
          setCountdown(180);
        }
      });
    }
  
    return () => {
      if (timer !== undefined) {
        clearTimeout(timer);
      }
    };
  }, [countdown, timerActive]);

  if (!isOpen) return null;

  return (
    <div 
      onClick={onRequestClose} 
      style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: 'rgba(0, 0, 0, 0.5)' // Adiciona uma sobreposição escura ao fundo
      }}
    >
      <div 
        style={styles.container} 
        onClick={(e) => e.stopPropagation()} // Previne o fechamento ao clicar dentro do modal
      >
        <div style={styles.headerContainer}>
          <h1 style={styles.title}>Editar Dados de Segurança</h1>
          <div style={{ width: 50 }} />
        </div>
  
        {/* Aqui removemos o botão de fechar */}
  
        <label style={styles.label}>Senha Atual</label>
        <input
          type="password"
          onChange={(e) => setCurrentPassword(e.target.value)}
          value={currentPassword}
          style={styles.input}
        />
  
        <label style={styles.label}>Nova Senha</label>
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          style={styles.input}
        />
  
        <label style={styles.label}>Confirmar Nova Senha</label>
        <input
          type="password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          style={styles.input}
        />
  
        <button 
          style={styles.button} 
          onClick={saveNewPassword}
        >
          <span style={styles.buttonText}>Salvar Nova Senha</span>
        </button>
  
        <label style={styles.label}>Novo Usuário (Slug)</label>
        <input
          onChange={(e) => setSlug(e.target.value)}
          value={slug}
          style={styles.input}
        />
  
        <button style={styles.button} onClick={saveNewSlug}>
          <span style={styles.buttonText}>Salvar Novo Usuário</span>
        </button>

        <button style={styles.deleteButtonStyle} onClick={openDeleteConfirmModal}>
          Excluir Conta
        </button>

        <Modal
  isOpen={deleteConfirmModalIsOpen}
  onRequestClose={closeDeleteConfirmModal}
  contentLabel="Confirmar Exclusão de Conta"
  style={{
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px', // Um pouco menor que o modal principal
      maxWidth: '90%',
      backgroundColor: '#1E1E1E',
      padding: '30px',
      borderRadius: '10px',
      boxSizing: 'border-box',
    },
  }}
>
  <h2 style={{ color: '#FFF', marginBottom: '20px' }}>Confirmar Exclusão de Conta</h2>
  <p style={{ color: '#FFF', marginBottom: '15px' }}>Por favor, insira sua senha para confirmar a exclusão da conta:</p>
  <input
    type="password"
    value={passwordForDeletion}
    onChange={(e) => setPasswordForDeletion(e.target.value)}
    style={styles.input}
    placeholder="Digite sua senha"
  />
  <button style={{...styles.button, backgroundColor: '#FF0000', marginTop: '20px'}} onClick={deleteAccount}>
    <span style={styles.buttonText}>Confirmar Exclusão</span>
  </button>
  <button style={{...styles.button, backgroundColor: '#333', marginTop: '10px'}} onClick={closeDeleteConfirmModal}>
    <span style={styles.buttonText}>Cancelar</span>
  </button>
</Modal>
  
        {errorMsg && <p style={styles.errorMsg}>{errorMsg}</p>}
  
        <label style={styles.label}>Novo Celular de segurança</label>
        <input
          style={styles.input}
          onChange={(e) => handlePhoneChange(e.target.value)}
          value={cellphone}
          type="tel"
        />
  
        {error && <p style={styles.error}>{error}</p>}
  
        {timerActive && (
          <p style={styles.timer}>
            Enviando... por favor, aguarde {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')} minutos
          </p>
        )}
  
        {showVerificationInput ? (
          <>
            <input
              style={styles.input}
              placeholder="Digite o Código de Verificação aqui"
              onChange={(e) => setVerificationCode(e.target.value)}
              value={verificationCode}
              type="number"
              maxLength={6}
            />
            <button style={styles.button} onClick={verifySMSCode}>
              <span style={styles.buttonText}>Validar código e prosseguir</span>
            </button>
          </>
        ) : (
          <button style={styles.button} onClick={submitPhoneNumber}>
            <span style={styles.buttonText}>Enviar código SMS</span>
          </button>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    maxWidth: '700px',
    padding: '20px',
    backgroundColor: '#1E1E1E',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center' as const,
    justifyContent: 'space-between' as const,
    marginBottom: '20px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FFF',
  },
  backButton: {
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    width: '50px',
    height: '50px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  backIcon: {
    width: '30px',
    height: '30px',
  },
  input: {
    height: '40px',
    borderColor: 'gray',
    borderWidth: '1px',
    marginBottom: '12px',
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: '#1E1E1E',
    width: '95%', // Reduzido de 100% para 90%
    borderRadius: '20px',
    color: '#fff',
  },
  label: {
    fontSize: '14px',
    color: '#FF6600',
    marginBottom: '5px',
    display: 'block',
  },
  button: {
    backgroundColor: '#914100',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: '10px',
    alignItems: 'center' as const,
    marginTop: '5px',
    marginBottom: '5px',
    border: 'none',
    cursor: 'pointer',
    width: '98%',
  },
  buttonText: {
    color: '#FFF',
    fontSize: '16px',
    textAlign: 'center' as const,
  },
  error: {
    color: 'red',
    fontSize: '14px',
    marginBottom: '10px',
  },
  timer: {
    color: 'orange',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  customToast: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#333',
    color: '#fff',
    padding: '10px',
    borderRadius: '5px',
    zIndex: 1000,
  },
  errorMsg: {
    color: '#FF6600',
    textAlign: 'center' as const,
    marginTop: '10px',
  },
  deleteButtonStyle: {
    width: '100%',
    padding: '12px',
    backgroundColor: '#FF0000', 
    border: 'none',
    borderRadius: '5px',
    color: '#FFF',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginBottom: '16px',
  }
};
  
  export default EditSecurityModalUser;
  