//Componente utilizado em Palco.tsx para excluir ou editar postagens ou comentários
import React, { useState } from 'react';

interface MenuButtonProps {
  onClick: () => void;
}

const VerticalDotsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <circle cx="12" cy="5" r="2" />
    <circle cx="12" cy="12" r="2" />
    <circle cx="12" cy="19" r="2" />
  </svg>
);

const MenuButton: React.FC<MenuButtonProps> = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'color 0.3s ease',
        color: isHovered ? '#fff' : '#aaa',
      }}
    >
      <VerticalDotsIcon />
    </button>
  );
};

export default MenuButton;