import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePasswordReset } from '../context/PasswordResetContext';
import Modal from 'react-modal';

const PasswordResetScreen: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { token } = useParams<{ token: string }>();
  const { resetPassword, loading, error } = usePasswordReset();
  const navigate = useNavigate();

  useEffect(() => {
    const checkMobile = () => {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];
      
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    };

    setIsMobile(checkMobile());
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setModalMessage('As senhas não coincidem. Por favor, tente novamente.');
      setShowModal(true);
      setIsSuccess(false);
      return;
    }
    if (token) {
      const success = await resetPassword(token, password);
      if (success) {
        setModalMessage(isMobile 
          ? 'Senha alterada, você já pode entrar no seu aplicativo.'
          : 'Senha redefinida com sucesso.');
        setIsSuccess(true);
      } else {
        setModalMessage('Ocorreu um erro ao redefinir a senha. Por favor, tente novamente.');
        setIsSuccess(false);
      }
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    if (isSuccess && !isMobile) {
      navigate('/'); // Redireciona para a tela de login na versão web apenas para desktop
    }
  };

  return (
    <div style={styles.container}>
      <img src={require('../assets/logotipo.png')} alt="Logotipo" style={styles.logo} />
      <h1 style={styles.title}>Por Favor, Insira Uma Nova Senha</h1>
      
      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Nova senha"
          required
        />
        <input
          style={styles.input}
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirme a nova senha"
          required
        />
      </div>
      
      {error && <p style={styles.errorText}>{error}</p>}
     
      <button
        style={styles.button}
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? 'Redefinindo...' : 'Redefinir Senha'}
      </button>

      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        style={customStyles}
        contentLabel="Resultado da Redefinição"
      >
        <h2 style={styles.modalTitle}>{modalMessage}</h2>
        <button onClick={handleCloseModal} style={styles.modalButton}>
          Fechar
        </button>
      </Modal>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#151922',
    padding: '20px',
  },
  logo: {
    width: '280px',
    height: '360px',
    objectFit: 'contain',
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    color: '#fff',
    textAlign: 'center',
    marginBottom: '20px',
  },
  inputContainer: {
    width: '80%',
    maxWidth: '300px',
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '10px',
    backgroundColor: '#151922',
    borderColor: '#914100',
    borderWidth: '1px',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    fontSize: '20px',
  },
  button: {
    backgroundColor: '#914100',
    padding: '15px 50px',
    borderRadius: '10px',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    textAlign: 'center',
    width: '200px',
    margin: '10px auto',
  },
  errorText: {
    color: '#FF6600',
    fontSize: '14px',
    textAlign: 'center',
  },
  modalTitle: {
    fontSize: '18px',
    color: '#000',
    textAlign: 'center',
    marginBottom: '20px',
  },
  modalButton: {
    backgroundColor: '#914100',
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '20px',
    backgroundColor: 'lightgray',
  },
};

export default PasswordResetScreen;