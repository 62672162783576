import React, { useState } from 'react';
import Modal from 'react-modal';
import TermosBandaDocument from './TermosBandaDocument';
import TermosUsuarioDocument from './TermosUsuarioDocument';

interface TermsOfUseModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  userType: 'banda' | 'usuario';
}

const TermsOfUseModal: React.FC<TermsOfUseModalProps> = ({ isOpen, onClose, onAccept, userType }) => {
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 1) {
      setHasScrolledToBottom(true);
    }
  };

  const TermsDocument = userType === 'banda' ? TermosBandaDocument : TermosUsuarioDocument;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Termos de Uso"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '80%',
          maxHeight: '80%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <h2>Termos de Uso - {userType === 'banda' ? 'Tribhus para Artistas' : 'Tribhus para Usuários'}</h2>
      <div 
        style={{ 
          overflowY: 'scroll', 
          maxHeight: 'calc(80vh - 100px)',
          marginBottom: '20px',
          padding: '10px'
        }}
        onScroll={handleScroll}
      >
        <TermsDocument />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button onClick={onClose}>Fechar</button>
        <button 
          onClick={onAccept} 
          disabled={!hasScrolledToBottom}
          style={{ 
            opacity: hasScrolledToBottom ? 1 : 0.5,
            cursor: hasScrolledToBottom ? 'pointer' : 'not-allowed'
          }}
        >
          Aceitar Termos
        </button>
      </div>
    </Modal>
  );
};

export default TermsOfUseModal;