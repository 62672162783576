import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import axios from '../Services/axiosInterceptor'; 
import { useNavigate } from 'react-router-dom';
import { SlugContext } from '../context/SlugContext';
import { AuthContext } from '../context/AuthContext';
import MusicContext from '../context/MusicContext';
import debounce from 'lodash.debounce';
import TabBar from '../Components/TabBar'; 
import MiniPlayer from '../Components/MiniPlayer';

interface Banda {
    id_banda: number;
    nome_banda: string;
    slug: string;
    cidade: string;
    estado: string;
    url_icone_banda: string;
    generos: string[];
}

const responsiveStyles = `
  @media (min-width: 1920px) {
    .mainContent {
      gap: 40px;
    }
    .feedColumn {
      max-width: 800px;
    }
  }
  @media (max-width: 1200px) {
    .mainContent {
      gap: 10px;
    }
    .feedColumn {
      max-width: 100%;
    }
    .tabBarColumn {
      display: none;
    }
  }
`;

const StyleSheet = () => (
  <style>
    {responsiveStyles}
  </style>
);

const Bandas: React.FC = () => {
    const navigate = useNavigate();
    const { dispatch: slugDispatch } = useContext(SlugContext);
    const { state: authState } = useContext(AuthContext);
    const [bandas, setBandas] = useState<Banda[]>([]);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [carregando, setCarregando] = useState(false);
    const [temMaisBandas, setTemMaisBandas] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const searchQueryRef = useRef('');
    const isSearchingRef = useRef(false);
    const [showScrollTop, setShowScrollTop] = useState(false);
    const musicContext = useContext(MusicContext);
    const currentTrack = musicContext?.state.track;

    const { ref, inView } = useInView({
        threshold: 0,
    });

    const debouncedSearch = useCallback(
        debounce((value: string) => {
            searchQueryRef.current = value;
            isSearchingRef.current = true;
            setBandas([]);
            setPaginaAtual(1);
            setTemMaisBandas(true);
            carregarBandas(1, value, true);
        }, 300),
        []
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        debouncedSearch(value);
    };

    const carregarBandas = useCallback(async (pagina: number, busca: string = '', isNewSearch: boolean = false) => {
        if (carregando || (!temMaisBandas && !isNewSearch)) return;
        setCarregando(true);
    
        try {
            const endpoint = busca
                ? `https://tribhus.shop:5000/bands/search?page=${pagina}&pageSize=20&search=${busca}`
                : `https://tribhus.shop:5000/bands/all-bands?page=${pagina}&pageSize=20`;
    
            const response = await axios.get<{ bandas: Banda[] }>(endpoint, {
                headers: {
                    'Authorization': `Bearer ${authState.token}`
                }
            });
    
            if (response.data.bandas.length === 0) {
                setTemMaisBandas(false);
            } else {
                setBandas(prevBandas => {
                    const novasBandas = isNewSearch ? response.data.bandas : [...prevBandas, ...response.data.bandas];
                    const bandasUnicas = Array.from(new Set(novasBandas.map(b => b.id_banda)))
                        .map(id => novasBandas.find(b => b.id_banda === id)!);
                    return bandasUnicas;
                });
                setPaginaAtual(prevPagina => isNewSearch ? 2 : prevPagina + 1);
            }
        } catch (error) {
            console.error("Erro ao buscar bandas: ", error);
        } finally {
            setCarregando(false);
            if (isNewSearch) {
                isSearchingRef.current = false;
            }
        }
    }, [authState.token]);    

    useEffect(() => {
        if (authState.token) {
          carregarBandas(1);
        }
      }, [authState.token, carregarBandas]);

    useEffect(() => {
        if (inView && !carregando && temMaisBandas && !isSearchingRef.current) {
            carregarBandas(paginaAtual, searchQueryRef.current);
        }
    }, [inView, carregando, temMaisBandas, paginaAtual, carregarBandas]);

const handleBandaClick = useCallback((slug: string) => {
    slugDispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
    navigate('/TelaBandaPerfil');
}, [slugDispatch, navigate]);

    //voltar ao topo
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setShowScrollTop(true);
            } else {
                setShowScrollTop(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div style={styles.container}>
            <StyleSheet />

            {/* Contêiner para logo e ícone do usuário acima do TabBar */}
            <div style={styles.logoAndProfileContainer}>
            <img src={require('../assets/logotipo.png')} alt="Logotipo" style={styles.logo} />
              </div>

            <div style={styles.mainContent}>
                <div style={styles.tabBarColumn}>
                    <TabBar currentScreen="Bandas" />
                    {currentTrack && (
                        <div style={styles.miniPlayerContainer}>
                            <MiniPlayer />
                        </div>
                    )}
                </div>
                
                <div style={styles.feedColumn}>
                    <input
                        style={styles.searchInput}
                        placeholder="Buscar bandas..."
                        value={inputValue}
                        onChange={handleInputChange}
                    />

                    <div style={styles.listContainer}>
                        {bandas.map((item) => {
                            const generosLimitados = item.generos.slice(0, 2).join(', ');

                            return (
                                <div key={item.id_banda} style={styles.bandaCard} onClick={() => handleBandaClick(item.slug)}>
                                    {item.url_icone_banda ? (
                                        <img
                                            style={styles.bandaImage}
                                            src={item.url_icone_banda}
                                            alt={item.nome_banda}
                                        />
                                    ) : (
                                        <div style={styles.noImage}>
                                            <span style={styles.noImageText}>Sem imagem</span>
                                        </div>
                                    )}
                                    <span style={styles.bandaName}>{item.nome_banda}</span>
                                    <span style={styles.bandaGenero}>{generosLimitados}</span>
                                    <span style={styles.bandaLocation}>
                                        {item.cidade}
                                        {item.estado && `, ${item.estado}`}
                                    </span>
                                </div>
                            );
                        })}
                        {temMaisBandas && <div ref={ref} style={styles.loadingMore}>
                            {carregando ? 'Carregando mais bandas...' : 'Carregue mais'}
                        </div>}
                    </div>
                </div>
            </div>
            {showScrollTop && (
                <button onClick={scrollToTop} style={styles.scrollTopButton}>
                    ↑
                </button>
            )}
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        backgroundColor: '#151922',
        minHeight: '100vh',
        color: 'lightgray',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logoAndProfileContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        marginBottom: '20px',
    },
    logo: {
        width: '260px',
        height: 'auto',
    },
    profileIcon: {
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        border: '2px solid #ff6600',
    },
    mainContent: {
        display: 'flex',
        padding: '0 20px',
        width: '100%',
        maxWidth: '1200px',
        gap: '20px',
    },
    tabBarColumn: {
        width: '20%',
        paddingRight: '20px',
    },
    feedColumn: {
        flex: 1,
        overflowY: 'auto',
    },
    searchInput: {
        height: '40px',
        borderColor: '#666',
        borderWidth: '1px',
        borderRadius: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
        margin: '10px',
        color: '#000',
    },
    listContainer: {
        padding: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    bandaCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px',
        padding: '10px',
        backgroundColor: '#1f2937',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    bandaImage: {
        width: '150px',
        height: '150px',
        borderRadius: '5px',
    },
    noImage: {
        width: '150px',
        height: '150px',
        borderRadius: '5px',
        backgroundColor: '#000000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    noImageText: {
        color: '#FFFFFF',
        fontSize: '12px',
    },
    bandaName: {
        fontSize: '16px',
        color: 'lightgray',
        fontWeight: 'bold',
        marginTop: '5px',
    },
    bandaLocation: {
        fontSize: '10px',
        color: '#E0E0E0',
        marginTop: '5px',
    },
    bandaGenero: {
        fontSize: '10px',
        color: '#ff6600',
        marginTop: '5px',
    },
    loadingMore: {
        textAlign: 'center',
        padding: '20px',
        color: '#FFFFFF',
        width: '100%',
    },
    scrollTopButton: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#ff6600',
        color: 'lightgray',
        border: 'none',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        fontSize: '24px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        opacity: 0.7,
    },
};

export default Bandas;