import React, { useEffect, useState, useContext, CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../Services/axiosInterceptor'; 
import { useParams } from 'react-router-dom';
import { BandaContext } from '../context/BandaContext';

interface Music {
  id_musica: number;
  nome_musica: string;
  compositor: string;
  ano_lancamento: string;
  url_musica: string;
}

interface Album {
  id_album: number;
  id_banda: number;
  nome_album: string;
  ano_lancamento: number;
  url_capa_album: string;
  musicas: Music[];
}

const EditarAlbum: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const albumId = id || localStorage.getItem('id_album');
  const token = localStorage.getItem('token');
  const [nomeAlbum, setNomeAlbum] = useState("");
  const [isEditingAlbum, setIsEditingAlbum] = useState(false);
  const [anoLancamento, setAnoLancamento] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { state } = useContext(BandaContext);
  const bandaId = state.id_banda;
  const [album, setAlbum] = useState<Album>({
    id_album: 0,
    id_banda: 0,
    nome_album: "",
    ano_lancamento: 0,
    url_capa_album: "",
    musicas: [],
  });
  const [editingMusic, setEditingMusic] = useState<Music | null>();
  const [editedMusicName, setEditedMusicName] = useState("");

  useEffect(() => {
    const getAlbum = async () => {
      try {
        const albumRes = await axios.get(`https://tribhus.shop:5000/bands/detalhe/${albumId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const tracksRes = await axios.get(`https://tribhus.shop:5000/bands/album-tracks/${albumId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
 console.log(tracksRes.data);
        if (albumRes.data && tracksRes.data) {
          console.log(albumRes.data);
          console.log(tracksRes.data);
          setAlbum({ ...albumRes.data, musicas: tracksRes.data });
          setNomeAlbum(albumRes.data.nome_album);
          setAnoLancamento(albumRes.data.ano_lancamento.toString());
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAlbum();
  }, [albumId, token]);

  const handleAlbumChange = (e: React.ChangeEvent<HTMLInputElement>, setStateFunc: React.Dispatch<React.SetStateAction<string>>) => {
    setStateFunc(e.target.value);
  };

  const handleEditAlbum = () => {
    setIsEditingAlbum(true);
  };

  const handleSaveAlbum = async () => {
    const updatedAlbum = {
      nome_album: nomeAlbum,
      ano_lancamento: parseInt(anoLancamento, 10),
    };

    try {
      const response = await axios.put(
        `https://tribhus.shop:5000/bands/album/${albumId}`,
        updatedAlbum,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setAlbum({
          ...album,
          ...updatedAlbum,
          musicas: album.musicas,
        });
        setIsEditingAlbum(false);
        console.log("Album updated successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelAlbum = () => {
    setIsEditingAlbum(false);
    setNomeAlbum(album.nome_album);
    setAnoLancamento(album.ano_lancamento.toString());
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSaveCapaAlbum = async () => {
    if (!selectedFile) {
      console.error('No file selected for upload');
      return;
    }
    console.log(album);  // Adicione essa linha
    const formData = new FormData();
    formData.append('update-album', selectedFile);
  
    // Adicione o id_banda ao formData
    formData.append('id_banda', bandaId?.toString() ?? '');
    console.log('Album ID:', albumId);
console.log('Banda ID:', bandaId);

    try {
      const response = await axios.put(`https://tribhus.shop:5000/bands/album/${albumId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },      
      });
  
      if (response.status === 200) {
        setAlbum({ ...album, url_capa_album: response.data.url_capa_album, musicas: album.musicas });
        console.log('Album cover image updated successfully');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleEditMusic = (musica: Music) => {
    setEditedMusicName(musica.nome_musica);
    setEditingMusic(musica);
  };

  const handleMusicNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedMusicName(e.target.value);
  };

  // Adicione esta função para salvar as alterações na música
const handleSaveMusic = async () => {
  if (editingMusic) {
    const updatedMusic = { ...editingMusic, nome_musica: editedMusicName };
    try {
      const response = await axios.put(`https://tribhus.shop:5000/bands/edit-music/${editingMusic.id_musica}`, updatedMusic, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setAlbum({
          ...album,
          musicas: album.musicas.map(musica =>
            musica.id_musica === editingMusic.id_musica ? updatedMusic : musica
          ),
        });
        setEditingMusic(null);
      }
    } catch (error) {
      console.error(error);
    }
  }
};

// Adicione esta função para excluir uma música
const handleDeleteMusic = async (musicId: number) => {
  try {
    const response = await axios.delete(`https://tribhus.shop:5000/bands/delete-music/${musicId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      setAlbum({
        ...album,
        musicas: album.musicas.filter(musica => musica.id_musica !== musicId),
      });
    }
  } catch (error) {
    console.error(error);
  }
};
  
  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div style={styles.container}>
      {album && (
        <div style={styles.box1}>
          <div style={styles.albumImageContainer}>
            <img style={styles.albumImage} src={album.url_capa_album} alt={album.nome_album} />
  
            {/* Esconda o input real e adicione um ouvinte de eventos para quando o botão 'Escolher arquivo' for clicado */}
            <div style={{ display: 'none' }}>
              <input id="hiddenFileInput" style={styles.input} type="file" onChange={handleFileChange} />
            </div>
  
            <button style={styles.button} onClick={() => {const hiddenFileInput = document.getElementById('hiddenFileInput');
    if(hiddenFileInput) {
      hiddenFileInput.click();
    } }}>Trocar imagem do álbum</button>
<button style={styles.button} onClick={handleSaveCapaAlbum}>Salvar nova capa</button>
          </div>
          {isEditingAlbum ? (
            <div>
              <input style={styles.input} type="text" value={nomeAlbum} onChange={(e) => handleAlbumChange(e, setNomeAlbum)} />
              <input style={styles.input} type="text" value={anoLancamento} onChange={(e) => handleAlbumChange(e, setAnoLancamento)} />
              <button style={styles.button} onClick={handleSaveAlbum}>Salvar</button>
              <button style={styles.button} onClick={handleCancelAlbum}>Cancelar</button>
            </div>
          ) : (
            <div onClick={handleEditAlbum}>
              <h2 style={styles.title}>Nome do Álbum: {album.nome_album}</h2>
              <p style={styles.paragraph}>Ano de lançamento: {album.ano_lancamento}</p>
            </div>
          )}
        </div>
      )}
  
      <div style={styles.box2}>
        <h3 style={styles.title}>Músicas</h3>
        {album.musicas.map((musica, index) => {
          return (
            <div key={index} style={styles.musicContainer}>
              {editingMusic === musica ? (
                <>
                  <input
                    style={styles.input}
                    type="text"
                    value={editedMusicName}
                    onChange={handleMusicNameChange}
                  />
                  <button style={styles.button} onClick={handleSaveMusic}>Salvar</button>
                  <button style={styles.button} onClick={() => setEditingMusic(null)}>Cancelar</button>
                </>
              ) : (
                <>
                  <p style={styles.paragraph}>Nome da música: {musica.nome_musica}</p>
                  <button style={styles.button} onClick={() => handleEditMusic(musica)}>Alterar nome da música</button>
                  <button style={styles.button} onClick={() => handleDeleteMusic(musica.id_musica)}>Excluir música</button>
                </>
              )}
              <p style={styles.paragraph}>Compositor: {musica.compositor}</p>
              <p style={styles.paragraph}>Ano de lançamento: {musica.ano_lancamento}</p>
            </div>
          );
        })}
      </div>
      <button style={styles.button} onClick={handleClose}>Fechar</button>
    </div>
  );
};
const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#151922',
    padding: '20px'
  },
  albumImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px'
  },  
  title: {
    color: '#fff1e5',
    fontSize: '24px',
    marginBottom: '10px'
  },
  paragraph: {
    color: '#fff1e5',
    fontSize: '16px',
    marginBottom: '10px'
  },
  box1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#30343f',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px',
    width: '60%', // isto é apenas um exemplo, você pode ajustar o valor para a sua necessidade
    maxWidth: '800px' // limita a largura máxima, isso é útil para telas grandes
  },
  
  box2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#30343f',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px',
    width: '60%', // a mesma largura que a box1
    maxWidth: '800px' // a mesma largura máxima que a box1
  },  
  button: {
    backgroundColor: '#914100',
    padding: '15px 30px', 
    borderRadius: '5px',
    color: '#fff1e5',
    fontSize: '14px',
    border: 'none',
    cursor: 'pointer',
    marginTop: '20px',
    marginBottom: '20px'
  },
  input: {
    height: '40px',
    borderColor: '#999',
    borderWidth: '1px',
    borderRadius: '10px',
    padding: '10px',
    backgroundColor: '#fff',
    marginBottom: '15px',
    color: '#333',
    width: '100%'
  },
  albumImage: {
    width: '200px',
    height: '200px',
    objectFit: 'contain'
  },
  musicContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#3b3b3b',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px',
    width: '100%'
  }
};

export default EditarAlbum;
