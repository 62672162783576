import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import CustomAlertModal from './CustomAlertModal';

interface FamiliaProps {
  idBandaRecebe?: number;
  idUsuarioRecebe?: number;
  isOwnProfile: boolean;
  isFamilia: boolean;
  setIsFamilia: React.Dispatch<React.SetStateAction<boolean>>;
}

const Familia: React.FC<FamiliaProps> = ({ idBandaRecebe, idUsuarioRecebe, isOwnProfile, isFamilia, setIsFamilia }) => {
  //const [isFamilia, setIsFamilia] = useState(false);
  const [convitePendente, setConvitePendente] = useState(false);
  const [idConvite, setIdConvite] = useState<number | null>(null);
  const [isRemoveFamilyModalVisible, setRemoveFamilyModalVisible] = useState(false);
  const { state: { id_usuario, id_banda, token } } = useContext(AuthContext);
  const [modalMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isOwnProfile) {
      verificarFamilia();
    }
  }, [idBandaRecebe, idUsuarioRecebe, token, isOwnProfile]);

  const verificarFamilia = async () => {
    if (!token) {
      console.log('Nenhum token disponível - usuário provavelmente deslogado');
      setIsFamilia(false);
      setConvitePendente(false);
      setIdConvite(null);
      return;
    }

    if ((id_usuario && idUsuarioRecebe) || (idBandaRecebe && id_banda)) {
      console.log('Acesso não permitido para verificar família ou convite');
      setIsFamilia(false);
      setConvitePendente(false);
      setIdConvite(null);
      return;
    } else {
      try {
        const bandaId = id_banda ? id_banda : idBandaRecebe;
        const url = `https://tribhus.shop:5000/amigos/familia/verificar/${bandaId}/${idUsuarioRecebe}`;
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
        console.log('Resposta de verificarFamilia:', response.data);

        setIsFamilia(response.data.isFamilia);
        setConvitePendente(response.data.convitePendente);
        setIdConvite(response.data.idConvite);
      } catch (error) {
        console.error('Erro ao verificar família:', error);
        setIsFamilia(false);
        setConvitePendente(false);
        setIdConvite(null);
      }
    }
  };

  const adicionarFamilia = async () => {
    try {
      const response = await axios.post('https://tribhus.shop:5000/amigos/familia/adicionar', 
        { id_banda: idBandaRecebe },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsFamilia(true); // Atualiza o estado compartilhado para refletir que o usuário agora faz parte da família

      await axios.post('https://tribhus.shop:5000/notificacoes/familia', 
        { 
          id_usuario2: idUsuarioRecebe,
          id_banda2: idBandaRecebe,
          id_familia: response.data.id_familia 
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.error('Erro ao adicionar à família:', error);
    }
  };

  const removerDaFamilia = async () => {
    setRemoveFamilyModalVisible(false);
    try {
      const url = `https://tribhus.shop:5000/amigos/familia/remover/${idBandaRecebe}`;
      await axios.delete(url, {
        data: { id_convite: idConvite },
        headers: { Authorization: `Bearer ${token}` }
      });
      setIsFamilia(false); // Atualiza o estado compartilhado para refletir que o usuário não faz mais parte da família
    } catch (error) {
      console.error('Erro ao remover da família:', error);
    }
  };

  const aceitarFamilia = async () => {
    if (!idConvite) return;

    try {
      const response = await axios.post('https://tribhus.shop:5000/amigos/familia/aceitar', 
        {
          id_banda: idBandaRecebe,
          id_convite: idConvite
        }, 
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsFamilia(true);
      setConvitePendente(false);

      await axios.post('https://tribhus.shop:5000/notificacoes/familia/aceitarFamilia', 
        {
          id_usuario: id_usuario,
          id_banda: idBandaRecebe,
          id_familia: response.data.id_familia
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.error('Erro ao aceitar convite de família:', error);
    }
  };

  const enviarConviteParaUsuario = async () => {
    try {
      const responseConvite = await axios.post('https://tribhus.shop:5000/amigos/convites/enviar', {
        id_banda_envia: id_banda,
        id_usuario_recebe: idUsuarioRecebe
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const { id_convite } = responseConvite.data;

      if (id_convite) {
        setIdConvite(id_convite);
        setConvitePendente(true);

        await axios.post('https://tribhus.shop:5000/notificacoes/criarConvite', {
          id_convite: id_convite,
          id_usuario_recebe: idUsuarioRecebe,
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });

        console.log('Notificação de convite criada com sucesso.');
      } else {
        console.log('ID do Convite não encontrado na resposta.');
      }
    } catch (error) {
      console.error('Erro ao enviar convite ou ao criar notificação:', error);
    }
  };

  const cancelarConvite = async (idConvite: number | null) => {
    if (idConvite === null) {
      console.error('Tentativa de cancelar um convite sem um ID válido.');
      return;
    }
    try {
      const responseCancelamento = await axios.delete(`https://tribhus.shop:5000/amigos/convites/cancelar/${idConvite}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (responseCancelamento.status === 200) {
        setConvitePendente(false);
        setIdConvite(null);
        console.log('Convite cancelado com sucesso.');
      }
    } catch (error) {
      console.error('Erro ao cancelar o convite:', error);
    }
  };

  const confirmarRemocaoDaFamilia = async () => {
    setRemoveFamilyModalVisible(false);
    try {
          const url = `https://tribhus.shop:5000/amigos/familia/remover/${idBandaRecebe}`;
      console.log('Enviando token remover familia:', token, idBandaRecebe || idUsuarioRecebe);
      await axios.delete(url, {
        data: { id_convite: idConvite },
        headers: { Authorization: `Bearer ${token}` }
      });
      setIsFamilia(false);
      // Adicione aqui qualquer feedback de sucesso, se necessário
    } catch (error) {
      console.error('Erro ao remover da família:', error);
      // Adicione aqui qualquer feedback de erro, se necessário
    }
  };

  const handleFamiliaClick = () => {
    if (isOwnProfile && id_banda) {
      navigate('/FamiliaBanda');
    } else if (idUsuarioRecebe && !idBandaRecebe && id_banda) {
      if (!convitePendente && !isFamilia) {
        enviarConviteParaUsuario();
      } else if (convitePendente && !isFamilia) {
        cancelarConvite(idConvite);
    //  } else if (isFamilia) {
      //  removerDaFamilia();
      }
    } else {
      if (isFamilia) {
        removerDaFamilia();
      } else if (convitePendente) {
        aceitarFamilia();
      } else {
        adicionarFamilia();
      }
    }
  };
  let buttonText = 'Família';

  // Verifica se não é o próprio perfil
  if (!isOwnProfile) {
    if (idBandaRecebe && id_usuario) {
      buttonText = isFamilia ? 'Família' : convitePendente ? 'Aceitar Família' : '+Família';
    } else if (idUsuarioRecebe && id_banda) {
      buttonText = convitePendente ? 'Convidou' : isFamilia ? 'Familiar' : 'Convidar';
    }
  }
  
 return (
    <>
      {((id_banda || idBandaRecebe) && !(id_banda && idBandaRecebe && !isOwnProfile)) && (
        <button onClick={handleFamiliaClick} style={styles.button}>
          {buttonText}
        </button>
      )}
  
      <CustomAlertModal
        isVisible={isRemoveFamilyModalVisible}
        onDismiss={() => setRemoveFamilyModalVisible(false)}
        onConfirm={confirmarRemocaoDaFamilia}
        title="Confirmação"
        message={modalMessage}
      />
    </>
  );
};  

const styles: { [key: string]: React.CSSProperties } = {
  button: {
    backgroundColor: 'transparent', // Remove a cor de fundo
    color: '#FF6600', // Cor do texto
    border: 'none', // Remove a borda, se necessário
    padding: '10px 15px',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'color 0.3s', // Transição de cor no hover
  },
};

export default Familia;