import React from 'react';

interface CustomAlertModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

const CustomAlertModal: React.FC<CustomAlertModalProps> = ({ 
  isVisible, 
  onDismiss, 
  onConfirm, 
  title, 
  message 
}) => {
  if (!isVisible) return null;

  return (
    <div style={styles.modalOverlay} onClick={onDismiss}>
      <div style={styles.modalContainer} onClick={e => e.stopPropagation()}>
        <h2 style={styles.modalTitle}>{title}</h2>
        <p style={styles.modalMessage}>{message}</p>
        <div style={styles.buttonContainer}>
          <button onClick={onDismiss} style={styles.buttonCancel}>Cancelar</button>
          <button onClick={onConfirm} style={styles.buttonConfirm}>Confirmar</button>
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Adicionado zIndex alto para garantir que o modal fique sobre outros elementos
  },
  modalContainer: {
    backgroundColor: '#914100',
    padding: '20px',
    borderRadius: '10px',
    alignItems: 'center',
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
  },
  modalTitle: {
    color: 'lightgray',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
  },
  modalMessage: {
    color: 'lightgray',
    fontSize: '14px',
    textAlign: 'center',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  buttonCancel: {
    backgroundColor: '#fff',
    color: '#000',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  buttonConfirm: {
    backgroundColor: '#fff',
    color: '#000',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
  }
};

export default CustomAlertModal;