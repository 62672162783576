import React, { useState } from 'react';
import Familia from './Familia';
import Inscritos from './Inscritos';
import Amigos from './Amigos';
import Notificacoes from './Notificacoes';
import IconeMensagens from './IconeMensagens';

interface BotoesSociaisProps {
  idBandaRecebe?: number;
  idUsuarioRecebe?: number;
  isOwnProfile: boolean;
  idConversa?: number | null; 
}

const BotoesSociais: React.FC<BotoesSociaisProps> = ({ 
  idBandaRecebe, 
  idUsuarioRecebe, 
  isOwnProfile,
  idConversa
}) => {
  const [isFamilia, setIsFamilia] = useState(false);
  const [isInscrito, setIsInscrito] = useState(false);

  return (
    <div style={styles.container}>
      <Familia 
        idBandaRecebe={idBandaRecebe} 
        idUsuarioRecebe={idUsuarioRecebe}
        isOwnProfile={isOwnProfile} 
        isFamilia={isFamilia}
        setIsFamilia={setIsFamilia} // Passando o estado de isFamilia para o componente
      />
      <Inscritos 
        idBandaRecebe={idBandaRecebe}
        idUsuarioRecebe={idUsuarioRecebe}
        isOwnProfile={isOwnProfile}
        isFamilia={isFamilia} // Passando o estado de isFamilia para o componente
        isInscrito={isInscrito}
        setIsInscrito={setIsInscrito} // Passando o estado de isInscrito para o componente
      />
      <Amigos
        idUsuarioRecebe={idUsuarioRecebe}
        isOwnProfile={isOwnProfile}
      />
      <Notificacoes
        idBandaRecebe={idBandaRecebe}
        idUsuarioRecebe={idUsuarioRecebe}
        isOwnProfile={isOwnProfile}
      />
<IconeMensagens
  idBandaRecebe={idBandaRecebe}
  idUsuarioRecebe={idUsuarioRecebe}
  isOwnProfile={isOwnProfile}
  idConversa={idConversa}
/>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start', // Mantém os botões alinhados à esquerda
    alignItems: 'center',
    gap: '90px',
    margin: '20px 0',
    paddingLeft: '40px', // Adiciona espaçamento da margem esquerda
    marginLeft: '380px',
  },
};

export default BotoesSociais;
