import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { AuthContext } from '../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { 
  faFacebookSquare, 
  faYoutube, 
  faInstagram, 
  faWhatsapp, 
  faTwitter, 
  faLinkedin, 
  faTiktok, 
  faPinterest
} from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import SocialMediaInput from './SocialMediaInput'; 

interface Band {
    id_banda: number;
    nome_banda: string;
    cnpj?: string;
    nome_responsavel: string;
    nasc_responsavel: string;
    data_inicio?: string;
    telefone?: string;
    descricao?: string;
    url_capa_banda?: string;
    url_icone_banda?: string;
    cidade: string;
    estado?: string;
    id_musica_apresentacao?: number;
    url_facebook?: string;
    url_instagram?: string;
    url_spotify?: string;
    sobrenome_responsavel: string;
    auth_id: number;
    slug?: string;
    [key: `url_${string}`]: string | undefined;
}

interface RedeSocial {
    id_rede_social: number;
    nome: string;
    icone: string;
}

interface SocialMedia {
    nome: string;
    url: string;
    id_rede: number;
    icone: string
}

interface SocialMediaData {
    id_rede: number;
    nome: string;
    icone: string;
    url: string;
}

interface SobreProps {
    band: Band;
    updateDescription: (description: string) => void;
}

const Sobre: React.FC<SobreProps> = ({ band, updateDescription }) => {
    const [description, setDescription] = useState(band.descricao || '');
    const [socialMediaOptions, setSocialMediaOptions] = useState<RedeSocial[]>([]);
    const [existingSocialMediaLinks, setExistingSocialMediaLinks] = useState<{ [key: string]: SocialMedia }>({});
    const [editingSocialMediaId, setEditingSocialMediaId] = useState<number | null>(null);
    const [socialMediaUrl, setSocialMediaUrl] = useState('');
    const [formData, setFormData] = useState({ telefone: '', whatsappLink: '' });
    const { state: authState } = useContext(AuthContext);

    const iconMap: { [key: string]: IconProp } = {
        'facebook': faFacebookSquare,
        'youtube': faYoutube,
        'instagram': faInstagram,
        'whatsapp': faWhatsapp,
        'x': faTwitter,
        'linkedin': faLinkedin,
        'tiktok': faTiktok,
        'pinterest': faPinterest
    };

    const getIcon = (iconName: string): IconProp => {
        return iconMap[iconName.toLowerCase()] || faQuestionCircle;
    };

    const fetchSocialMediaOptions = async () => {
        try {
            const response = await axios.get<RedeSocial[]>('https://tribhus.shop:5000/redesocial/band/social-media', {
                headers: {
                    Authorization: `Bearer ${authState.token}`
                }
            });
            setSocialMediaOptions(response.data);
        } catch (error) {
            console.error('Erro ao buscar redes sociais:', error);
        }
    };

    const fetchExistingSocialMediaLinks = async () => {
        try {
            const url = `https://tribhus.shop:5000/redesocial/band/${authState.id_banda}/social-medias`;
            const response = await axios.get<SocialMediaData[]>(url, {
                headers: {
                    Authorization: `Bearer ${authState.token}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = response.data;

            const linksMap = data.reduce<{ [key: string]: SocialMedia }>((acc, item) => {
                acc[normalizeSocialMediaName(item.nome)] = {
                    nome: item.nome,
                    url: item.url,
                    id_rede: item.id_rede,
                    icone: item.icone
                };
                return acc;
            }, {});

            setExistingSocialMediaLinks(linksMap);
        } catch (error) {
            console.error("Erro ao buscar links das redes sociais da banda:", error);
        }
    };

    useEffect(() => {
        fetchSocialMediaOptions();
        fetchExistingSocialMediaLinks();
    }, []);

    const handleSave = async () => {
        if (band.id_banda) {
            try {
                await axios.put(`https://tribhus.shop:5000/bands/profile/${band.id_banda}`, {
                    descricao: description
                }, {
                    headers: {
                        Authorization: `Bearer ${authState.token}`
                    }
                });
                alert('Descrição atualizada com sucesso!');
                updateDescription(description);
            } catch (error) {
                console.error('Erro ao atualizar descrição:', error);
                alert('Erro ao atualizar descrição.');
            }
        } else {
            alert('Erro: ID do usuário não encontrado.');
        }
    };
    const buildFullSocialMediaUrl = (socialMediaName: string, slug: string): string => {
        const prefixes: { [key: string]: string } = {
            'facebook': 'https://facebook.com/',
            'youtube': 'https://youtube.com/@',
            'instagram': 'https://instagram.com/',
            'x': 'https://twitter.com/',
            'linkedin': 'https://linkedin.com/in/',
            'tiktok': 'https://tiktok.com/@',
            'pinterest': 'https://pinterest.com/'
        };
    
        const prefix = prefixes[socialMediaName.toLowerCase()] || '';
        return slug.startsWith(prefix) ? slug : prefix + slug;
    };
    
    const handleAddSocialMedia = async (idRedeSocial: number, slug: string, idRede?: number) => {
        console.log('Adicionando ou atualizando rede social:', idRedeSocial, slug, idRede);
        try {
            const urlEndpoint = 'https://tribhus.shop:5000/redesocial/band/add-social-media';
            const socialMediaName = socialMediaOptions.find(option => option.id_rede_social === idRedeSocial)?.nome.toLowerCase() ?? 'generic';
            const cleanSlug = slug.replace(new RegExp(`^${getPrefix(socialMediaName)}`), '');
            const fullUrl = buildFullSocialMediaUrl(socialMediaName, cleanSlug);
    
            const response = await axios.post(urlEndpoint, {
                id_banda: band.id_banda,
                id_rede_social: idRedeSocial,
                url: fullUrl,
                id_rede: idRede
            }, {
                headers: {
                    Authorization: `Bearer ${authState.token}`
                }
            });
    
            if (response.data) {
                fetchExistingSocialMediaLinks();
            }
    
            alert(idRede ? 'Rede social atualizada com sucesso!' : 'Rede social adicionada com sucesso!');
        } catch (error) {
            console.error('Erro ao adicionar ou atualizar rede social:', error);
            alert('Erro ao adicionar ou atualizar rede social. Por favor, verifique sua conexão e tente novamente.');
        }
    };
    
    const handleRemoveSocialMedia = async (idRedeSocial: number) => {
        try {
            await axios.delete(`https://tribhus.shop:5000/redesocial/band/remove-social-media/${idRedeSocial}`, {
                headers: {
                    Authorization: `Bearer ${authState.token}`
                }
            });
    
            alert('Rede social removida com sucesso!');
            setSocialMediaUrl('');
            clearWhatsAppFields();
            fetchExistingSocialMediaLinks();
        } catch (error) {
            console.error('Erro ao remover rede social:', error);
            alert('Erro ao remover rede social.');
        }
    };
    
    const getPrefix = (type: string): string => {
        const prefixes: { [key: string]: string } = {
            'facebook': 'https://facebook.com/',
            'youtube': 'https://youtube.com/@',
            'instagram': 'https://instagram.com/',
            'x': 'https://twitter.com/',
            'linkedin': 'https://linkedin.com/in/',
            'tiktok': 'https://tiktok.com/@',
            'pinterest': 'https://pinterest.com/'
        };
        return prefixes[type.toLowerCase()] || '';
    };
    
    const clearWhatsAppFields = () => {
        setFormData(prev => ({ ...prev, telefone: '', whatsappLink: '' }));
    };
    
    const formatPhone = (phone: string): string => {
        let numbers = phone.replace(/\D/g, '');
        numbers = numbers.slice(0, 11);
    
        if (numbers.length <= 2) {
            return `(${numbers}`;
        } else if (numbers.length <= 7) {
            return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 7)}`;
        } else {
            return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 7)}-${numbers.slice(7)}`;
        }
    };
    
    const createWhatsAppLink = (phone: string): string => {
        const formattedPhone = phone.replace(/\D/g, '');
        return `https://wa.me/55${formattedPhone}`;
    };
    
    const handlePhoneChange = (value: string) => {
        const formattedPhone = formatPhone(value);
        const whatsAppLink = createWhatsAppLink(formattedPhone);
        setFormData(prev => ({ ...prev, telefone: formattedPhone, whatsappLink: whatsAppLink }));
    };
    
    const extractPhoneNumberFromLink = (whatsAppLink: string): string => {
        const match = whatsAppLink.match(/wa.me\/55(\d+)/);
        return match ? match[1] : '';
    };
    
    const formatPhoneNumber = (phoneNumber: string): string => {
        if (phoneNumber.length <= 2) {
            return `(${phoneNumber}`;
        } else if (phoneNumber.length <= 7) {
            return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`;
        } else if (phoneNumber.length > 7) {
            return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 7)}-${phoneNumber.slice(7)}`;
        }
        return phoneNumber;
    };
    
    const normalizeSocialMediaName = (name: string): string => {
        return name.replace(/\s+/g, '').toLowerCase();
    };

    const handleCancel = () => {
        // Redefine os estados para os valores que foram recebidos nas props
        setDescription(band.descricao || '');
        setExistingSocialMediaLinks({});
        setSocialMediaUrl('');
        setEditingSocialMediaId(null);
        setFormData({ telefone: '', whatsappLink: '' });
    
        // Alternativamente, se você quiser recarregar a página completamente:
        // window.location.reload();
    };    
    const styles = {
        formContainer: {
            padding: '20px',
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 700,
            maxWidth: '100%',
            margin: '0 auto',
            backgroundColor: '#1E1E1E',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
        input: {
            height: '120px',
            borderColor: 'gray',
            borderWidth: '1px',
            marginBottom: '10px',
            padding: '10px',
            width: '100%',  // Ocupa toda a largura disponível do contêiner pai
            borderRadius: '20px',
            color: '#fff',
            backgroundColor: '#151922',
            boxSizing: 'border-box' as const,
        },
        buttonSaveDescription: {
            backgroundColor: '#FF6600',  // Cor de fundo laranja para salvar a descrição
            padding: '10px 20px',
            borderRadius: '10px',
            border: 'none',
            cursor: 'pointer',
            color: '#fff',
            fontSize: '16px',
            marginTop: '10px',
        },
        button: {
            backgroundColor: 'transparent', // Sem cor de fundo
            border: 'none',
            color: '#FF6600', // Cor do texto
            fontSize: '16px',
            cursor: 'pointer',
            padding: '10px 20px',
            marginBottom: '20px',
        },
        buttonCancel: {
            marginLeft: '10px', // Espaço entre o botão "Salvar" e "Cancelar"
        },
        sectionTitle: {
            fontSize: '18px',
            color: '#fff',
            marginTop: '20px',
            alignSelf: 'flex-start', // Alinha o título das redes sociais à esquerda
        },
        socialMediaContainer: {
            width: '100%',  // Garante que o contêiner das redes sociais ocupe toda a largura do pai
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'flex-start', // Alinha o conteúdo dentro à esquerda
        },
        socialMediaItem: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
            cursor: 'pointer',
        },
        socialMediaIcon: {
            marginRight: '10px',
            color: '#fff',
        },
        socialMediaName: {
            color: '#fff',
        },
        inputContainer: {
            marginTop: '10px',
            width: '100%',  // Garante que o contêiner do input ocupe toda a largura disponível
            alignSelf: 'flex-start', // Alinha o contêiner de input de redes sociais à esquerda
        },
        inputUrl: {
            height: '40px',
            borderColor: 'gray',
            borderWidth: '1px',
            padding: '10px',
            borderRadius: '5px',
            color: '#fff',
            backgroundColor: '#151922',
            marginBottom: '10px',
            width: '100%',  // Garante que o input ocupe toda a largura disponível do contêiner pai
            boxSizing: 'border-box' as const,
        },
    };
    
    return (
        <div style={styles.formContainer}>
            <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Editar sua descrição"
                style={styles.input}
            />
            <button style={styles.buttonSaveDescription} onClick={handleSave}>Salvar</button>
            
            <h3 style={styles.sectionTitle}>Adicionar ou Editar Redes Sociais</h3>
            {socialMediaOptions.length === 0 && (
                <p style={{...styles.sectionTitle, fontSize: '12px', color: '#FF6600'}}>
                    Nenhuma rede social cadastrada. Adicione uma nova!
                </p>
            )}
            
            <div style={styles.socialMediaContainer}>
                {socialMediaOptions.map((option) => (
                    <div key={option.id_rede_social}>
                        <div 
                            style={styles.socialMediaItem}
                            onClick={() => {
                                if (editingSocialMediaId === option.id_rede_social) {
                                    setEditingSocialMediaId(null);
                                    setSocialMediaUrl('');
                                    setFormData({ ...formData, telefone: '' });
                                } else {
                                    setEditingSocialMediaId(option.id_rede_social);
                                    const linkDetails = existingSocialMediaLinks[normalizeSocialMediaName(option.nome)] || {};
                                    if (option.nome.toLowerCase() === 'whatsapp') {
                                        const rawPhoneNumber = extractPhoneNumberFromLink(linkDetails.url || '');
                                        const formattedPhone = formatPhoneNumber(rawPhoneNumber);
                                        setFormData({ ...formData, telefone: formattedPhone, whatsappLink: linkDetails.url || '' });
                                    } else {
                                        setSocialMediaUrl(linkDetails.url || '');
                                    }
                                }
                            }}
                        >
                            <FontAwesomeIcon 
                                icon={getIcon(option.nome)}
                                style={styles.socialMediaIcon}
                            />
                            <span style={styles.socialMediaName}>{option.nome}</span>
                        </div>
    
                        {editingSocialMediaId === option.id_rede_social && (
                            <div style={styles.inputContainer}>
                                {option.nome.toLowerCase() === 'whatsapp' ? (
                                    <input
                                        style={styles.inputUrl}
                                        value={formData.telefone}
                                        onChange={(e) => handlePhoneChange(e.target.value)}
                                        placeholder="(XX) XXXXX-XXXX"
                                    />
                                ) : (
                                    <SocialMediaInput
                                        type={option.nome}
                                        value={socialMediaUrl}
                                        onChange={setSocialMediaUrl}
                                    />
                                )}
                                <button 
                                    style={styles.button} 
                                    onClick={() => {
                                        const linkDetails = existingSocialMediaLinks[normalizeSocialMediaName(option.nome)] || {};
                                        handleAddSocialMedia(
                                            option.id_rede_social, 
                                            option.nome.toLowerCase() === 'whatsapp' ? formData.whatsappLink : socialMediaUrl, 
                                            linkDetails.id_rede
                                        );
                                    }}
                                >
                                    Salvar
                                </button>
                                <button 
                                    style={{...styles.button, marginLeft: '10px', color: '#ff4444'}} 
                                    onClick={() => {
                                        const linkDetails = existingSocialMediaLinks[normalizeSocialMediaName(option.nome)] || {};
                                        if (linkDetails && linkDetails.id_rede) {
                                            handleRemoveSocialMedia(linkDetails.id_rede);
                                            if (option.nome.toLowerCase() === 'whatsapp') {
                                                clearWhatsAppFields();
                                            }
                                        } else {
                                            console.log('ID da rede social não encontrado para: ', option.nome);
                                        }
                                    }}
                                >
                                    Excluir
                                </button>
                                <button 
                style={{ ...styles.button, ...styles.buttonCancel }} 
                onClick={handleCancel} // Função de cancelamento que você pode definir
            >
                Cancelar
            </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
    };

    export default Sobre;