import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

interface AudioConverterProps {
  onConversionComplete: (convertedFile: File) => void;
  songName: string;
  onProgress: (progress: number) => void;
  file: File | null; // New prop to receive the file from parent
}

export interface AudioConverterRef {
  convertToAAC: () => Promise<void>;
  reset: () => void;
}

const AudioConverter = forwardRef<AudioConverterRef, AudioConverterProps>((props, ref) => {
  const { onConversionComplete, songName, onProgress, file } = props;
  const [ffmpeg, setFfmpeg] = useState<FFmpeg | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [loadError, setLoadError] = useState<string | null>(null);

  useEffect(() => {
    const loadFFmpeg = async () => {
      try {
        const ffmpegInstance = new FFmpeg();
        console.log('Iniciando carregamento do FFmpeg');
        await ffmpegInstance.load();
        console.log('FFmpeg carregado com sucesso');
        setFfmpeg(ffmpegInstance);
        setIsReady(true);
      } catch (error) {
        console.error('Erro ao carregar FFmpeg:', error);
        setLoadError('Falha ao carregar o conversor de áudio. Por favor, recarregue a página.');
        setIsReady(false);
      }
    };

    loadFFmpeg();
  }, []);

  const convertToAAC = async () => {
    if (!ffmpeg || !file) {
        console.error('FFmpeg ou arquivo selecionado não disponível.');
        return;
    }

    setIsConverting(true);
    
    try {
        console.log('Iniciando a conversão para AAC...');
        await ffmpeg.writeFile('input', await fetchFile(file));

        ffmpeg.on('progress', ({ progress }) => {
            onProgress(progress * 100);
            console.log(`Progresso da conversão: ${progress * 100}%`);
        });

        await ffmpeg.exec([
            '-i', 'input',
            '-c:a', 'aac',
            '-b:a', '128k',
            'output.aac'
        ]);

        const data = await ffmpeg.readFile('output.aac');
        console.log('Leitura do arquivo de saída concluída.');

        const safeFileName = songName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        const blob = new Blob([data], { type: 'audio/aac' });
        const convertedFile = new File([blob], `${safeFileName}.aac`, { type: 'audio/aac' });

        console.log('Arquivo convertido criado:', convertedFile);
        onConversionComplete(convertedFile);
    } catch (error) {
        console.error('Erro na conversão:', error);
        setLoadError('Erro ao converter o áudio. Por favor, tente novamente.');
    } finally {
        setIsConverting(false);
    }
};

  const reset = () => {
    setIsConverting(false);
    setLoadError(null);
  };

  useImperativeHandle(ref, () => ({
    convertToAAC,
    reset
  }));

  const styles = {
    container: {
      color: 'lightgray',
    },
    error: {
      color: 'red',
    },
  };

  if (loadError) return <div style={styles.error}>{loadError}</div>;
  if (!isReady) return <div style={styles.container}>Carregando conversor de áudio...</div>;

  return (
    <div style={styles.container}>
      {file && <p>Música selecionada: {file.name}</p>}
      {isConverting && <div>Salvando sua música, por favor aguarde...</div>}
    </div>
  );
});

export default AudioConverter;