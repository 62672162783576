import React, { useReducer, createContext, Dispatch, ReactNode } from 'react';

interface State {
  auth_id: number | null;
  id_banda: number | null;
  nome_banda: string;
  cnpj: string;
  nome_responsavel: string;
  sobrenome_responsavel: string;
  nasc_responsavel: string;
  slug: string;
  data_inicio: string;
  telefone: string;
  descricao: string;
  url_capa_banda: string;
  url_icone_banda: string;
  cidade: string;
  estado: string;
  url_facebook: string;
  url_instagram: string;
  url_spotify: string;
  email: string;
  senha: string;
  genres: string[];
  selectedGenres: number[];
  error: string;
}

const initialState: State = {
  auth_id: null,
  id_banda: null,
  nome_banda: '',
  cnpj: '',
  nome_responsavel: '',
  sobrenome_responsavel: '',
  nasc_responsavel: '',
  slug: '',
  data_inicio: '',
  telefone: '',
  descricao: '',
  url_capa_banda: '',
  url_icone_banda: '',
  cidade: '',
  estado: '',
  url_facebook: '',
  url_instagram: '',
  url_spotify: '',
  email: '',
  senha: '',
  genres: [],
  selectedGenres: [],
  error: '',
};

type Action =
  | { type: 'SET_BANDA_AUTH_ID'; auth_id: number }
  | { type: 'SET_FORM'; field: keyof State; value: string }
  | { type: 'SET_BANDA_ID'; id: number }
  | { type: 'SET_SELECTED_GENRES'; genres: number[] }
  | { type: 'SET_ERROR'; error: string }
  | { type: 'ADD_SELECTED_GENRE'; id: number } 
  | { type: 'REMOVE_SELECTED_GENRE'; id: number }
  | { type: 'SET_BANDA'; banda: State }
  | { type: 'UPDATE_DESCRICAO_BANDA'; descricao: string }; // Nova linha adicionada

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_BANDA':
      return { ...state, ...action.banda };
    case 'UPDATE_DESCRICAO_BANDA':
      return { ...state, descricao: action.descricao };
    case 'SET_BANDA_AUTH_ID':
      return { ...state, auth_id: action.auth_id };  
    case 'SET_FORM':
      const newState = { ...state, [action.field]: action.value };
      return newState;
    case 'SET_BANDA_ID':
      return { ...state, id_banda: action.id };
    case 'SET_SELECTED_GENRES':
      return { ...state, selectedGenres: action.genres };  // action.genres é agora number[]
    case 'SET_ERROR':
      return { ...state, error: action.error };
    case 'ADD_SELECTED_GENRE':
      return { ...state, selectedGenres: [...state.selectedGenres, action.id] };
    case 'REMOVE_SELECTED_GENRE':
      return { ...state, selectedGenres: state.selectedGenres.filter(id => id !== action.id) };
    default:
      return state;
  }
};

interface ContextProps {
  state: State;
  dispatch: Dispatch<Action>;
}

export const BandaContext = createContext<ContextProps>({
  state: initialState,
  dispatch: () => null,
});

interface BandaProviderProps {
  children: ReactNode;
}

export const BandaProvider: React.FC<BandaProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(state)
  return <BandaContext.Provider value={{ state, dispatch }}>{children}</BandaContext.Provider>;
};
