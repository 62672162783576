import React, { useReducer, createContext, Dispatch, ReactNode } from 'react';

interface State {
  auth_id: number | null;
  id_usuario: number | null;
  id_banda: number | null;
  email: string;
  senha: string;
  nome: string;
  url_capa_usuario: string;
  sobrenome: string;
  slug: string;
  descricao: string;
  genero_sexo: string;
  outro_genero: string;
  data_nascimento: string;
  genres: string[];
  selectedGenres: string[]; // Campo para armazenar os gêneros selecionados
  error: string; // Campo para armazenar mensagens de erro
}

const initialState: State = {
  auth_id: null,
  id_usuario: null,
  id_banda: null,
  email: '',
  senha: '',
  nome: '',
  url_capa_usuario: '',
  sobrenome: '',
  slug: '',
  descricao: '',
  genero_sexo: '',
  outro_genero: '',
  data_nascimento: '',
  genres: [],
  selectedGenres: [], // Valor inicial como array vazio
  error: '', // Valor inicial como string vazia
};

type Action = 
  | { type: 'SET_AUTH_ID'; auth_id: number } 
  | { type: 'SET_FORM'; field: keyof State; value: string }
  | { type: 'SET_USER_ID'; id: number }
  | { type: 'SET_BAND_ID'; id: number }
  | { type: 'SET_USER_COVER_URL'; url: string }
  | { type: 'SET_USER_PROFILE'; id: number; nome: string; sobrenome: string }
  | { type: 'SET_GENRES'; genres: string[] }
  | { type: 'SET_SELECTED_GENRES'; genres: string[] } // Ação para definir os gêneros selecionados
  | { type: 'SET_ERROR'; error: string } // Ação para definir mensagens de erro
  | { type: 'UPDATE_DESCRICAO_USUARIO'; descricao: string }; // Ação para atualizar descrição do usuário

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'UPDATE_DESCRICAO_USUARIO':
      return { ...state, descricao: action.descricao };
    case 'SET_AUTH_ID':
      return { ...state, auth_id: action.auth_id };
    case 'SET_FORM':
      const newState = { ...state, [action.field]: action.value };
      return newState;
    case 'SET_USER_ID':
      return { ...state, id_usuario: action.id };
    case 'SET_BAND_ID':
        return { ...state, id_banda: action.id };
        case 'SET_USER_COVER_URL':
          return { ...state, url_capa_usuario: action.url };
    case 'SET_USER_PROFILE':
            return { ...state, id_usuario: action.id, nome: action.nome, sobrenome: action.sobrenome };      
    case 'SET_GENRES':
      return { ...state, genres: action.genres };
    case 'SET_SELECTED_GENRES': // Manipula a ação de definir gêneros selecionados
      return { ...state, selectedGenres: action.genres };
    case 'SET_ERROR': // Manipula a ação de definir mensagens de erro
      return { ...state, error: action.error };
    default:
      return state;
  }
};

interface ContextProps {
  state: State;
  dispatch: Dispatch<Action>;
}

export const RegisterContext = createContext<ContextProps>({
  state: initialState,
  dispatch: () => null,
});

interface RegisterProviderProps {
  children: ReactNode;
}

export const RegisterProvider: React.FC<RegisterProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <RegisterContext.Provider value={{ state, dispatch }}>
      {children}
    </RegisterContext.Provider>
  );
};
