import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { AuthContext } from '../context/AuthContext';

interface Band {
    id_banda: number;
    nome_banda: string;
    cnpj?: string;
    nome_responsavel: string;
    nasc_responsavel: string;
    data_inicio?: string;
    telefone?: string;
    descricao?: string;
    url_capa_banda?: string;
    url_icone_banda?: string;
    cidade: string;
    estado?: string;
    id_musica_apresentacao?: number;
    url_facebook?: string;
    url_instagram?: string;
    url_spotify?: string;
    sobrenome_responsavel: string;
    auth_id: number;
    slug?: string;
    [key: `url_${string}`]: string | undefined;
}

interface Genre {
    id_genero: number;
    nome_genero: string;
    isSelected?: boolean;
}

interface GenerosEditBandProps {
    band: Band;
    setBand: React.Dispatch<React.SetStateAction<Band>>;
}

const GenerosEditBand: React.FC<GenerosEditBandProps> = ({ band }) => {
    const [genres, setGenres] = useState<Genre[]>([]);
    const [filteredGenres, setFilteredGenres] = useState<Genre[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const { state: authState } = useContext(AuthContext);
    const [selectedGenres, setSelectedGenres] = useState<string[]>([]);

    useEffect(() => {
        const fetchAllGenres = async () => {
            let allGenres: Genre[];
            let bandGenres: string[];

            try {
                console.log('Fetching all genres with token:', authState.token);
                const allGenresResponse = await axios.get<Genre[]>('https://tribhus.shop:5000/users/genres', {
                    headers: { Authorization: `Bearer ${authState.token}` }
                });
                allGenres = allGenresResponse.data;
            } catch (error) {
                console.error("Erro ao buscar todos os gêneros:", error);
                return;
            }

            try {
                console.log(`Fetching genres for band ${band.id_banda} with token:`, authState.token);
                const bandGenresResponse = await axios.get<{ generos: string[] }>(`https://tribhus.shop:5000/bands/banda/${band.id_banda}`, {
                    headers: { Authorization: `Bearer ${authState.token}` }
                });
                bandGenres = bandGenresResponse.data.generos;
                setSelectedGenres(bandGenres);
            } catch (error) {
                console.error(`Erro ao buscar gêneros do usuário ${band.id_banda}:`, error);
                return;
            }

            if (allGenres && bandGenres) {
                const markedGenres = allGenres.map(genre => ({
                    ...genre,
                    isSelected: bandGenres.includes(genre.nome_genero)
                }));
                setFilteredGenres(markedGenres);
                setGenres(markedGenres);
            }
        };
        fetchAllGenres();
    }, [band.id_banda, authState.token]);

    useEffect(() => {
        const lowerSearchTerm = searchTerm.toLowerCase();
        setFilteredGenres(genres.filter(genre => genre.nome_genero.toLowerCase().includes(lowerSearchTerm)));
    }, [searchTerm, genres]);

    const handleSelectGenre = (selectedGenreName: string) => {
        setFilteredGenres(prevGenres => prevGenres.map(genre => {
            if (genre.nome_genero === selectedGenreName) {
                const isSelected = !genre.isSelected;
                setSelectedGenres(prevSelected => {
                    if (isSelected) {
                        if (!prevSelected.includes(selectedGenreName)) {
                            return [...prevSelected, selectedGenreName];
                        }
                    } else {
                        return prevSelected.filter(name => name !== selectedGenreName);
                    }
                    return prevSelected;
                });
                return { ...genre, isSelected };
            }
            return genre;
        }));
    };    

    const handleSave = async () => {
        setIsUpdating(true);
        try {
            console.log('Saving genres for band:', band.id_banda);
            console.log('Selected genres:', selectedGenres);
            console.log('Token na hora:', authState.token);
            const response = await axios.put(`https://tribhus.shop:5000/bands/update/${band.id_banda}/genres`, {
                generos: selectedGenres
            }, {
                headers: {
                    Authorization: `Bearer ${authState.token}`
                }
            });

            if (response.status === 200) {
                alert('Gêneros atualizados com sucesso!');
            } else {
                throw new Error('Falha ao salvar as alterações');
            }
        } catch (error) {
            console.error('Erro ao atualizar gêneros:', error);
            alert('Erro ao atualizar gêneros.');
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <div style={styles.container}>
            <input
                style={styles.searchBox}
                placeholder="Busque seus gêneros preferidos"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div style={styles.genresContainer}>
                {filteredGenres.map((genre, index) => (
                    <button
                        key={index}
                        onClick={() => handleSelectGenre(genre.nome_genero)}
                        style={{
                            ...styles.genreBox,
                            ...(genre.isSelected ? styles.selectedGenreBox : {})
                        }}
                    >
                        {genre.nome_genero}
                    </button>
                ))}
            </div>
            <button
                style={styles.nextButton}
                onClick={handleSave}
                disabled={isUpdating}
            >
                {isUpdating ? 'Salvando...' : 'Salvar Alterações'}
            </button>
        </div>
    );
};

const styles = {
    container: {
        padding: '10px',
    },
    searchBox: {
        height: '40px',
        borderColor: 'gray',
        borderWidth: '1px',
        borderRadius: '5px',
        paddingLeft: '10px',
        marginBottom: '30px',
        width: '90%',
        alignSelf: 'center',
        color: '#fff',
        backgroundColor: '#151922',
    },
    genresContainer: {
        display: 'flex',
        flexWrap: 'wrap' as 'wrap',
        justifyContent: 'space-between',
    },
    genreBox: {
        border: '1px solid #fff',
        borderRadius: '15px',
        backgroundColor: '#151922',
        padding: '10px',
        marginBottom: '20px',
        width: 'calc(33.33% - 15px)',
        textAlign: 'center' as 'center',
        cursor: 'pointer',
        color: '#fff',
    },
    selectedGenreBox: {
        backgroundColor: 'orange',
    },
    nextButton: {
        backgroundColor: '#ff6347',
        borderRadius: '20px',
        padding: '10px',
        marginTop: '20px',
        textAlign: 'center' as 'center',
        cursor: 'pointer',
        color: '#fff',
        fontSize: '18px',
        border: 'none',
        width: '100%',
    },
};

export default GenerosEditBand;