import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../Services/axiosInterceptor';
import { AuthContext } from '../context/AuthContext';
import messageIcon from '../assets/Message.png';
import unreadMessageIcon from '../assets/UnreadMessage.png';

interface IconeMensagensProps {
  idUsuarioRecebe?: number;
  idBandaRecebe?: number;
  isOwnProfile: boolean;
  idConversa?: number | null;
}

interface Notificacao {
  id_notificacao: number;
  status: 'lida' | 'não lida';
  tem_mensagem_nao_lida: boolean;
  // Adicione outros campos conforme necessário
}

const IconeMensagens: React.FC<IconeMensagensProps> = ({ 
  idUsuarioRecebe, 
  idBandaRecebe, 
  isOwnProfile,
  idConversa // Adicionado idConversa como prop
}) => {
  const [temMensagensNaoLidas, setTemMensagensNaoLidas] = useState(false);
  const { state: authState } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authState.token && (idUsuarioRecebe || idBandaRecebe)) {
      fetchNotificacoes();
    }
  }, [authState.token, idUsuarioRecebe, idBandaRecebe]);

  const fetchNotificacoes = async () => {
    if (!authState.token) {
      console.log('Nenhum token disponível. Usuário provavelmente deslogado.');
      return;
    }

    try {
      const url = `https://tribhus.shop:5000/notificacoes/all/${idUsuarioRecebe}/${idBandaRecebe}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });

      const temMensagensNaoLidas = response.data.some((notificacao: Notificacao) => notificacao.tem_mensagem_nao_lida);
      console.log("Existem mensagens não lidas?", temMensagensNaoLidas);

      setTemMensagensNaoLidas(temMensagensNaoLidas);
    } catch (error) {
      console.error('Erro ao buscar notificações:', error);
    }
  };

  const handleMensagemClick = () => {
    console.log('Ícone de mensagem clicado');
    if (isOwnProfile) {
      console.log('Navegando para página de Mensagens');
      navigate('/Mensagens');
    } else {
      console.log('Navegando para ChatBox');
      navigate('/ChatBox', {
        state: {
          idUsuarioRecebe: idUsuarioRecebe || undefined,
          idBandaRecebe: idBandaRecebe || undefined,
          idConversa: idConversa || undefined
        }
      });
    }
  };

  return (
    <div onClick={handleMensagemClick} style={styles.iconContainer}>
      <img 
        src={temMensagensNaoLidas && isOwnProfile ? unreadMessageIcon : messageIcon} 
        alt="Mensagens" 
        style={styles.icon}
      />
      {temMensagensNaoLidas && isOwnProfile && (
        <div style={styles.unreadIndicator}></div>
      )}
    </div>
  );
};

const styles = {
  iconContainer: {
    position: 'relative' as const,
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    zIndex: 1000,
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  unreadIndicator: {
    position: 'absolute' as const,
    top: '-5px',
    right: '-5px',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: 'red',
  },
};

export default IconeMensagens;