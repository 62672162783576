import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor';  
import { AuthContext } from '../context/AuthContext';
import { SlugContext } from '../context/SlugContext';
import MusicContext from '../context/MusicContext';
import { useNavigate } from 'react-router-dom';
import TabBar from '../Components/TabBar'; 
import MiniPlayer from '../Components/MiniPlayer'; 

interface MembroFamilia {
  id_usuario: number;
  nome: string;
  sobrenome: string;
  cidade: string | null;
  estado: string | null;
  url_icone_usuario: string | null;
  slug: string;
}

const FamiliaBanda: React.FC = () => {
  const { dispatch } = useContext(SlugContext);
  const { state: authState } = useContext(AuthContext);
  const [membrosFamilia, setMembrosFamilia] = useState<MembroFamilia[]>([]);
  const musicContext = useContext(MusicContext);
  const currentTrack = musicContext?.state.track;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMembrosFamilia = async () => {
      if (authState.token) {
        try {
          const response = await axios.get('https://tribhus.shop:5000/amigos/familia/lista', {
            headers: { Authorization: `Bearer ${authState.token}` },
          });
          console.log('Dados retornados do backend:', response.data);
          setMembrosFamilia(response.data);
        } catch (error) {
          console.error('Erro ao buscar membros da família:', error);
        }
      }
    };

    fetchMembrosFamilia();
  }, [authState.token]);

  const navigateToUserProfile = (slug: string) => {
    dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
    navigate('/TelaUsuarioPerfil');
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Família</h1> {/* Título adicionado */}
      <div style={styles.mainContent}>
        <div style={styles.tabBarColumn}>
          <TabBar currentScreen="Família" />
          {currentTrack && (
            <div style={styles.miniPlayerContainer}>
              <MiniPlayer />
            </div>
          )}
        </div>
        
        <div style={styles.feedColumn}>
          <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px' }}>
            {membrosFamilia.map((item) => (
              <div
                key={item.id_usuario}
                onClick={() => navigateToUserProfile(item.slug)}
                style={{
                  flex: '1 1 30%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '5px',
                  padding: '6px',
                  backgroundColor: '#1f2937',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
              >
                {item.url_icone_usuario ? (
                  <img
                    src={item.url_icone_usuario}
                    alt={`${item.nome} ${item.sobrenome}`}
                    style={{ width: '70px', height: '70px', borderRadius: '50%', objectFit: 'cover' }}
                  />
                ) : (
                  <div style={{
                    width: '70px',
                    height: '70px',
                    borderRadius: '50%',
                    backgroundColor: '#000000',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <span style={{ color: '#FFFFFF', fontSize: '10px' }}>Sem imagem</span>
                  </div>
                )}
                <span style={{ fontSize: '16px', color: '#FF6600', fontWeight: 'bold', marginTop: '5px' }}>
                  {item.nome} {item.sobrenome}
                </span>
                {item.cidade && (
                  <span style={{ fontSize: '14px', color: '#FFFFFF', marginTop: '3px' }}>
                    {item.cidade}
                  </span>
                )}
                {item.estado && (
                  <span style={{ fontSize: '12px', color: '#CCCCCC', marginTop: '1px' }}>
                    {item.estado}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#151922',
    minHeight: '100vh',
    color: 'lightgray',
    padding: '20px',
  },
  title: {
    color: '#FF6600', // Cor do título
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  mainContent: {
    display: 'flex',
    padding: '0 20px',
  },
  tabBarColumn: {
    width: '20%',
    paddingRight: '20px',
  },
  miniPlayerContainer: {
    marginTop: '60px',
  },
  feedColumn: {
    flex: 1,
    overflowY: 'auto' as 'auto', // Explicitamente definindo o tipo de overflowY
  },
};

export default FamiliaBanda;