//componente que adquire o slug do backend ao clicar no link compartilhado de banda ou usuario (compartilhamento)
import React, { useEffect, useContext, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { SlugContext } from '../context/SlugContext';

const SlugInterceptor: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation();
  const { dispatch } = useContext(SlugContext);

  useEffect(() => {
    const slug = location.hash.slice(1); // Remove o '#' do início
    if (slug) {
      dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
      console.log(`Slug atualizado para: ${slug}`);
      
      // Opcional: Remover o hash da URL após capturá-lo
      window.history.replaceState(null, '', location.pathname);
    }
  }, [location, dispatch]);

  return <>{children}</>;
};

export default SlugInterceptor;