import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor';  
import { AuthContext } from '../context/AuthContext';

interface User {
    id_usuario?: number;
    nome?: string;
    sobrenome?: string;
    descricao?: string;
    cidade?: string;
    estado?: string;
    data_nascimento?: string;
    genero_sexo?: string;
    telefone?: string | null;
    url_capa_usuario?: string | null;
    url_icone_usuario?: string | null;
    slug?: string;
    generos?: string[];
    auth_id?: number;
}

interface Genre {
    id_genero: number;
    nome_genero: string;
    isSelected?: boolean; 
}

interface GenerosEditProps {
    user: User;
    setUser: React.Dispatch<React.SetStateAction<User>>;
}

const GenerosEdit: React.FC<GenerosEditProps> = ({ user }) => {
    const [genres, setGenres] = useState<Genre[]>([]);
    const [filteredGenres, setFilteredGenres] = useState<Genre[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const { state: authState } = useContext(AuthContext);
    const [selectedGenres, setSelectedGenres] = useState<string[]>([]);

    useEffect(() => {
        const fetchAllGenres = async () => {
            let allGenres: Genre[] = [];
            let userGenres: string[] = [];
    
            try {
                console.log('Fetching all genres with token:', authState.token);
                const allGenresResponse = await axios.get('https://tribhus.shop:5000/users/genres', {
                    headers: { Authorization: `Bearer ${authState.token}` }
                });
                allGenres = allGenresResponse.data;
            } catch (error) {
                console.error("Erro ao buscar todos os gêneros:", error);
            }
    
            try {
                console.log(`Fetching genres for user ${user.id_usuario} with token:`, authState.token);
                const userGenresResponse = await axios.get(`https://tribhus.shop:5000/users/${user.id_usuario}`, {
                    headers: { Authorization: `Bearer ${authState.token}` }
                });
                userGenres = userGenresResponse.data.generos;
                setSelectedGenres(userGenres);
            } catch (error) {
                console.error(`Erro ao buscar gêneros do usuário ${user.id_usuario}:`, error);
            }
    
            if (allGenres && userGenres) {
                const markedGenres = allGenres.map((genre: Genre) => ({
                    ...genre,
                    isSelected: userGenres.includes(genre.nome_genero)
                }));                
                setFilteredGenres(markedGenres);
                setGenres(markedGenres);
            }
        };
        fetchAllGenres();
    }, [user.id_usuario, authState.token]);
    
    useEffect(() => {
        const lowerSearchTerm = searchTerm.toLowerCase();
        setFilteredGenres(genres.filter(genre => genre.nome_genero.toLowerCase().includes(lowerSearchTerm)));
    }, [searchTerm, genres]);

    const handleSelectGenre = (selectedGenreName: string) => {
        setFilteredGenres(prevGenres => prevGenres.map(genre => {
            if (genre.nome_genero === selectedGenreName) {
                const isSelected = !genre.isSelected;
                setSelectedGenres(prevSelected => {
                    if (isSelected) {
                        if (!prevSelected.includes(selectedGenreName)) {
                            return [...prevSelected, selectedGenreName];
                        }
                    } else {
                        return prevSelected.filter(name => name !== selectedGenreName);
                    }
                    return prevSelected;
                });
                return { ...genre, isSelected };
            }
            return genre;
        }));
    };      

    const handleSave = async () => {
        setIsUpdating(true);
        try {
            console.log('Saving genres for user:', user.id_usuario);
            console.log('Selected genres:', selectedGenres);
            console.log('Token na hora:', authState.token);
            const response = await axios.put(`https://tribhus.shop:5000/users/update/${user.id_usuario}/genres`, {
                generos: selectedGenres
            }, {
                headers: {
                    Authorization: `Bearer ${authState.token}`
                }
            });
    
            if (response.status === 200) {
                alert('Gêneros atualizados com sucesso!');
            } else {
                throw new Error('Falha ao salvar as alterações');
            }
        } catch (error) {
            console.error('Erro ao atualizar gêneros:', error);
            alert('Erro ao atualizar gêneros.');
        } finally {
            setIsUpdating(false);
        }
    };    

        return (
            <div style={styles.container}>
                <input
                    style={styles.searchBox}
                    placeholder="Busque seus gêneros preferidos"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div style={styles.genresContainer}>
                    {filteredGenres.map((genre, index) => (
                        <button 
                            key={index} 
                            onClick={() => handleSelectGenre(genre.nome_genero)}
                            style={{
                                ...styles.genreBox,
                                ...(genre.isSelected ? styles.selectedGenreBox : {})
                            }}
                        >
                            {genre.nome_genero}
                        </button>
                    ))}
                </div>
                <button style={styles.nextButton} onClick={handleSave} disabled={isUpdating}>
                    {isUpdating ? 'Salvando...' : 'Salvar Alterações'}
                </button>
            </div>
        );
    };
    
    const styles = {
        container: {
            padding: '10px',
        },
        searchBox: {
            height: '40px',
            borderColor: 'gray',
            borderWidth: '1px',
            borderRadius: '5px',
            paddingLeft: '10px',
            marginBottom: '30px',
            width: '90%',
            alignSelf: 'center',
            color: '#fff',
            backgroundColor: '#151922',
        },
        genresContainer: {
            display: 'flex',
            flexWrap: 'wrap' as 'wrap',
            justifyContent: 'space-between',
        },
        genreBox: {
            border: '1px solid #fff',
            borderRadius: '15px',
            backgroundColor: '#151922',
            padding: '10px',
            marginBottom: '20px',
            width: 'calc(33.33% - 15px)',
            textAlign: 'center' as 'center',
            cursor: 'pointer',
            color: '#fff',
        },
        selectedGenreBox: {
            backgroundColor: 'orange',
        },
        nextButton: {
            backgroundColor: '#ff6347',
            borderRadius: '20px',
            padding: '10px',
            marginTop: '20px',
            textAlign: 'center' as 'center',
            cursor: 'pointer',
            color: '#fff',
            fontSize: '18px',
            border: 'none',
            width: '100%',
        },
    };
    
    export default GenerosEdit;
    