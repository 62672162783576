import React, { useState, useContext, useEffect, CSSProperties } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { AuthContext } from '../context/AuthContext';
import LikesModal from './LikesModal';

type MusicLikeButtonProps = {
  trackId: number;
};

type LikeData = {
  nome: string;
  slug: string;
  tipo: 'usuario' | 'banda';
  id: number;
  imagem: string; // Adicionamos a propriedade 'imagem'
};

const MusicLikeButton: React.FC<MusicLikeButtonProps> = ({ trackId }) => {
  const { state: authState } = useContext(AuthContext);
  const [liked, setLiked] = useState(false);
  const [likesCount, setLikesCount] = useState<number>(0);
  const [likesList, setLikesList] = useState<LikeData[]>([]);
  const [recentLikes, setRecentLikes] = useState<LikeData[]>([]); // Para exibir os likes recentes
  const [modalOpen, setModalOpen] = useState(false);

  const fetchLikes = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`https://tribhus.shop:5000/music-like/${trackId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data.map((like: any) => ({
        nome: like.tipo === 'usuario' ? like.user_name : like.banda_name,
        slug: like.tipo === 'usuario' ? like.user_slug : like.banda_slug,
        tipo: like.tipo,
        id: like.tipo === 'usuario' ? like.user_id : like.banda_id,
        imagem: like.imagem, // Certifique-se de que a API retorna a imagem
      }));
      setLikesList(data);
      setLikesCount(data.length);

      // Atualizar se o usuário atual curtiu a música
      const userOrBandId = authState.id_usuario || authState.id_banda;
      const userLiked = data.some((like: { id: number }) => like.id === userOrBandId);
      setLiked(userLiked);

      // Atualizar os likes recentes (últimos 3)
      setRecentLikes(data.slice(-3));
    } catch (error) {
      console.error('Erro ao buscar curtidas da música:', error);
    }
  };

  useEffect(() => {
    fetchLikes();
  }, [trackId, authState.id_usuario, authState.id_banda]);

  const handleButtonClick = async () => {
    if (liked) {
      handleUnlike();
    } else {
      handleLike();
    }
  };

  const handleUnlike = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`https://tribhus.shop:5000/music-like/${trackId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLikesCount((prev) => prev - 1);
      setLiked(false);
      fetchLikes();
    } catch (error) {
      console.error('Erro ao descurtir a música:', error);
    }
  };

  const handleLike = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        `https://tribhus.shop:5000/music-like`,
        { id_musica: trackId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        setLikesCount((prev) => prev + 1);
        setLiked(true);

        // Enviar notificação de curtida de música
        try {
          await axios.post(
            'https://tribhus.shop:5000/notificacoes/music-like',
            { id_musica: trackId },
            { headers: { Authorization: `Bearer ${token}` } }
          );
        } catch (error) {
          console.error('Erro ao criar notificação de curtida de música:', error);
        }

        fetchLikes();
      }
    } catch (error) {
      console.error('Erro ao curtir a música:', error);
    }
  };

  const handleLikesClick = async () => {
    await fetchLikes();
    setModalOpen(true);
  };

  const getLikesMessage = () => {
    if (likesCount === 0) return '0 curtidas';
    if (likesCount === 1) return '1 pessoa curtiu isso';
    return `${likesCount} pessoas curtiram isso`;
  };

  const styles: { [key: string]: CSSProperties } = {
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    likeCount: {
      marginLeft: 10,
      color: '#545C6F',
      fontWeight: 'bold',
      fontSize: 12,
      cursor: 'pointer',
    },
    likeButton: {
      width: 24,
      height: 24,
    },
    image: {
      width: 24,
      height: 24,
      borderRadius: '50%',
      marginRight: -5,
    },
  };
  
    const curtirIcon = require('../assets/curtir.png');
    const curtiuIcon = require('../assets/curtiu.png');

  return (
    <div style={styles.buttonContainer}>
      <button
        onClick={handleButtonClick}
        style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
      >
        <img
          src={liked ? curtiuIcon : curtirIcon}
          alt="Like"
          style={{
            ...styles.likeButton,
            filter: liked
              ? 'brightness(0) saturate(100%) invert(55%) sepia(59%) saturate(1786%) hue-rotate(359deg) brightness(100%) contrast(106%)'
              : 'none',
          }}
        />
      </button>
      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
        {recentLikes.map((like, index) => (
          <img
            key={like.id ? like.id.toString() : index.toString()}
            src={like.imagem}
            alt={`Recent like ${index + 1}`}
            style={styles.image}
          />
        ))}
      </div>
      <span style={styles.likeCount} onClick={handleLikesClick}>
        {getLikesMessage()}
      </span>
      <LikesModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        likesList={likesList}
      />
    </div>
  );
};

export default MusicLikeButton;
