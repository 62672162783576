import axios, { AxiosInstance, AxiosError } from 'axios';

const baseURL = 'https://tribhus.shop:5000';

const axiosInstance: AxiosInstance & {
  isAxiosError: (payload: any) => payload is AxiosError;
  // Adicione outros métodos estáticos que você possa precisar aqui
} = axios.create({
  baseURL: baseURL
}) as any;

// Copiar métodos estáticos relevantes
axiosInstance.isAxiosError = axios.isAxiosError;
// Adicione outros métodos estáticos que você possa precisar aqui

type UpdateTokenFunction = (accessToken: string, refreshToken: string) => Promise<void>;

export function setupInterceptors(updateToken: UpdateTokenFunction) {
    axiosInstance.interceptors.request.use(
        async config => {
            const token = localStorage.getItem('accessToken');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );
    
    let isRefreshing = false;
    let failedQueue: { resolve: (token: string) => void; reject: (error: any) => void; }[] = [];

    const processQueue = (error: any, token: string | null = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else if (token) {
                prom.resolve(token);
            } else {
                prom.reject('No token provided');
            }
        });
        failedQueue = [];
    };

    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
          const originalRequest = error.config;
      
          if (error.response?.status === 401 || error.response?.data?.error === 'Token expired') {
            if (!isRefreshing) {
              isRefreshing = true;
              console.log("Token expirado. Tentando refresh...");
      
              try {
                const refreshToken = localStorage.getItem('refreshToken');
                const sessionId = localStorage.getItem('sessionId');
                const platform = 'web';
      
                const res = await axiosInstance.post('/users/refresh', { refreshToken, sessionId, platform });
       console.log('Resposta do refresh no interceptor:', res.data);
                        
       if (res.status === 200) {
        const newAccessToken = res.data.accessToken;
        const newRefreshToken = res.data.refreshToken;

        // Atualiza tokens no localStorage e contexto de autenticação
        localStorage.setItem('accessToken', newAccessToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        await updateToken(newAccessToken, newRefreshToken);

        // Atualiza o header Authorization para futuras requisições
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        processQueue(null, newAccessToken);

        console.log("Token atualizado com sucesso.");

        // Reenvia a requisição original com o novo access token
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);  // Aqui é onde você refaz a requisição original
      }
    } catch (refreshError) {
      console.error("Erro ao atualizar o token:", refreshError);
      processQueue(refreshError, null);
      // Aqui você pode implementar um logout automático ou outra lógica
    } finally {
      isRefreshing = false;
    }
  }

  // Adiciona a requisição à fila para ser reprocessada após a atualização do token
  return new Promise((resolve, reject) => {
    failedQueue.push({ resolve, reject });
  });
}

return Promise.reject(error);
}
);
}

export default axiosInstance;