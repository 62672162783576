import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor'; 

interface Video {
    id_video: number;
    url_video: string;
    titulo: string;
    descricao: string;
}

const VideosAdicionar: React.FC = () => {
    const { state: authState } = useContext(AuthContext);
    const [videoUrl, setVideoUrl] = useState<string>('');
    const [titulo, setTitulo] = useState<string>('');
    const [descricao, setDescricao] = useState<string>('');
    const [mensagem, setMensagem] = useState<string>('');
    const [videos, setVideos] = useState<Video[]>([]);

    const extractYoutubeLink = (text: string) => {
        const youtubeUrlPattern = /(https?:\/\/(?:www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]+(?:[^\s]*))/;
        const match = text.match(youtubeUrlPattern);
        return match ? match[0] : null;
    };

    useEffect(() => {
        fetchVideos();
    }, []);

    const fetchVideos = async () => {
        try {
            const response = await axios.get<Video[]>(`https://tribhus.shop:5000/videos/band/${authState.id_banda}`, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
            setVideos(response.data);
        } catch (error) {
            console.error('Erro ao buscar vídeos:', error);
            setMensagem('Erro ao carregar os vídeos. Por favor, tente novamente mais tarde.');
        }
    };

    const handleAddVideo = async () => {
        setMensagem('');
        const extractedYoutubeLink = extractYoutubeLink(videoUrl);
    
        if (!extractedYoutubeLink) {
            setMensagem('Por favor, insira uma URL válida do YouTube.');
            return;
        }
    
        if (!titulo) {
            setMensagem('Por favor, insira um título para o vídeo.');
            return;
        }
    
        if (!authState.id_banda) {
            setMensagem('ID da banda não encontrado. Por favor, faça login novamente.');
            return;
        }
    
        const videoData = {
            id_banda: authState.id_banda,
            url_video: extractedYoutubeLink,
            titulo,
            descricao
        };
    
        try {
            const response = await axios.post(`https://tribhus.shop:5000/videos/add/${authState.id_banda}`, videoData, {
                headers: {
                    Authorization: `Bearer ${authState.token}`,
                },
            });
    
            if (response.status === 201) {
                setMensagem('Vídeo adicionado com sucesso!');
                
                // Atualizar a lista de vídeos
                const newVideo: Video = {
                    id_video: response.data.id_video, // Assumindo que o backend retorna o id do novo vídeo
                    url_video: extractedYoutubeLink,
                    titulo,
                    descricao
                };
                
                setVideos(prevVideos => [newVideo, ...prevVideos]);
    
                // Limpar os campos do formulário
                setVideoUrl('');
                setTitulo('');
                setDescricao('');
            } else {
                setMensagem('Falha ao adicionar o vídeo. Por favor, tente novamente.');
            }
        } catch (error) {
            console.error('Erro ao adicionar o vídeo:', error);
            setMensagem('Erro ao adicionar o vídeo. Por favor, tente novamente mais tarde.');
        }
    };

    const handleDeleteVideo: (videoId: number) => Promise<void> = async (videoId) => {
        try {
            await axios.delete(`https://tribhus.shop:5000/videos/delete/${authState.id_banda}/${videoId}`, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
            setMensagem('Vídeo excluído com sucesso!');
            fetchVideos(); // Atualiza a lista de vídeos
        } catch (error) {
            console.error('Erro ao excluir o vídeo:', error);
            setMensagem('Erro ao excluir o vídeo. Por favor, tente novamente mais tarde.');
        }
    };

    const getYoutubeEmbedUrl = (url: string) => {
        const videoId = url.split('v=')[1]?.split('&')[0] || url.split('/').pop();
        return `https://www.youtube.com/embed/${videoId}`;
    };

    return (
        <div style={styles.container}>
            <div style={styles.leftColumn}>
                <h2 style={styles.headerTitle}>Adicionar Vídeo do YouTube</h2>
                <input
                    type="text"
                    value={videoUrl}
                    onChange={(e) => setVideoUrl(e.target.value)}
                    placeholder="URL do Vídeo do YouTube"
                    style={styles.input}
                />
                <input
                    type="text"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                    placeholder="Título do Vídeo"
                    style={styles.input}
                />
                <textarea
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    placeholder="Descrição do Vídeo (opcional)"
                    style={styles.textArea}
                />
                <button onClick={handleAddVideo} style={styles.addButton}>
                    <span style={styles.addButtonText}>Adicionar Vídeo</span>
                </button>
                {mensagem && <p style={styles.mensagem}>{mensagem}</p>}
            </div>
            <div style={styles.rightColumn}>
                <h2 style={styles.headerTitle}>Vídeos da Banda</h2>
                {videos.map((video) => (
                    <div key={video.id_video} style={styles.videoContainer}>
                        <h3 style={styles.videoTitle}>{video.titulo}</h3>
                        <iframe
                            width="100%"
                            height="200"
                            src={getYoutubeEmbedUrl(video.url_video)}
                            title={video.titulo}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <p style={styles.videoDescription}>{video.descricao}</p>
                        <button onClick={() => handleDeleteVideo(video.id_video)} style={styles.deleteButton}>
                            <span style={styles.deleteButtonText}>Excluir Vídeo</span>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#151922',
        padding: '20px',
        minHeight: '100vh',
    },
    leftColumn: {
        width: '40%',
        backgroundColor: '#1E2736',
        padding: '20px',
        borderRadius: '10px',
    },
    rightColumn: {
        width: '55%',
        backgroundColor: '#1E2736',
        padding: '20px',
        borderRadius: '10px',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 40px)',
    },
    headerTitle: {
        color: 'lightgray',
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '15px',
        backgroundColor: '#2C3E50',
        border: 'none',
        borderRadius: '5px',
        color: 'lightgray',
        fontSize: '16px',
    },
    textArea: {
        width: '100%',
        minHeight: '100px',
        padding: '10px',
        marginBottom: '15px',
        backgroundColor: '#2C3E50',
        border: 'none',
        borderRadius: '5px',
        color: 'lightgray',
        fontSize: '16px',
        resize: 'vertical',
    },
    addButton: {
        backgroundColor: '#FF6600',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        border: 'none',
        width: '100%',
    },
    addButtonText: {
        color: 'lightgray',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    mensagem: {
        color: 'lightgray',
        marginTop: '15px',
        textAlign: 'center',
    },
    videoContainer: {
        backgroundColor: '#30343f',
        padding: '15px',
        borderRadius: '10px',
        marginBottom: '20px',
    },
    videoTitle: {
        color: 'lightgray',
        fontSize: '18px',
        marginBottom: '10px',
    },
    videoDescription: {
        color: 'lightgray',
        fontSize: '14px',
        marginTop: '10px',
        marginBottom: '10px',
    },
    deleteButton: {
        backgroundColor: '#d9534f',
        padding: '8px 15px',
        borderRadius: '5px',
        cursor: 'pointer',
        border: 'none',
        marginTop: '10px',
    },
    deleteButtonText: {
        color: 'lightgray',
        fontWeight: 'bold',
        fontSize: '14px',
    },
};

export default VideosAdicionar;