import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  songs: [],
};

const topSongsSlice = createSlice({
  name: 'topSongs',
  initialState,
  reducers: {
    getTopSongs: (state, action) => {
      state.songs = action.payload;
    },
  },
});

export const { getTopSongs } = topSongsSlice.actions;
export const selectTopSongs = (state) => state.topSongs.songs;
export default topSongsSlice.reducer;
