import React from 'react';

interface Video {
  id_video: number;
  url_video: string;
  titulo: string;
  descricao: string;
}

interface ModalVideoProps {
  isOpen: boolean;
  video: Video;
  onClose: () => void;
}

const ModalVideo: React.FC<ModalVideoProps> = ({ isOpen, video, onClose }) => {
  if (!isOpen) return null;

  const getYoutubeEmbedUrl = (url: string) => {
    const videoId = url.split('v=')[1]?.split('&')[0] || url.split('/').pop();
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <div style={styles.header}>
          <h2 style={styles.title}>{video.titulo}</h2>
          <button style={styles.closeButton} onClick={onClose}>X</button>
        </div>
        <div style={styles.videoContainer}>
          <iframe
            width="100%"
            height="100%"
            src={getYoutubeEmbedUrl(video.url_video)}
            title={video.titulo}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={styles.video}
          ></iframe>
        </div>
        <p style={styles.description}>{video.descricao}</p>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: '#202020',
    padding: '20px',
    borderRadius: '10px',
    width: '80%',
    maxWidth: '800px',
    minWidth: '500px',
    maxHeight: '90vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column' as const,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
  },
  closeButton: {
    background: '#FF6600',
    border: 'none',
    color: 'lightgray',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
    padding: '5px 10px',
    borderRadius: '5px',
  },
  title: {
    color: 'lightgray',
    margin: 0,
    fontSize: '24px',
  },
  videoContainer: {
    position: 'relative' as const,
    paddingBottom: '56.25%', // 16:9 aspect ratio
    height: 0,
    overflow: 'hidden',
  },
  video: {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  },
  description: {
    color: 'lightgray',
    marginTop: '15px',
    fontSize: '16px',
    lineHeight: '1.5',
  },
};

export default ModalVideo;