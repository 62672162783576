import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor';  

interface User {
    id_usuario?: number;
    nome?: string;
    sobrenome?: string;
    descricao?: string;
    cidade?: string;
    estado?: string;
    data_nascimento?: string;
    genero_sexo?: string;
    telefone?: string | null;
    url_capa_usuario?: string | null;
    url_icone_usuario?: string | null;
    slug?: string;
    generos?: string[];
    auth_id?: number;
}

interface SobreMimProps {
    user: User;
    updateDescription: (description: string) => void;
}

const SobreMim: React.FC<SobreMimProps> = ({ user, updateDescription }) => {
    const [description, setDescription] = useState<string>(user.descricao || '');
    const { state: authState } = useContext(AuthContext);

    const handleSave = async () => {
        if (user.id_usuario) {
            try {
                await axios.put(`https://tribhus.shop:5000/users/profile/${user.id_usuario}`, {
                    descricao: description
                }, {
                    headers: {
                        Authorization: `Bearer ${authState.token}`
                    }
                });
                alert('Descrição atualizada com sucesso!');
                updateDescription(description);
            } catch (error) {
                console.error('Erro ao atualizar descrição:', error);
                alert('Erro ao atualizar descrição.');
            }
        } else {
            alert('Erro: ID do usuário não encontrado.');
        }
    };

           return (
            <div style={styles.formContainer}>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Editar sua descrição"
                    style={styles.input}
                    rows={6}
                />
                <button style={styles.buttonSaveDescription} onClick={handleSave}>
                    Salvar
                </button>
            </div>
        );
    };
    
    const styles = {
        formContainer: {
            padding: 20,
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 700,
            maxWidth: '100%',
            margin: '0 auto',
            backgroundColor: '#1E1E1E',
            borderRadius: 10,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
        input: {
            height: 120,
            borderColor: 'gray',
            borderWidth: 1,
            marginBottom: 10,
            padding: 10,
            width: '100%',  // Ocupa toda a largura disponível do contêiner pai
            borderRadius: 20,
            color: '#fff',
            backgroundColor: '#151922',
            boxSizing: 'border-box' as const,
            resize: 'vertical' as const,
            fontFamily: 'inherit',
            fontSize: 'inherit',
        },
        buttonSaveDescription: {
            backgroundColor: '#FF6600',  // Cor de fundo laranja para salvar a descrição
            padding: '10px 20px',
            borderRadius: 10,
            border: 'none',
            cursor: 'pointer',
            color: '#fff',
            fontSize: 16,
            marginTop: 10,
        },
    };
    
    export default SobreMim;
    