import React, { useContext, useState, useEffect } from 'react';
import axios from '../Services/axiosInterceptor';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { RegisterContext } from '../context/RegisterContext';
import TermsOfUseModal from '../documents/TermsOfUseModal';
import Modal from 'react-modal';

interface UserInfo {
  emailVerified: boolean;
  role: string;
}

const QuartaTela = () => {
  const { state, dispatch } = useContext(RegisterContext);
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [loginError, setLoginError] = useState<string>('');
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [redirectMessage, setRedirectMessage] = useState('');
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [preRegistrationComplete, setPreRegistrationComplete] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>({ emailVerified: false, role: '' });

  useEffect(() => {
    const storedAuthId = localStorage.getItem('auth_id');
    console.log('Stored auth_id on component mount:', storedAuthId);
  }, []);

  const validateEmail = (email: string): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const checkSlugAvailability = async (): Promise<boolean> => {
    try {
      const response = await axios.post('https://tribhus.shop:5000/users/check-slug', { slug: state.slug });
      if (response.data.message === "Nome de Usuário já está em uso, por favor escolha outro.") {
        setErrorMsg(response.data.message);
        return false;
      }
      setErrorMsg(null);
      return true;
    } catch (error) {
      console.error(error);
      setErrorMsg('Ocorreu um problema ao verificar a disponibilidade do nome de usuário.');
      return false;
    }
  };

  const validatePassword = (): boolean => {
    if (state.senha.length < 8) {
      setErrorMsg('A senha deve ter no mínimo 8 caracteres.');
      return false;
    }
    if (state.senha !== confirmPassword) {
      setErrorMsg('As senhas não correspondem!');
      return false;
    }
    return true;
  };

  const handleNext = async () => {
    if (preRegistrationComplete) {
      setShowTermsModal(true);
      return;
    }
  
    if (!validateEmail(state.email)) {
      setErrorMsg('Por favor, insira um email válido!');
      return;
    }
    
    const isSlugAvailable = await checkSlugAvailability();
    if (!isSlugAvailable || !validatePassword()) {
      return;
    }
  
    try {
      const userResponse = await axios.post('https://tribhus.shop:5000/users/auth', {
        email: state.email,
        senha: state.senha,
        role: 'usuario'
      });
  
      const receivedAuthId = userResponse.data.auth_id;
      if (receivedAuthId) {
        localStorage.setItem('auth_id', receivedAuthId.toString());
        dispatch({ type: 'SET_AUTH_ID', auth_id: receivedAuthId });
      }
  
      if (userResponse.data.token) {
        localStorage.setItem('token', userResponse.data.token);
      }
  
      setPreRegistrationComplete(true);
      setShowTermsModal(true);
    } catch (error) {
      console.error('Erro durante a autenticação:', error);
  
      if (axios.isAxiosError(error) && error.response) {
        const responseData = error.response.data as { error: string, isComplete?: boolean };
        if (responseData.error === 'Cadastro já completo' && responseData.isComplete) {
          // Redirecionar para a tela de login se o cadastro já estiver completo
          handleRedirectWithDelay("Você já é cadastrado na Tribhus", '/PrimeiraTela');
        } else {
          setErrorMsg(responseData.error);
        }
      } else {
        setErrorMsg('Ocorreu um erro desconhecido.');
      }
    }
  };

  const handleAcceptTerms = async () => {
    try {
      const authId = localStorage.getItem('auth_id');
      if (!authId) {
        throw new Error('AuthId não encontrado');
      }
  
      // Aceitar os termos de uso
      await axios.post(`https://tribhus.shop:5000/bands/accept-terms/${authId}`, {}, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
  
      setTermsAccepted(true);
      setShowTermsModal(false);
      
      // Verificar o estado de emailVerified
      if (!userInfo || !userInfo.emailVerified) {
        // Se userInfo não existe ou email não está verificado, vá para CodEmailUser
        navigate('/CodEmailUser');
      } else {
        // Se o email já foi verificado, vá para a próxima etapa (por exemplo, CodSMS)
        navigate('/CodSMS');
      }
    } catch (error) {
      console.error('Erro ao aceitar os termos:', error);
      setErrorMsg('Ocorreu um erro ao aceitar os termos. Por favor, tente novamente.');
      setShowTermsModal(false);
    }
  };

  const handleCloseTermsModal = () => {
    setShowTermsModal(false);
    if (!termsAccepted) {
      setErrorMsg('Você precisa aceitar os termos para continuar.');
    }
  };

  const handleRedirectWithDelay = (message: string, path: string) => {
    setRedirectMessage(message);
    setShowRedirectModal(true);
    setTimeout(() => {
      setShowRedirectModal(false);
      navigate(path);
    }, 5000);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post('/users/loginusu', {
        email: loginEmail,
        senha: loginPassword,
      });

      const { auth_id, role, emailVerified } = response.data;
      localStorage.setItem('auth_id', auth_id.toString());
      dispatch({ type: 'SET_AUTH_ID', auth_id });
      setShowLoginModal(false);

      // Verificar se os termos de uso foram aceitos
      const termsResponse = await axios.get(`https://tribhus.shop:5000/bands/check-terms/${auth_id}`);
      const termsAccepted = termsResponse.data.aceito;

      if (!termsAccepted) {
        setShowTermsModal(true);
        setUserInfo({ emailVerified, role });
      } else {
        handleNavigation(emailVerified, role);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ error: string }>;
        if (axiosError.response?.data?.error) {
          const errorMessage = axiosError.response.data.error;
          if (errorMessage === "Cadastro já completo, redirecionando...") {
            handleRedirectWithDelay("Você já é cadastrado na Tribhus", '/PrimeiraTela');
          } else {
            setLoginError(errorMessage);
          }
        } else {
          setLoginError("Erro na resposta do servidor");
        }
      } else {
        setLoginError("Não foi possível conectar ao servidor.");
      }
    }
  };

  const handleNavigation = (emailVerified: boolean, role: string) => {
    console.log('Navegando com base nas informações do usuário:', { emailVerified, role });
    if (emailVerified) {
      navigate('/CodSMSUser');
    } else {
      navigate('/CodEmailUser');
    }
  };

  return (
    <div style={styles.container}>
      <TermsOfUseModal
        isOpen={showTermsModal}
        onClose={handleCloseTermsModal}
        onAccept={handleAcceptTerms}
        userType="usuario"
      />
      <Modal
        isOpen={showLoginModal}
        onRequestClose={() => setShowLoginModal(false)}
        style={customStyles}
        contentLabel="Login Modal"
      >
        <button onClick={() => setShowLoginModal(false)} style={styles.closeButton}>X</button>
        <h2>Email já cadastrado, por favor, faça o login e continue o cadastro.</h2>
        <input
          style={styles.input}
          onChange={(e) => setLoginEmail(e.target.value)}
          value={loginEmail}
          placeholder="Email"
          type="email"
        />
        <input
          style={styles.input}
          onChange={(e) => setLoginPassword(e.target.value)}
          value={loginPassword}
          placeholder="Senha"
          type="password"
        />
        {loginError && <p style={styles.errorText}>{loginError}</p>}
        <button onClick={handleLogin} style={styles.button}>Login</button>
      </Modal>
   {/* New redirect modal */}
   <Modal
        isOpen={showRedirectModal}
        style={customStyles}
        contentLabel="Redirect Modal"
      >
        <h2>{redirectMessage}</h2>
        <p>Redirecionando para tela de login...</p>
      </Modal>

      <img src={require('../assets/logotipo.png')} alt="Logotipo" style={styles.logo} />
      <button onClick={() => navigate(-1)} style={styles.backButton}>
        <img src={require('../assets/voltar.png')} alt="Voltar" style={styles.backIcon} />
      </button>
      <h1 style={styles.title}>Vamos começar cadastrando seus dados...</h1>
      
      <div style={styles.inputContainer}>
      <input
          style={styles.input}
          onChange={(e) => {
            const newSlug = e.target.value.toLowerCase().replace(/[^a-z0-9_]/g, '');
            dispatch({ type: 'SET_FORM', field: 'slug', value: newSlug });
          }}
          value={state.slug}
          placeholder="Escolha seu Nome de Usuário"
        />
        <input
          style={styles.input}
          onChange={(e) => dispatch({ type: 'SET_FORM', field: 'email', value: e.target.value })}
          value={state.email}
          placeholder="Email"
          type="email"
        />
        <input
          style={styles.input}
          onChange={(e) => dispatch({ type: 'SET_FORM', field: 'senha', value: e.target.value })}
          value={state.senha}
          placeholder="Senha"
          type="password"
        />
        <input
          style={styles.input}
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          placeholder="Confirmar Senha"
          type="password"
        />
      </div>
           
      {errorMsg && <p style={styles.errorText}>{errorMsg}</p>}
     
      <button
        style={styles.button}
        onClick={handleNext}
      >
        {preRegistrationComplete ? 'Ver Termos de Uso' : 'Próximo'}
      </button>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#151922', // Mesma cor de fundo que no React Native
    padding: '20px',
  },
  logo: {
    width: '280px',
    height: '360px',
    objectFit: 'contain',
    marginBottom: '20px',
  },
  backButton: {
    position: 'absolute' as 'absolute',
    top: '10px',
    left: '10px',
    background: 'none',
    border: 'none',
    fontSize: '12px',
    cursor: 'pointer',
  },
  backIcon: {
    width: '30px',
    height: '30px',
  },
  title: {
    fontSize: '20px',
    color: '#fff', // Cor do título como no React Native
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '-30px',
  },
  inputContainer: {
    width: '80%',
    maxWidth: '300px',
    marginBottom: '30px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '10px',
    backgroundColor: '#151922',
    borderColor: '#914100',
    borderWidth: '1px',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
  },
  button: {
    backgroundColor: '#914100',
    padding: '15px 50px',
    borderRadius: '10px',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    textAlign: 'center',
    width: '200px',
    margin: '10px auto',
  },
  buttonText: {
    color: '#fff',
    fontSize: '16px',
  },
  suggestionsContainer: {
    width: '80%',
    marginBottom: '20px',
  },
  errorText: {
    color: '#FF6600',
    fontSize: '14px',
    textAlign: 'center',
    //marginBottom: '10px',
  },
  modalOverlay: {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparência como no React Native
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    backgroundColor: 'lightgray',
    padding: '35px',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    position: 'relative',
    width: '80%',
    maxWidth: '500px',
  },
  modalText: {
    fontSize: '18px',
    color: '#000',
    textAlign: 'center',
    marginBottom: '15px',
  },
  textInputModal: {
    width: '240px',
    padding: '10px',
    marginBottom: '10px',
    backgroundColor: '#151922',
    color: 'lightgray',
    borderRadius: '5px',
    border: '1px solid #914100',
  },
  buttonStyle: {
    backgroundColor: '#914100',
    padding: '10px',
    borderRadius: '5px',
    marginTop: '10px',
    width: '200px',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute' as const,
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  },
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '20px', // Ajustado para combinar com o React Native
    backgroundColor: 'lightgray',
  },
};

export default QuartaTela;
