import React, { useContext, useState, useEffect } from 'react';
import { BandaContext } from '../context/BandaContext';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor';
import { useNavigate } from 'react-router-dom';

const TelaBanda5 = () => {
  const { state: bandaState } = useContext(BandaContext);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const handleRegister = async () => {
      try {
        if (bandaState.id_banda === null) {
          throw new Error("ID da banda não disponível.");
        }
  
        const bandaId = bandaState.id_banda;
        setProgress(0.5);
      // Adicionando log para rastrear quantas vezes a função é chamada
      console.log("Iniciando tentativa de salvar gêneros", bandaState.selectedGenres);

        const promises = bandaState.selectedGenres.map(genre => {
          const postData = {
            id_banda: bandaId, 
            id_genero: genre
          };
          return axios.post(`https://tribhus.shop:5000/bands/band-genres/${bandaId}`, postData);
        });      
  
        await Promise.all(promises);
        setProgress(1);
        setSuccess(true);
      } catch (error) {
        setError('Falha ao salvar gêneros, ' + (error instanceof Error ? error.message : "Erro desconhecido"));
      }
    };
  
    if (bandaState.id_banda && bandaState.selectedGenres.length > 0) {
      handleRegister();
    }
  }, [bandaState.id_banda, bandaState.selectedGenres]);

  useEffect(() => {
    const handleLogin = async () => {
      if (success && bandaState.email && bandaState.senha) { // Já está correto aqui
        try {
          // Aqui precisa mudar para usar bandaState ao invés de registerState
          const loginSuccess = await login(bandaState.email, bandaState.senha, navigate);
          if (loginSuccess) {
            console.log('Login automático bem-sucedido');
            navigate('/TelaBandaPerfil');
          } else {
            setError('Falha no login automático. Por favor, faça login manualmente.');
          }
        } catch (error) {
          console.error('Erro durante o login automático:', error);
          setError('Erro durante o login automático. Por favor, tente fazer login manualmente.');
        }
      }
    };

    handleLogin();
  }, [success, bandaState.email, bandaState.senha, login, navigate]);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#fff',
      height: '100vh',
      padding: '20px',
    },
    logo: {
      width: '280px',
      height: '360px',
      objectFit: 'contain' as 'contain',
      marginBottom: '50px',
      marginTop: '10px',
    },
    progressBar: {
      width: '200px',
      height: '8px',
      backgroundColor: '#e6e6e6',
      borderRadius: '4px',
      overflow: 'hidden',
    },
    progressFill: {
      width: `${progress * 100}%`,
      height: '100%',
      backgroundColor: '#914100',
      transition: 'width 0.5s ease-in-out',
    },
    loadingText: {
      fontSize: '20px',
      color: '#000',
      marginTop: '20px',
    },
    successText: {
      fontSize: '20px',
      color: 'green',
      marginTop: '20px',
    },
    errorText: {
      fontSize: '20px',
      color: 'red',
      marginTop: '20px',
    },
  };

  return (
    <div style={styles.container}>
      <img src={require('../assets/logotipo.png')} style={styles.logo} alt="Logotipo" />
      <div style={styles.progressBar}>
        <div style={styles.progressFill}></div>
      </div>
      {success ? (
        <span style={styles.successText}>Registro concluído com sucesso!</span>
      ) : (
        <span style={styles.loadingText}>Aguarde um momento</span>
      )}
      {error && <span style={styles.errorText}>{error}</span>}
    </div>
  );
};

export default TelaBanda5;