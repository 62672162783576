import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../Services/axiosInterceptor'; 
import { AuthContext } from '../context/AuthContext';

interface Mensagem {
  id_mensagem: number;
  nome_sender: string | null;
  mensagem: string;
  data_hora: string;
  lida: boolean;
  mensagemRecebidaPelaBanda: boolean;
  direcao_mensagem: 'envia' | 'recebe';
}

interface Participant {
  id: number;
  nome: string;
  tipo: string;
  icone: string;
}

export interface Conversa {
  id_conversa: number;
  posicao_usuario_logado: string;
  otherParticipant: Participant;
  messages: Mensagem[];
}

const Mensagens = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state: authState } = useContext(AuthContext);
  const [conversasAtualizadas, setConversasAtualizadas] = useState<Conversa[]>([]);

  const conversasIniciais = location.state?.mensagens ?? [];

  const fetchMensagens = async () => {
    try {
      const response = await axios.get('https://tribhus.shop:5000/mensagens/my-messages', {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      });
      setConversasAtualizadas(response.data);
    } catch (error) {
      console.error('Erro ao buscar mensagens:', error);
    }
  };

  useEffect(() => {
    fetchMensagens();
  }, [authState.token]);

  const handleConversationClick = (conversaId: number) => {
    const conversa = conversasAtualizadas.find((c: Conversa) => c.id_conversa === conversaId) || 
                     conversasIniciais.find((c: Conversa) => c.id_conversa === conversaId);
    if (!conversa) return;

    const idUsuarioRecebe = conversa.otherParticipant.tipo === 'usuario' ? conversa.otherParticipant.id : undefined;
    const idBandaRecebe = conversa.otherParticipant.tipo === 'banda' ? conversa.otherParticipant.id : undefined;

    navigate('/ChatBox', {
      state: {
        idUsuarioRecebe,
        idBandaRecebe,
        idConversa: conversaId,
        otherParticipantName: conversa.otherParticipant.nome  // Passando o nome do participante
      }
    });
};

  const handleBackPress = () => {
    navigate(-1);
  };

  function formatDateTime(dateTimeStr: string): string {
    try {
      const dateTime = new Date(dateTimeStr);
      if (isNaN(dateTime.getTime())) {
        throw new Error('Invalid date time string');
      }
      const time = `${dateTime.getHours().toString().padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}`;
      const date = `${dateTime.getDate().toString().padStart(2, '0')}/${(dateTime.getMonth() + 1).toString().padStart(2, '0')}/${dateTime.getFullYear()}`;
      return `${time} ${date}`;
    } catch (e) {
      console.error(e);
      return 'Data/Hora inválida';
    }
  }

  return (
    <div style={styles.fullScreen}>
      <div style={styles.header}>
        <button onClick={handleBackPress} style={styles.backButton}>
          <img
            src={require('../assets/voltar.png')}
            alt="Voltar"
            style={styles.backIcon}
          />
        </button>
        <h1 style={styles.modalTitle}>Mensagens</h1>
      </div>
      <div style={styles.messagesContainer}>
        {(conversasAtualizadas.length > 0 ? conversasAtualizadas : conversasIniciais)
          .filter((conversa: Conversa) => conversa.messages && conversa.messages.length > 0)
          .map((conversa: Conversa) => {
            const ultimaMensagem = conversa.messages[0];
            conversa.messages.forEach(msg => {
              console.log(`Mensagem ID ${msg.id_mensagem} direção: ${msg.direcao_mensagem}, lida: ${msg.lida}`);
            });
            const mostraIndicadorNaoLida = ultimaMensagem.direcao_mensagem === "recebe" && !ultimaMensagem.lida;

            return (
              <div
                key={conversa.id_conversa}
                onClick={() => handleConversationClick(conversa.id_conversa)}
                style={styles.messageItem}
              >
                {
                  conversa.otherParticipant.icone ?
                    <img
                      src={conversa.otherParticipant.icone}
                      alt="Avatar"
                      style={styles.avatar}
                    /> :
                    <div style={styles.avatarPlaceholder} />
                }
                <div style={styles.messageContent}>
                  <p style={styles.messageSender}>{conversa.otherParticipant.nome}</p>
                  <p style={mostraIndicadorNaoLida ? styles.messageTextUnread : styles.messageText}>
                    {ultimaMensagem.mensagem}
                  </p>
                </div>
                <div style={styles.messageDateContainer}>
                  {mostraIndicadorNaoLida && (
                    <>
                      <span style={styles.exclamation}>!</span>
                      <img
                        src={require('../assets/lida.png')}
                        alt="Não lida"
                        style={styles.unreadIcon}
                      />
                    </>
                  )}
                  <span style={styles.messageTime}>{formatDateTime(ultimaMensagem.data_hora)}</span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: '#151922',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px 20px',
    backgroundColor: '#333',
  },
  backButton: {
    marginRight: '-10px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  backIcon: {
    width: '24px',
    height: '24px',
  },
  avatarPlaceholder: {
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: '20px',
    color: '#FFFFFF',
    fontWeight: 'bold',
    flex: 1,
    textAlign: 'center',
  },
  messagesContainer: {
    flex: 1,
    overflowY: 'auto',
  },
  messageSender: {
    fontWeight: 'bold',
    color: 'lightgray',
    fontSize: '16px',
  },
  messageTime: {
    fontSize: '12px',
    color: '#A5A5A5',
    marginTop: '-50px',
  },
  messageItem: {
    display: 'flex',
    padding: '16px',
    borderBottom: '1px solid #333',
    alignItems: 'center',
    cursor: 'pointer',
  },
  messageContent: {
    flex: 1,
    marginLeft: '16px',
  },
  avatar: {
    width: '50px',
    height: '50px',
    borderRadius: '25px',
  },
  messageText: {
    color: 'lightgray',
  },
  messageTextUnread: {
    color: 'orange',
  },
  exclamation: {
    color: 'lightgray',
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: '-25px',
  },
  unreadIcon: {
    width: '20px',
    height: '20px',
    marginRight: '-100px',
    marginBottom: '-10px',
    objectFit: 'contain',
  },
  messageDateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
};

export default Mensagens;