import React from 'react';

const SobreDocument = () => (
  <>
    <h2>Sobre a Tribhus</h2>
    <p>
      A Tribhus é uma inovadora plataforma de streaming dedicada ao Rock e suas vertentes. Nossa missão é potencializar a visibilidade de bandas e artistas independentes, conectando-os diretamente aos fãs apaixonados por rock.
    </p>

    <h3>Para as Bandas</h3>
    <p>Oferecemos um espaço único para:</p>
    <ul>
      <li>Compartilhar suas músicas, vídeos e fotos com um público engajado</li>
      <li>Divulgar agenda de shows e eventos</li>
      <li>Promover produtos e merchandise (em desenvolvimento)</li>
    </ul>

    <h3>Para os Fãs</h3>
    <p>Proporcionamos uma experiência imersiva:</p>
    <ul>
      <li>Descubra novas bandas e acompanhe seus artistas favoritos</li>
      <li>Acesse conteúdo exclusivo e atualizações em tempo real</li>
      <li>Explore uma comunidade vibrante de amantes do Rock</li>
      <li>Encontre seus gêneros do rock favoritos e crie suas tribhus</li>
    </ul>

    <h3>Nossa Visão</h3>
    <p>
  Acreditamos no potencial inexplorado do cenário do rock brasileiro, da América Latina e além. A Tribhus nasceu para ser a ponte entre artistas talentosos de diferentes países e um público global ávido por novas descobertas musicais. Estamos redefinindo o streaming de música para artistas independentes, criando um ecossistema que valoriza e impulsiona o rock em todas as suas vertentes.
</p>

<h3>Compromisso com a Excelência</h3>
<p>Nosso time trabalha incansavelmente para aprimorar a plataforma, garantindo:</p>
<ul>
  <li>Uma interface intuitiva e responsiva</li>
  <li>Recursos inovadores para artistas e fãs</li>
  <li>Colaboração constante com a comunidade do rock, permitindo que os usuários participem do desenvolvimento e da evolução da plataforma</li>
</ul>

    <h3>Fale Conosco</h3>
    <p>
      Sua opinião é fundamental para o crescimento da Tribhus. Tem sugestões, dúvidas ou feedback? Entre em contato conosco:
    </p>
    <ul>
      <li>E-mail: <a href="mailto:contato@tribhus.com.br">contato@tribhus.com.br</a></li>
    </ul>

    <p>
      Junte-se a nós nessa jornada roqueira. A Tribhus é mais que uma plataforma - é um movimento em prol do rock nacional!
    </p>
  </>
);

export default SobreDocument;
