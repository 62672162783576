import React, { useEffect, useState, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { RegisterContext } from '../context/RegisterContext';
import { useNavigate } from 'react-router-dom';

interface Genre {
  id_genero: number;
  nome_genero: string;
}

const SetimaTela = () => {
  const [genres, setGenres] = useState<Genre[]>([]);
  const [filteredGenres, setFilteredGenres] = useState<Genre[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state, dispatch } = useContext(RegisterContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching genres...');
        const response = await axios.get('https://tribhus.shop:5000/users/genres');
        console.log('Genres fetched:', response.data);
        setGenres(response.data);
        setFilteredGenres(response.data);
      } catch (err) {
        console.error('Error fetching genres:', err);
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred.");
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    setFilteredGenres(genres.filter(genre => genre.nome_genero.toLowerCase().includes(lowerSearchTerm)));
  }, [searchTerm, genres]);

  const handleSelectGenre = (genre: Genre) => {
    const genreId = genre.id_genero;
    if (state.selectedGenres.includes(genreId.toString())) {
      dispatch({
        type: 'SET_SELECTED_GENRES',
        genres: state.selectedGenres.filter(id => id !== genreId.toString())
      });
    } else {
      dispatch({
        type: 'SET_SELECTED_GENRES',
        genres: [...state.selectedGenres, genreId.toString()]
      });
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleNext = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
  
    if (state.genero_sexo === 'Outro' && !state.outro_genero) {
      setError('Por favor, especifique seu gênero.');
      setIsSubmitting(false);
      return;
    }
  
    const payload = {
      auth_id: state.auth_id,
      nome: state.nome,
      sobrenome: state.sobrenome,
      genero_sexo: state.genero_sexo,
      data_nascimento: state.data_nascimento,
      selected_genres: state.selectedGenres,
      slug: state.slug,
      outro_genero: state.genero_sexo === 'Outro' ? state.outro_genero : null,
    };
  
    try {
      const userResponse = await axios.post('https://tribhus.shop:5000/users/register', payload);
      dispatch({ type: 'SET_USER_ID', id: userResponse.data.id_usuario });
      navigate('/OitavaTela');
    } catch (error) {
      console.error(error);
      setError('Erro ao registrar usuário. Por favor, tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };  

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <button style={styles.backButton} onClick={() => window.history.back()}>
          <img src={require('../assets/voltar.png')} alt="Voltar" style={styles.backIcon} />
        </button>
        <img
          style={styles.logo}
          src={require('../assets/logotipo.png')}
          alt="Logotipo"
        />
      </div>
      <input
        style={styles.searchBox}
        placeholder="Busque seus gêneros preferidos"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <div style={styles.genresWrapper}>
        <div style={styles.genresContainer}>
          {error ? (
            <div style={styles.errorContainer}>
              <span style={styles.errorText}>Erro: {error}</span>
            </div>
          ) : filteredGenres.length > 0 ? (
            filteredGenres.map((genre, index) => (
              <div
                key={index}
                style={{
                  ...styles.genreBox,
                  ...(state.selectedGenres.includes(genre.id_genero.toString()) ? styles.selectedGenreBox : {})
                }}
                onClick={() => handleSelectGenre(genre)}
              >
                <span style={styles.genreText}>{genre.nome_genero}</span>
              </div>
            ))
          ) : (
            <div style={styles.loadingContainer}>
              <span style={styles.loadingText}>
                {searchTerm ? "Nenhum gênero encontrado" : "Carregando gêneros..."}
              </span>
            </div>
          )}
        </div>
      </div>
      <button style={styles.nextButton} onClick={handleNext} disabled={isSubmitting}>
        {isSubmitting ? 'Carregando...' : 'Próximo'}
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    backgroundColor: '#151922',
    padding: '10px',
    height: '100vh',
    overflowY: 'auto' as const,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '20px',
  },
  logo: {
    width: '260px',
    height: '140px',
    objectFit: 'contain' as const,
  },
  backButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },
  backIcon: {
    width: '30px',
    height: '30px',
  },
  searchBox: {
    width: '60%',
    padding: '10px',
    marginBottom: '20px',
    backgroundColor: '#ffffff',
    color: '#000000',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    alignSelf: 'center',
  },
  genresWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  genresContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    justifyContent: 'space-between',
    maxWidth: '800px',
    width: '100%',
  },
  genreBox: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '15px',
    borderColor: '#fff',
    backgroundColor: '#151922',
    padding: '10px',
    marginBottom: '20px',
    width: 'calc(33.33% - 15px)',
    textAlign: 'center' as const,
    cursor: 'pointer',
  },
  genreText: {
    fontSize: '14px',
    color: '#fff',
  },
  selectedGenreBox: {
    backgroundColor: 'orange',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '200px',
  },
  loadingText: {
    fontSize: '20px',
    color: '#fff',
  },
  nextButton: {
    backgroundColor: '#ff6347',
    padding: '15px 50px',
    borderRadius: '10px',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    textAlign: 'center' as const,
    width: '200px',
    margin: '10px auto',
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  },
  errorText: {
    color: 'red',
  },
};

export default SetimaTela;
