import React, { useState } from 'react';
import axios from '../Services/axiosInterceptor';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type RazaoDenuncia = {
  id: number;
  label: string;
};

type RazoesDenuncia = {
  [key: string]: RazaoDenuncia[];
};

interface BotaoDenunciaPerfilProps {
  itemId: number | string;
  tipoItem: 'usuario' | 'banda';
  onDenunciaEnviada?: () => void;
}

const BotaoDenunciaPerfil: React.FC<BotaoDenunciaPerfilProps> = ({ itemId, tipoItem, onDenunciaEnviada }) => {
  const [estaAberto, setEstaAberto] = useState<boolean>(false);
  const [razaoSelecionada, setRazaoSelecionada] = useState<number | null>(null);
  const [enviando, setEnviando] = useState<boolean>(false);

  const razoesDenuncia: RazoesDenuncia = {
    usuario: [
      { id: 3, label: 'Perfil falso ou enganoso' },
      { id: 4, label: 'Spam ou comportamento abusivo' },
    ],
    banda: [
      { id: 5, label: 'Perfil falso ou enganoso' },
      { id: 6, label: 'Spam ou comportamento abusivo' },
    ],
  };

  const handleSelecionarRazao = (idRazao: number) => {
    setRazaoSelecionada(idRazao);
  };

  const handleEnviar = async () => {
    if (razaoSelecionada !== null) {
      setEnviando(true);
      try {
        const token = localStorage.getItem('token');
        const data = {
          reported_item_id: itemId,
          reported_item_type: tipoItem,
          razao: razoesDenuncia[tipoItem].find(r => r.id === razaoSelecionada)?.label,
          descricao: ''
        };

        const response = await axios.post('https://tribhus.shop:5000/denuncias/reports', data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 201 && response.data.message) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setEstaAberto(false);
          setRazaoSelecionada(null);
          if (onDenunciaEnviada) {
            onDenunciaEnviada();
          }
        } else {
          throw new Error('Resposta inesperada do servidor');
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.error || 'Erro ao enviar denúncia. Por favor, tente novamente.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error('Erro de conexão. Por favor, verifique sua internet e tente novamente.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } finally {
        setEnviando(false);
      }
    }
  };

  const handleFechar = () => {
    setEstaAberto(false);
    setRazaoSelecionada(null);
  };

  return (
    <div style={styles.container}>
      <button 
        onClick={() => setEstaAberto(!estaAberto)}
        style={styles.button}
      >
        Denunciar Perfil
      </button>
      {estaAberto && (
        <div style={styles.popover}>
          <button onClick={handleFechar} style={styles.closeButton}>
            X
          </button>
          <h4 style={styles.title}>Denunciar Perfil</h4>
          <p style={styles.description}>
            Selecione o motivo da denúncia:
          </p>
          <div>
            {razoesDenuncia[tipoItem].map((razao) => (
              <button
                key={razao.id}
                onClick={() => handleSelecionarRazao(razao.id)}
                style={{
                  ...styles.reasonButton,
                  backgroundColor: razaoSelecionada === razao.id ? '#ff6347' : '#f0f0f0',
                  color: razaoSelecionada === razao.id ? 'lightgray' : 'black'
                }}
              >
                {razao.label}
              </button>
            ))}
          </div>
          <button
            onClick={handleEnviar}
            disabled={razaoSelecionada === null || enviando}
            style={{
              ...styles.submitButton,
              opacity: (razaoSelecionada !== null && !enviando) ? 1 : 0.5,
              cursor: (razaoSelecionada !== null && !enviando) ? 'pointer' : 'not-allowed'
            }}
          >
            {enviando ? 'Enviando...' : 'Enviar Denúncia'}
          </button>
          <p style={styles.footer}>
            Poderemos entrar em contato com você para mais detalhes da denúncia.
          </p>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    display: 'inline-block'
  } as React.CSSProperties,
  button: {
    padding: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#FF6600',
    fontWeight: 'bold',
    fontSize: '18px', // Aumente o tamanho da fonte para destacar o texto
  } as React.CSSProperties,
  popover: {
    position: 'fixed', // Mantendo 'fixed' para que o popover flutue
    left: '50%', // Centraliza horizontalmente
    top: '50%', // Centraliza verticalmente
    transform: 'translate(-50%, -50%)', // Ajusta para que o centro do popover seja exatamente o meio da tela
    width: '250px',
    backgroundColor: 'lightgray',
    borderRadius: '4px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    zIndex: 1101, // Garantindo que o popover esteja acima do modal
    padding: '16px',
  } as React.CSSProperties,
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#666'
  } as React.CSSProperties,
  title: {
    fontWeight: 'bold',
    marginBottom: '8px',
    paddingRight: '20px'
  } as React.CSSProperties,
  description: {
    fontSize: '14px',
    color: '#666',
    marginBottom: '16px'
  } as React.CSSProperties,
  reasonButton: {
    width: '100%',
    textAlign: 'left',
    padding: '8px',
    marginBottom: '8px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer'
  } as React.CSSProperties,
  submitButton: {
    width: '100%',
    padding: '8px',
    backgroundColor: '#ff6347',
    color: 'lightgray',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '16px'
  } as React.CSSProperties,
  footer: {
    fontSize: '12px',
    color: '#666',
    marginTop: '16px',
    textAlign: 'center'
  } as React.CSSProperties
};

export default BotaoDenunciaPerfil;
