import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ProtectedLayout from './Components/ProtectedLayout';

// Telas públicas
import PrimeiraTela from './screens/PrimeiraTela';
import TerceiraTela from './screens/TerceiraTela';
import QuartaTela from './screens/QuartaTela';
import QuintaTela from './screens/QuintaTela';
import SextaTela from './screens/SextaTela';
import SetimaTela from './screens/SetimaTela';
import OitavaTela from './screens/OitavaTela';
import TelaBanda1 from './screens/TelaBanda1';
import CodEmail from './screens/CodEmail';
import CodSMS from './screens/CodSMS';
import CodEmailUser from './screens/CodEmailUser';
import CodSMSUser from './screens/CodSMSUser';
import TelaBanda2 from './screens/TelaBanda2';
import TelaBanda3 from './screens/TelaBanda3';
import TelaBanda4 from './screens/TelaBanda4';
import TelaBanda5 from './screens/TelaBanda5';
import PasswordResetRequestScreen from './screens/PasswordResetRequestScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';

// Telas protegidas
import TelaBandaPerfil from './screens/TelaBandaPerfil';
import DashBoardBand from './screens/DashBoardBand';
import EditarAlbum from './screens/EditarAlbum';
import TelaUsuarioPerfil from './screens/TelaUsuarioPerfil';
import TelaEdiUsuario from './screens/TelaEdiUsuario';
import Palco from './screens/Palco';
import AudioPlayer from './screens/AudioPlayer';
import Bandas from './screens/Bandas';
import FamiliaBanda from './screens/FamiliaBanda';
import InscritosBanda from './screens/InscritosBanda';
import Mensagens from './screens/Mensagens';
import ChatBox from './screens/ChatBox';
import AmigosUser from './screens/AmigosUser';
import BandEditPerfil from './screens/BandEditPerfil';
import UserEditPerfil from './screens/UserEditPerfil';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Rota raiz redireciona para PrimeiraTela */}
      <Route path="/" element={<Navigate to="/PrimeiraTela" replace />} />

      {/* Rotas públicas */}
      <Route path="/PrimeiraTela" element={<PrimeiraTela />} />
      <Route path="/TerceiraTela" element={<TerceiraTela />} />
      <Route path="/QuartaTela" element={<QuartaTela />} />
      <Route path="/QuintaTela" element={<QuintaTela />} />
      <Route path="/SextaTela" element={<SextaTela />} />
      <Route path="/SetimaTela" element={<SetimaTela />} />
      <Route path="/OitavaTela" element={<OitavaTela />} />
      <Route path="/TelaBanda1" element={<TelaBanda1 />} />
      <Route path="/CodEmail" element={<CodEmail />} />
      <Route path="/CodSMS" element={<CodSMS />} />
      <Route path="/CodEmailUser" element={<CodEmailUser />} />
      <Route path="/CodSMSUser" element={<CodSMSUser />} />
      <Route path="/TelaBanda2" element={<TelaBanda2 />} />
      <Route path="/TelaBanda3" element={<TelaBanda3 />} />
      <Route path="/TelaBanda4" element={<TelaBanda4 />} />
      <Route path="/TelaBanda5" element={<TelaBanda5 />} />
      <Route path="/PasswordResetRequestScreen" element={<PasswordResetRequestScreen />} />
      <Route path="/PasswordResetScreen/:token" element={<PasswordResetScreen />} />

      {/* Rotas protegidas usando elemento pai ProtectedLayout */}
      <Route element={<ProtectedLayout />}>
        <Route path="/TelaBandaPerfil" element={<TelaBandaPerfil />} />
        <Route path="/DashBoardBand/:id" element={<DashBoardBand />} />
        <Route path="/EditarAlbum/:id" element={<EditarAlbum />} />
        <Route path="/TelaUsuarioPerfil" element={<TelaUsuarioPerfil />} />
        <Route path="/TelaEdiUsuario" element={<TelaEdiUsuario />} />
        <Route path="/Palco" element={<Palco />} />
        <Route path="/AudioPlayer" element={<AudioPlayer />} />
        <Route path="/Bandas" element={<Bandas />} />
        <Route path="/FamiliaBanda" element={<FamiliaBanda />} />
        <Route path="/InscritosBanda" element={<InscritosBanda />} />
        <Route path="/Mensagens" element={<Mensagens />} />
        <Route path="/ChatBox" element={<ChatBox />} />
        <Route path="/AmigosUser" element={<AmigosUser />} />
        <Route path="/BandEditPerfil" element={<BandEditPerfil />} />
        <Route path="/UserEditPerfil" element={<UserEditPerfil />} />
      </Route>

      {/* Rota para páginas não encontradas */}
      <Route path="*" element={<Navigate to="/PrimeiraTela" replace />} />
    </Routes>
  );
};

export default AppRoutes;