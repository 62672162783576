import React from 'react';

const TermosUsuarioDocument = () => (
  <>
    <h2>Termos de Uso e Política de Privacidade para Usuários - Tribhus</h2>
    <p>Última atualização: 15/09/2024</p>

    <h3>1. Introdução</h3>
    <p>
      Este documento, doravante denominado "Termos de Uso e Política de Privacidade para Usuários", estabelece as regras e condições para a utilização do site (www.tribhus.com.br) e aplicativos móveis, de propriedade da TRIBHUS, referida como "Tribhus" neste documento.
    </p>
    <p>
      O acesso e uso de qualquer um dos nossos sites ou aplicativos implica na aceitação integral destes Termos de Uso e de nossa Política de Privacidade. Recomendamos enfaticamente que você leia este documento na íntegra ANTES de começar a usar nossos serviços. Caso não concorde com alguma condição prevista, sugerimos que não acesse nossas plataformas ou utilize nossos serviços. O uso contínuo dos nossos serviços, após qualquer atualização dos Termos de Uso e Política de Privacidade, constitui sua aceitação tácita das novas condições. Caso não concorde com alguma modificação futura, você deverá cessar imediatamente o uso dos nossos serviços.
    </p>

    <h3>2. Serviços e Funcionalidades</h3>
    <h4>2.1 Funcionalidades Básicas</h4>
    <p>
      Os serviços e funcionalidades básicas oferecidos em nossos sites e aplicativos, incluindo o cadastro, acesso ao feed, publicação de conteúdos e navegação geral na plataforma, são gratuitos para os usuários.
    </p>
    <h4>2.2 Funcionalidades Premium</h4>
    <p>
      Alguns recursos premium ou avançados, definidos pela Tribhus, podem exigir pagamento. Qualquer cobrança relacionada a funcionalidades premium será claramente informada antes da contratação ou uso desses serviços. O usuário deve revisar e aceitar os termos específicos de pagamento antes de completar a transação, e nenhum valor será cobrado sem o consentimento explícito do usuário.
    </p>
    <h4>2.3 Alterações nas Funcionalidades</h4>
    <p>
      A Tribhus se reserva o direito de modificar, adicionar ou remover funcionalidades, tanto gratuitas quanto premium, a qualquer momento. Caso uma funcionalidade gratuita seja convertida em premium, você será notificado com antecedência, e a continuidade do uso dessa funcionalidade estará sujeita à aceitação dos novos termos.
    </p>

    <h3>3. Cadastro e Conta de Usuário</h3>
    <p>
      3.1. Para acessar todas as funcionalidades, é necessário realizar um cadastro fornecendo dados pessoais como nome completo, data de nascimento, sexo, CPF e endereço de e-mail.
    </p>
    <p>
      3.2. Você se compromete a fornecer informações verdadeiras, precisas e atualizadas, bem como a manter seus dados cadastrais sempre atualizados.
    </p>
    <p>
      3.3. A Tribhus se reserva o direito de utilizar todos os meios válidos e possíveis para identificar seus usuários, com o objetivo de prevenir fraudes e perfis falsos.
    </p>
    <p>
      3.4. É proibida a criação de mais de uma conta por pessoa. Contas duplicadas poderão ser unificadas ou inabilitadas sem aviso prévio.
    </p>
    <p>
      3.5. Você é integralmente responsável pela segurança e uso de sua identificação e senha. Não compartilhe essas informações com terceiros.
    </p>
    <p>
      3.6. A Tribhus pode solicitar a verificação de conta a qualquer momento, exigindo que o usuário forneça documentação adicional para confirmar sua identidade. O não cumprimento dessa solicitação poderá resultar na suspensão ou encerramento da conta.
    </p>

    <h3>4. Elegibilidade</h3>
    <p>
      4.1. Nossos serviços estão disponíveis para pessoas com pelo menos 13 anos de idade.
    </p>
    <p>
      4.2. Usuários entre 13 e 18 anos devem ter permissão de um responsável legal para usar nossos serviços.
    </p>
    <p>
      4.3. Pessoas inabilitadas pela Tribhus, temporária ou definitivamente, não podem utilizar nossos serviços.
    </p>

    <h3>5. Proteção de Dados e Privacidade</h3>
    <p>
      5.1. A Tribhus está comprometida em proteger sua privacidade e cumprir a Lei Geral de Proteção de Dados (LGPD - Lei nº 13.709/2018).
    </p>
    <p>
      5.2. Ao utilizar nossos serviços, você concorda com a coleta, uso e compartilhamento de seus dados conforme descrito em nossa Política de Privacidade.
    </p>
    <p>
      5.3. Utilizamos seus dados para:
    </p>
    <ul>
      <li>Personalizar sua experiência na plataforma, incluindo recomendações de conteúdo e publicidade personalizada, desde que você tenha dado consentimento para tais práticas.</li>
      <li>Comunicar diretamente com você sobre novidades, promoções e atualizações, com a opção de cancelar essas comunicações a qualquer momento.</li>
      <li>Analisar o uso de nossos produtos para desenvolver novos serviços.</li>
      <li>Cumprir obrigações legais e regulatórias.</li>
    </ul>
    <p>
      5.4. Seus direitos sob a LGPD incluem:
    </p>
    <ul>
      <li>Acesso aos seus dados pessoais.</li>
      <li>Correção de dados incompletos, inexatos ou desatualizados.</li>
      <li>Portabilidade dos dados.</li>
      <li>Eliminação dos dados pessoais (ressalvadas as hipóteses de guarda legal).</li>
      <li>Informação sobre compartilhamento de dados.</li>
      <li>Revogação do consentimento.</li>
    </ul>
    <p>
      5.5. Para exercer seus direitos ou obter mais informações, entre em contato conosco através do e-mail: <a href="mailto:admin@tribhus.com.br">admin@tribhus.com.br</a>.
    </p>
    <p>
      5.6. Utilizamos cookies e tecnologias similares para melhorar sua experiência. Você pode configurar seu navegador para recusar cookies, mas isso pode limitar algumas funcionalidades.
    </p>

    <h3>6. Conteúdo do Usuário e Propriedade Intelectual</h3>
    <p>
      6.1. Ao publicar conteúdo em nossas plataformas, você garante que possui os direitos necessários sobre esse conteúdo e concede à Tribhus uma licença não exclusiva, mundial, gratuita e sublicenciável para usar, reproduzir, modificar, adaptar, publicar e exibir tal conteúdo.
    </p>
    <p>
      6.2. A Tribhus se reserva o direito de remover qualquer conteúdo que viole estes termos ou direitos de terceiros, sem aviso prévio.
    </p>
    <p>
      6.3. Se você acredita que seu direito autoral ou de propriedade intelectual foi violado, entre em contato conosco através do e-mail: <a href="mailto:admin@tribhus.com.br">admin@tribhus.com.br</a>.
    </p>
    <p>
      6.4. O usuário concorda em indenizar e isentar a Tribhus de qualquer reclamação, dano ou responsabilidade decorrente da publicação de conteúdo que viole direitos de terceiros, incluindo direitos autorais e de propriedade intelectual. A Tribhus poderá fornecer informações do usuário às autoridades ou partes interessadas em caso de reivindicação legal.
    </p>

    <h3>7. Uso de Conteúdo de Terceiros</h3>
    <p>
      7.1. A Tribhus permite que os usuários incorporem vídeos de plataformas de compartilhamento de terceiros (como YouTube) diretamente em suas postagens ou perfis.
    </p>
    <p>
      7.2. Ao incorporar conteúdo de terceiros, você deve:
    </p>
    <ul>
      <li>Garantir que tem o direito de compartilhar esse conteúdo.</li>
      <li>Respeitar os termos de serviço da plataforma original.</li>
      <li>Fornecer atribuição adequada ao criador original, quando aplicável.</li>
    </ul>
    <p>
      7.3. A Tribhus não se responsabiliza pelo conteúdo de terceiros incorporado pelos usuários, mas se reserva o direito de remover qualquer conteúdo que viole estes termos ou receba reclamações válidas de direitos autorais.
    </p>
    <p>
      7.4. A Tribhus não endossa, verifica ou assume qualquer responsabilidade pelo conteúdo de terceiros incorporado pelos usuários. Todo o conteúdo incorporado deve cumprir as leis aplicáveis, e o usuário que o incorpora é totalmente responsável por garantir a sua legalidade.
    </p>

    <h3>8. Conduta do Usuário</h3>
    <p>
      8.1. Ao utilizar nossos serviços, você concorda em não:
    </p>
    <ul>
      <li>Violar direitos de terceiros, incluindo direitos autorais e de propriedade intelectual.</li>
      <li>Publicar conteúdo ilegal, ofensivo, difamatório ou que promova discriminação.</li>
      <li>Criar falsa identidade ou usar subterfúgios.</li>
      <li>Enviar spam ou conteúdo comercial não solicitado.</li>
      <li>Transmitir malware ou realizar ataques cibernéticos.</li>
      <li>Manipular métricas da plataforma, como contagens de reprodução ou seguidores.</li>
      <li>Acessar áreas restritas da plataforma sem autorização.</li>
      <li>Utilizar os serviços para fins não autorizados ou ilegais.</li>
      <li>Usar bots, scrapers ou outros meios automatizados para coletar informações.</li>
    </ul>
    <p>
      8.2. A violação destas regras pode resultar na suspensão ou encerramento de sua conta, incluindo a proibição permanente de criar novas contas na plataforma Tribhus.
    </p>

    <h3>9. Limitação de Responsabilidade</h3>
    <p>
      9.1. A Tribhus não se responsabiliza por danos indiretos, incidentais, consequenciais ou punitivos resultantes do uso de seus serviços.
    </p>
    <p>
      9.2. Não garantimos que nossos serviços estarão sempre disponíveis, seguros, livres de erros ou vírus.
    </p>
    <p>
      9.3. A Tribhus não é responsável pela precisão, legalidade ou adequação do conteúdo gerado por usuários.
    </p>
    <p>
      9.4. Nossa responsabilidade máxima está limitada ao valor pago pelo usuário pelos serviços nos últimos 12 meses, se aplicável.
    </p>
    <p>
      9.5. Os serviços são fornecidos "como estão", sem garantias de qualquer tipo, expressas ou implícitas, incluindo, mas não se limitando a, garantias de comercialização, adequação a um propósito específico, ou não violação de direitos de terceiros.
    </p>

    <h3>10. Modificações nos Termos</h3>
    <p>
      10.1. Reservamo-nos o direito de modificar estes Termos a qualquer momento. As alterações entrarão em vigor imediatamente após a publicação.
    </p>
    <p>
      10.2. Notificaremos os usuários sobre alterações significativas por e-mail ou através de um aviso em nossa plataforma.
    </p>
    <p>
      10.3. O uso continuado de nossos serviços após as alterações constitui sua aceitação dos novos termos.
    </p>

    <h3>11. Lei Aplicável e Foro</h3>
    <p>
      11.1. Estes Termos são regidos pelas leis do Brasil.
    </p>
    <p>
      11.2. Fica eleito o Foro da Comarca de Limeira, Estado de São Paulo, para dirimir quaisquer dúvidas ou controvérsias decorrentes destes Termos, salvo outro foro privilegiado determinado por lei.
    </p>

    <h3>12. Resolução de Conflitos</h3>
    <h4>12.1 Mediação e Arbitragem</h4>
    <p>
      Em caso de disputa decorrente ou relacionada a estes Termos, as partes concordam em buscar uma solução amigável por meio de mediação. Caso a mediação não resolva a disputa, as partes poderão optar pela arbitragem, conforme as regras da Câmara de Arbitragem de São Paulo, a ser realizada na cidade de Limeira, São Paulo.
    </p>
    <h4>12.2 Jurisdição</h4>
    <p>
      Se a disputa não for resolvida por meio de mediação ou arbitragem, ou se a arbitragem não for aplicável por alguma razão, as partes concordam que a disputa será resolvida nos tribunais da Comarca de Limeira, Estado de São Paulo.
    </p>

    <h3>13. Contato</h3>
    <p>
      Para quaisquer dúvidas sobre estes Termos ou o serviço Tribhus para Artistas, entre em contato conosco através do e-mail: <a href="mailto:admin@tribhus.com.br">admin@tribhus.com.br</a>.
    </p>

    <p>
      Ao utilizar os serviços da Tribhus, você confirma que revisou e compreendeu plenamente suas responsabilidades legais em relação ao conteúdo que publica, e que se compromete a garantir que todo o conteúdo enviado é de sua propriedade ou que você possui todas as licenças e autorizações necessárias para distribuí-lo.
    </p>
  </>
);

export default TermosUsuarioDocument;
