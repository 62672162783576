import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor'; 

interface DeletePostCommentProps {
  onPostDeleted?: () => void;
  onCommentDeleted?: () => void;
  onClose: () => void;
  isVisible: boolean;
  postId?: number | null;
  commentId?: number | null;
}

const DeletePostComment: React.FC<DeletePostCommentProps> = ({
  onPostDeleted,
  onCommentDeleted,
  onClose,
  isVisible,
  postId,
  commentId,
}) => {
  const { state: authState } = useContext(AuthContext);

  const deletePost = async () => {
    if (!postId) return;
    try {
      await axios.delete(`https://tribhus.shop:5000/palco/post/${postId}`, {
        headers: { Authorization: `Bearer ${authState.token}` },
      });
      if (onPostDeleted) onPostDeleted();
      console.log("Postagem excluída com sucesso.");
      onClose();
    } catch (error) {
      console.error("Erro ao excluir postagem:", error);
    }
  };

  const deleteComment = async () => {
    if (!commentId) return;
    try {
      await axios.delete(`https://tribhus.shop:5000/palco/comentarios/${commentId}`, {
        headers: { Authorization: `Bearer ${authState.token}` },
      });
      if (onCommentDeleted) onCommentDeleted();
      console.log("Comentário excluído com sucesso.");
      onClose();
    } catch (error) {
      console.error("Erro ao excluir comentário:", error);
    }
  };

  const handleDelete = () => {
    if (commentId) {
      deleteComment();
    } else {
      deletePost();
    }
  };

  if (!isVisible) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <h2 style={styles.modalTitle}>Confirmar Exclusão</h2>
        <p style={styles.modalText}>
          Tem certeza que deseja excluir este {commentId ? "comentário" : "post"}?
        </p>
        <div style={styles.buttonContainer}>
          <button onClick={handleDelete} style={styles.deleteButton}>
            Excluir
          </button>
          <button onClick={onClose} style={styles.cancelButton}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#1e1e1e',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '400px',
    width: '100%',
  },
  modalTitle: {
    color: '#ffffff',
    marginBottom: '15px',
  },
  modalText: {
    color: '#cccccc',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    backgroundColor: '#FF6600',
    color: 'lightgray',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  cancelButton: {
    backgroundColor: '#333333',
    color: 'lightgray',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default DeletePostComment;