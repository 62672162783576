import React, { useState, CSSProperties, useEffect } from 'react';
import axios from '../Services/axiosInterceptor'; 

interface FotosAdicionarProps {
    idBanda: number;
    token: string;
}

interface Foto {
    id: number;
    url_foto: string;
}

interface Album {
    id_album_foto: number;
    nome: string;
    descricao: string;
}

const FotosAdicionar: React.FC<FotosAdicionarProps> = ({ idBanda, token }) => {
    const [nomeAlbum, setNomeAlbum] = useState('');
    const [descricaoAlbum, setDescricaoAlbum] = useState('');
    const [albumSalvo, setAlbumSalvo] = useState<Album | null>(null); // Atualizado para armazenar Album ou null
    const [fotos, setFotos] = useState<File[]>([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [albuns, setAlbuns] = useState<Album[]>([]);
    const [fotosDoAlbumSelecionado, setFotosDoAlbumSelecionado] = useState<Foto[]>([]);
    const [albumSelecionado, setAlbumSelecionado] = useState<Album | null>(null);
    const [confirmarExclusao, setConfirmarExclusao] = useState(false);

    useEffect(() => {
        buscarAlbuns();
    }, [idBanda, token]);

    const buscarAlbuns = async () => {
        try {
            const response = await axios.get(`https://tribhus.shop:5000/fotos/albuns`, {
                params: {
                    id_proprietario: idBanda,
                    tipo_proprietario: 'banda'
                },
                headers: { Authorization: `Bearer ${token}` }
            });
            setAlbuns(response.data);
        } catch (error) {
            console.error('Erro ao buscar álbuns:', error);
        }
    };

    const buscarFotosDoAlbum = async (id_album_foto: number) => { // Movido para antes de ser chamado
        try {
            const response = await axios.get(`https://tribhus.shop:5000/fotos/fotos/${id_album_foto}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setFotosDoAlbumSelecionado(response.data);
        } catch (error) {
            console.error('Erro ao buscar fotos do álbum:', error);
        }
    };

    const salvarAlbum = async () => {
        try {
            const response = await axios.post(`https://tribhus.shop:5000/fotos/album/${idBanda}`, {
                nome: nomeAlbum,
                descricao: descricaoAlbum,
                id_proprietario: idBanda,
                tipo_proprietario: 'banda'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const novoAlbum = {
                id_album_foto: response.data.id_album_foto,
                nome: nomeAlbum,
                descricao: descricaoAlbum
            };
            setAlbuns([...albuns, novoAlbum]);
            setAlbumSelecionado(novoAlbum);
            setFotosDoAlbumSelecionado([]);
            setAlbumSalvo(novoAlbum); // Agora salva o objeto Album
            setNomeAlbum('');
            setDescricaoAlbum('');
        } catch (error) {
            console.error('Erro ao salvar álbum:', error);
        }
    };

    const adicionarFotos = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFotos([...fotos, ...Array.from(event.target.files)]);
        }
    };

    const uploadFoto = async (foto: File) => {
        if (!albumSelecionado) {
            console.error('Nenhum álbum selecionado');
            return;
        }

        const formData = new FormData();
        formData.append('foto', foto);

        try {
            const response = await axios.post(`https://tribhus.shop:5000/fotos/foto/${idBanda}/${albumSelecionado.id_album_foto}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
                    setUploadProgress(percentCompleted);
                }
            });
            setFotosDoAlbumSelecionado([...fotosDoAlbumSelecionado, { id: response.data.id_foto, url_foto: response.data.url_foto }]);
        } catch (error) {
            console.error('Erro ao fazer upload da foto:', error);
        }
    };

    const salvarFotos = async () => {
        if (!albumSelecionado) {
            console.error('Nenhum álbum selecionado');
            return;
        }
        for (let foto of fotos) {
            await uploadFoto(foto);
        }
        setFotos([]);
        setUploadProgress(0);
    };

    const selecionarAlbum = (album: Album) => {
        setAlbumSelecionado(album);
        buscarFotosDoAlbum(album.id_album_foto); // Agora a função está definida antes
        setAlbumSalvo(null); // Reseta o albumSalvo ao selecionar outro álbum
    };

    const excluirAlbum = async () => {
        if (!albumSelecionado) return;

        try {
            await axios.delete(`https://tribhus.shop:5000/fotos/album/${idBanda}/${albumSelecionado.id_album_foto}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setAlbuns(albuns.filter(album => album.id_album_foto !== albumSelecionado.id_album_foto));
            setAlbumSelecionado(null);
            setFotosDoAlbumSelecionado([]);
            setConfirmarExclusao(false);
            setAlbumSalvo(null);
        } catch (error) {
            console.error('Erro ao excluir álbum:', error);
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.leftColumn}>
                <h2 style={styles.title}>Adicionar Álbum de Fotos</h2>
                {!albumSalvo ? (
                    <div style={styles.box}>
                        <input
                            style={styles.input}
                            type="text"
                            value={nomeAlbum}
                            onChange={(e) => setNomeAlbum(e.target.value)}
                            placeholder="Nome do Álbum"
                        />
                        <textarea
                            style={{ ...styles.input, height: '100px' }}
                            value={descricaoAlbum}
                            onChange={(e) => setDescricaoAlbum(e.target.value)}
                            placeholder="Descrição do Álbum"
                        />
                        <button style={styles.button} onClick={salvarAlbum}>Criar Álbum</button>
                    </div>
                ) : (
                    <div style={styles.box}>
                        <h3 style={styles.subtitulo}>Detalhes do Álbum</h3>
                        <p style={styles.albumDetailsText}>Nome: {albumSalvo.nome}</p>
                        <p style={styles.albumDetailsText}>Descrição: {albumSalvo.descricao}</p>
                    </div>
                )}

            <div>
              <h3 style={styles.title}>Álbuns Cadastrados</h3>
              {albuns && albuns.length > 0 ? (
                albuns.map((album) => (
                  <div
                    key={album.id_album_foto}
                    style={styles.box}
                    onClick={() => selecionarAlbum(album)}
                  >
                    <h4 style={styles.albumDetailsText}>{album.nome}</h4>
                    <p style={styles.albumDetailsText}>{album.descricao}</p>
                  </div>
                ))
              ) : (
                <p style={styles.albumDetailsText}>Nenhum álbum cadastrado ainda.</p>
              )}
            </div>
          </div>
      
          {albumSelecionado && (
            <div style={styles.rightColumn}>
              <div style={styles.albumHeader}>
                <h3 style={styles.title}>{albumSelecionado.nome}</h3>
                <button
                  style={styles.excluirButton}
                  onClick={() => setConfirmarExclusao(true)}
                >
                  Excluir Álbum
                </button>
              </div>
              <p style={styles.albumDetailsText}>{albumSelecionado.descricao}</p>
      
              {confirmarExclusao && (
                <div style={styles.confirmacaoExclusao}>
                  <p style={styles.confirmacaoTexto}>Tem certeza que deseja excluir este álbum e todas as suas fotos?</p>
                  <button
                    style={styles.confirmarExclusaoButton}
                    onClick={excluirAlbum}
                  >
                    Confirmar Exclusão
                  </button>
                  <button
                    style={styles.cancelarExclusaoButton}
                    onClick={() => setConfirmarExclusao(false)}
                  >
                    Cancelar
                  </button>
                </div>
              )}
      
              <div style={styles.adicionarFotosSection}>
                <h4 style={styles.adicionarFotosTitulo}>Adicione fotos ao seu álbum</h4>
                <input
                  style={styles.fileInput}
                  type="file"
                  onChange={adicionarFotos}
                  multiple
                  accept="image/*"
                />
                <button style={styles.uploadButton} onClick={salvarFotos}>Upload Foto</button>
              </div>
              {uploadProgress > 0 && <p style={styles.paragraph}>Progresso: {uploadProgress}%</p>}
              <div style={styles.fotosPreview}>
                {fotosDoAlbumSelecionado.map((foto, index) => (
                  <img key={index} src={foto.url_foto} alt={`Foto ${index + 1}`} style={styles.fotoPreview} />
                ))}
              </div>
            </div>
          )}
        </div>
      );
    };      

const styles: { [key: string]: CSSProperties } = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#151922',
        padding: '20px',
        minHeight: '100vh',
    },
    leftColumn: {
        width: '40%',
        backgroundColor: '#1E2736',
        padding: '20px',
     },
    rightColumn: {
        width: '55%',
        backgroundColor: '#1E2736',
        padding: '20px',
        marginTop: '50px',
        marginLeft: '50px',
 },
    title: {
        fontSize: '20px',
        color: 'lightgray',
        fontWeight: 'bold',
        textAlign: 'left',
    },
    subtitulo: {
        fontSize: '20px',
        color: '#FF6600',
        fontWeight: 'bold',
        textAlign: 'left',
     },
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#30343f',
        padding: '20px',
        borderRadius: '10px',
        marginBottom: '20px',
        cursor: 'pointer',
    },
    button: {
        backgroundColor: '#FF6600',
        padding: '10px 20px',
        borderRadius: '5px',
        color: '#fff1e5',
        fontSize: '14px',
        border: 'none',
        cursor: 'pointer',
        marginTop: '10px',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: '#2C3E50',
        border: 'none',
        borderRadius: '5px',
        color: 'lightgray',
    },
    paragraph: {
        color: '#fff1e5',
        fontSize: '16px',
        marginBottom: '10px',
    },
    fotosPreview: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginTop: '20px',
    },
    fotoPreview: {
        width: '180px',
        height: '180px',
        objectFit: 'cover',
        borderRadius: '20px',
    },
    albumDetailsText: {
        fontSize: '16px',
        color: 'lightgray',
    },
    confirmacaoExclusao: {
        backgroundColor: '#30343f',
        padding: '15px',
        borderRadius: '5px',
        marginTop: '10px',
    },
    confirmacaoTexto: {
        color: 'lightgray',
        marginBottom: '10px',
    },
    albumHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    excluirButton: {
        backgroundColor: 'transparent',
        color: '#FF4136',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px',
        padding: '5px 10px',
    },
    adicionarFotosSection: {
        marginTop: '20px',
        padding: '15px',
        backgroundColor: '#30343f',
        borderRadius: '5px',
    },
    adicionarFotosTitulo: {
        fontSize: '16px',
        color: '#FF6600',
        marginBottom: '10px',
    },
    fileInput: {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: '#2C3E50',
        border: 'none',
        borderRadius: '5px',
        color: 'lightgray',
    },
    uploadButton: {
        backgroundColor: '#FF6600',
        padding: '10px 20px',
        borderRadius: '5px',
        color: '#fff1e5',
        fontSize: '14px',
        border: 'none',
        cursor: 'pointer',
    },
    confirmarExclusaoButton: {
        backgroundColor: '#FF4136',
        padding: '10px 20px',
        borderRadius: '5px',
        color: '#fff1e5',
        fontSize: '14px',
        border: 'none',
        cursor: 'pointer',
        marginRight: '10px',
    },
    cancelarExclusaoButton: {
        backgroundColor: '#30343f',
        padding: '10px 20px',
        borderRadius: '5px',
        color: '#fff1e5',
        fontSize: '14px',
        border: '1px solid #fff1e5',
        cursor: 'pointer',
    },
};

export default FotosAdicionar;