import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePasswordReset } from '../context/PasswordResetContext';
import Modal from 'react-modal';

const PasswordResetRequestScreen: React.FC = () => {
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const { requestPasswordReset, loading, error } = usePasswordReset();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const success = await requestPasswordReset(email);
    if (success) {
      setModalMessage('Um e-mail de redefinição de senha foi enviado. Por favor, verifique sua caixa de entrada ou de SPAM.');
      setShowModal(true);
    } else {
      setModalMessage('Ocorreu um erro ao enviar o e-mail de redefinição. Por favor, tente novamente.');
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/'); // Assumindo que '/' é a rota para PrimeiraTela
  };

  return (
    <div style={styles.container}>
      <img src={require('../assets/logotipo.png')} alt="Logotipo" style={styles.logo} />
      <button onClick={() => navigate(-1)} style={styles.backButton}>
        <img src={require('../assets/voltar.png')} alt="Voltar" style={styles.backIcon} />
      </button>
      <h1 style={styles.title}>Por Favor, Insira seu Email Cadastrado na Tribhus</h1>
      
      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Seu e-mail"
          required
        />
      </div>
      
      {error && <p style={styles.errorText}>{error}</p>}
     
      <button
        style={styles.button}
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? 'Enviando...' : 'Enviar'}
      </button>

      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        style={customStyles}
        contentLabel="Resultado da Solicitação"
      >
        <h2 style={styles.modalTitle}>{modalMessage}</h2>
        <button onClick={handleCloseModal} style={styles.modalButton}>Fechar</button>
      </Modal>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#151922',
    padding: '20px',
  },
  logo: {
    width: '280px',
    height: '360px',
    objectFit: 'contain',
    marginBottom: '20px',
  },
  backButton: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    background: 'none',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
  },
  backIcon: {
    width: '30px',
    height: '30px',
  },
  title: {
    fontSize: '24px',
    color: '#fff',
    textAlign: 'center',
    marginBottom: '50px',
  },
  inputContainer: {
    width: '80%',
    maxWidth: '400px',
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '10px',
    backgroundColor: '#151922',
    borderColor: '#914100',
    borderWidth: '1px',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    fontSize: '20px',
  },
  button: {
    backgroundColor: '#914100',
    padding: '15px 50px',
    borderRadius: '10px',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    textAlign: 'center',
    width: '200px',
    margin: '10px auto',
  },
  errorText: {
    color: '#FF6600',
    fontSize: '14px',
    textAlign: 'center',
  },
  modalTitle: {
    fontSize: '18px',
    color: '#000',
    textAlign: 'center',
    marginBottom: '20px',
  },
  modalButton: {
    backgroundColor: '#914100',
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '20px',
    backgroundColor: 'lightgray',
  },
};

export default PasswordResetRequestScreen;